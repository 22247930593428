import dayjs from 'dayjs';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getHomePage } from '../../services/home';
import { handleErrors } from '../../utils/handleErrors';
import { Status } from '../../views/shared/components/CustomAlert/component';
import { getHomeDataSuccess, setHomeLoader } from '../actions/home';
import { setMessage } from '../actions/message';
import { IState } from '../reducers';
import { declineUser } from './auth';

export const getHomeData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setHomeLoader(true));
    try {
      const response = await getHomePage();
      await dispatch(declineUser(response));
      const transformResponseData = Object.entries(response.data.data).map(
        (responseData) => {
          if (responseData[0] === 'updatesFromSubscription') {
            return responseData;
          } else {
            const videosData = responseData[1].map((data) => ({
              ...data,
              createdAt: dayjs(data.createdAt).format('D MMM YYYY')
            }));
            return [responseData[0], videosData];
          }
        }
      );
      const readyResponseData = Object.fromEntries(transformResponseData);
      dispatch(getHomeDataSuccess({ ...readyResponseData }));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setHomeLoader(false));
    }
  };
};
