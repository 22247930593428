import { DecliningAction, DecliningActionTypes } from '../actions/declining';

export interface IDecliningState {
  declined: boolean;
}

export const initialDecliningState: IDecliningState = {
  declined: false
};

export default function decliningReducer(
  state: IDecliningState = initialDecliningState,
  action: DecliningAction
) {
  switch (action.type) {
    case DecliningActionTypes.DECLINE_USER:
      return {
        ...state,
        declined: action.payload
      };
    default:
      return state;
  }
}
