import { combineReducers } from 'redux';
import adsReducer, { IAdsState, initialAdsState } from './ads';
import authReducer, { IAuthState, initialAuthState } from './auth';
import categoriesReducer, {
  ICategoriesState,
  initialCategoriesState
} from './categories';
import channelReducer, {
  IChannelsState,
  initialChannelsState
} from './channels';
import decliningReducer, {
  IDecliningState,
  initialDecliningState
} from './declining';
import historyReducer, { IHistoryState, initialHistoryState } from './history';
import homeReducer, { IHomeState, initialHomeState } from './home';
import messageReducer, { IMessageState, initialMessageState } from './message';
import playlistReducer, {
  initialPlaylistState,
  IPlaylistState
} from './playlist';
import profileReducer, { initialProfileState, IProfileState } from './profile';
import redirectReducer, {
  initialRedirectState,
  IRedirectState
} from './redirect';
import searchReducer, { initialSearchState, ISearchState } from './search';
import snackbarReducer, {
  initialSnackbarState,
  ISnackbarState
} from './snackbar';
import spamReducer, { initialSpamState, ISpamState } from './spam';
import videosReducer, { initialVideosState, IVideosState } from './videos';
import wishlistReducer, {
  initialWishlistState,
  IWishlistState
} from './wishlist';
import withdrawalReducer, {
  initialWithdrawalState,
  IWithdrawalState
} from './withdrawal';

export interface IState {
  ads: IAdsState;
  auth: IAuthState;
  channels: IChannelsState;
  message: IMessageState;
  profile: IProfileState;
  redirect: IRedirectState;
  videos: IVideosState;
  history: IHistoryState;
  categories: ICategoriesState;
  home: IHomeState;
  withdrawal: IWithdrawalState;
  wishlist: IWishlistState;
  playlist: IPlaylistState;
  spam: ISpamState;
  snackbar: ISnackbarState;
  search: ISearchState;
  declining: IDecliningState;
}

export const initialState: IState = {
  ads: initialAdsState,
  auth: initialAuthState,
  channels: initialChannelsState,
  message: initialMessageState,
  profile: initialProfileState,
  redirect: initialRedirectState,
  videos: initialVideosState,
  history: initialHistoryState,
  categories: initialCategoriesState,
  home: initialHomeState,
  withdrawal: initialWithdrawalState,
  wishlist: initialWishlistState,
  playlist: initialPlaylistState,
  spam: initialSpamState,
  snackbar: initialSnackbarState,
  search: initialSearchState,
  declining: initialDecliningState
};

export default combineReducers({
  ads: adsReducer,
  auth: authReducer,
  channels: channelReducer,
  message: messageReducer,
  profile: profileReducer,
  redirect: redirectReducer,
  videos: videosReducer,
  history: historyReducer,
  categories: categoriesReducer,
  home: homeReducer,
  withdrawal: withdrawalReducer,
  wishlist: wishlistReducer,
  playlist: playlistReducer,
  spam: spamReducer,
  snackbar: snackbarReducer,
  search: searchReducer,
  declining: decliningReducer
});

export const receiveAds = (state: IState) => state.ads;
export const receiveAuth = (state: IState) => state.auth;
export const receiveMessage = (state: IState) => state.message;
export const receiveProfile = (state: IState) => state.profile;
export const receiveRedirect = (state: IState) => state.redirect;
export const receiveVideos = (state: IState) => state.videos;
export const receiveHistory = (state: IState) => state.history;
export const receiveChannels = (state: IState) => state.channels;
export const receiveCategories = (state: IState) => state.categories;
export const receiveHome = (state: IState) => state.home;
export const receiveWithdrawal = (state: IState) => state.withdrawal;
export const receiveWishlist = (state: IState) => state.wishlist;
export const receivePlaylist = (state: IState) => state.playlist;
export const receiveSpam = (state: IState) => state.spam;
export const receiveSnackbar = (state: IState) => state.snackbar;
export const receiveSearch = (state: IState) => state.search;
export const receiveDeclining = (state: IState) => state.declining;
