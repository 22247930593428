import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { receiveProfile, receiveVideos } from '../../../application/reducers';
import { theme } from '../../../assets/styles/configs/themeMaterials';
import { getToken } from '../../../utils/token';
import {
  getVpnMessage,
  setVpnMessage,
  unsetVpnMessage
} from '../../../utils/vpnMessage';
import { Header } from '../../components/Header';
import { RegisterBanner } from '../../components/RegisterBanner';
import { Sidebar } from '../../components/Sidebar';
import { VpnMessage } from '../../components/VpnMessage';

type ILayout = {
  children: React.ReactNode;
};

export const Layout = ({ children }: ILayout) => {
  const token = getToken();
  const vpnMessage = getVpnMessage();
  const fullTheme = useTheme();
  const matchesMd = useMediaQuery(fullTheme.breakpoints.down('md'));
  const { canMonetise } = useSelector(receiveVideos);
  const { isSidebarOpened, isCloseModalFree} = useSelector(receiveProfile);
  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false);
  const [isVpnMessage, setIsVpnMessage] = useState<boolean>(
    vpnMessage?.isMessage
  );

  useEffect(() => {
    if (token) {
      setInterval(() => {
        const vpnData = getVpnMessage();
        const nowDate = new Date().getTime();
        if (nowDate > vpnData?.expiry) {
          setVpnMessage();
        }
        if (isVpnMessage !== vpnData?.isMessage) {
          setIsVpnMessage(vpnData?.isMessage);
        }
      }, 10000);
    } else {
      unsetVpnMessage();
    }
  }, [token, isVpnMessage]);

  useEffect(() => {
    if (matchesMd) {
      setIsMobileOpen(false);
    }
  }, [matchesMd]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%'
      }}
      id="layout"
    >
      <Header isMobileOpen={isMobileOpen} setIsMobileOpen={setIsMobileOpen} />
      <Sidebar
        open={isSidebarOpened}
        isMobileOpen={isMobileOpen}
        setIsMobileOpen={setIsMobileOpen}
        isAuthenticated={token}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: { xs: '55px', md: '75px' },
          width: '100%',
          minHeight: { xs: 'calc(100vh - 55px)', md: 'calc(100vh - 75px)' },
          overflow: 'hidden',
          backgroundColor: theme.bg.mainBlock
        }}
      >
        {children}
        {!token && !isCloseModalFree && <RegisterBanner />}
        {!canMonetise && isVpnMessage && <VpnMessage />}
      </Box>
    </Box>
  );
};
