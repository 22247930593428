import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../assets/styles/configs/themeMaterials';

export const useStyles = makeStyles((fullTheme: Theme) => ({
  list: {
    height: '100%',
    padding: '24px 10px'
  },
  listItem: {
    '&.MuiListItem-root': {
      cursor: 'pointer',
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '5px'
    },
    '& .MuiTypography-root': {
      fontFamily: theme.fontFamily.primary,
      fontSize: '14px',
      color: theme.common.primaryLight
    }
  },

  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '30px',
    height: '30px',
    padding: '5px',
    borderRadius: '50%',
    [fullTheme.breakpoints.down('md')]: {
      marginRight: '5px'
    }
  },

  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '3px',
    width: '100%',
    '&:hover': {
      background: theme.button.primary,
      boxShadow: theme.boxShadow.button,
      borderRadius: '5px',
      '& .MuiTypography-root': {
        color: theme.common.primaryLight
      },
      '& path, & circle, & rect': {
        fill: theme.common.primaryLight
      },
      '& .svg-detail': {
        fill: theme.common.detail
      }
    }
  },

  linkSmall: {
    '&:hover': {
      background: 'transparent',
      boxShadow: 'none',
      '& .MuiListItemIcon-root': {
        background: theme.button.round
      }
    }
  },

  bottomMenu: {
    [fullTheme.breakpoints.down('md')]: {
      marginTop: 0
    }
  },

  mobileDrawer: {
    '& .MuiPaper-root': {
      backgroundImage: 'none'
    },
    '& .MuiList-root': {
      paddingTop: '25px',
      paddingBottom: '45px'
    },
    '& grid': {
      height: '100%'
    }
  },

  sidebarLogoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '30px'
  }
}));

const drawerWidth = 240;
const headerHeight = 75;

const openedMixin = (fullTheme: Theme): CSSObject => ({
  width: drawerWidth,
  top: headerHeight,
  transition: fullTheme.transitions.create('width', {
    easing: fullTheme.transitions.easing.sharp,
    duration: fullTheme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  '& .MuiListItemIcon-root': {
    marginRight: '10px'
  }
});

const closedMixin = (fullTheme: Theme): CSSObject => ({
  transition: fullTheme.transitions.create('width', {
    easing: fullTheme.transitions.easing.sharp,
    duration: fullTheme.transitions.duration.leavingScreen
  }),
  top: headerHeight,
  overflowX: 'hidden',
  width: `calc(${fullTheme.spacing(7)} + 1px)`,
  [fullTheme.breakpoints.up('sm')]: {
    width: `calc(${fullTheme.spacing(9)} + 1px)`
  },
  '& .MuiListItemIcon-root': {
    transition: fullTheme.transitions.create('margin', {
      easing: fullTheme.transitions.easing.sharp,
      duration: fullTheme.transitions.duration.leavingScreen
    }),
    marginRight: '22px'
  },
  '& .MuiLink-root': {
    transition: fullTheme.transitions.create('margin', {
      easing: fullTheme.transitions.easing.sharp,
      duration: fullTheme.transitions.duration.leavingScreen
    }),
    marginRight: '25px'
  }
});

export const DrawerDesktop = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme: fullTheme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(fullTheme),
    '& .MuiDrawer-paper': openedMixin(fullTheme)
  }),
  ...(!open && {
    ...closedMixin(fullTheme),
    '& .MuiDrawer-paper': closedMixin(fullTheme)
  })
}));
