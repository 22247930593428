import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IAddToSpam, ISpamVideo } from '../../domains/models/spam';
import { addToSpam, getSpam, getSpamReasons } from '../../services/spam';
import { handleDateHorizontalBlocks } from '../../utils/handleDate';
import { handleErrors } from '../../utils/handleErrors';
import { Status } from '../../views/shared/components/CustomAlert/component';
import { setMessage } from '../actions/message';
import { redirect } from '../actions/redirect';
import { setSnackbar } from '../actions/snackbar';
import {
  getSpamReasonsSuccess,
  getSpamSuccess,
  setSpamLoader
} from '../actions/spam';
import { IState } from '../reducers';
import { declineUser } from './auth';

export const getSpamData = (page: number, isUpdating: boolean) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setSpamLoader(true));
    try {
      const response = await getSpam(page);
      await dispatch(declineUser(response));
      const { spamVideos, paginate } = response.data.data;
      const transformVideos =
        handleDateHorizontalBlocks<ISpamVideo>(spamVideos);
      const spamData = {
        spamVideoData: transformVideos,
        totalPages: paginate.totalPages
      };
      dispatch(
        getSpamSuccess({
          spamData,
          isUpdating
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setSpamLoader(false));
    }
  };
};

export const getSpamReasonsData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setSpamLoader(true));
    try {
      const response = await getSpamReasons();
      await dispatch(declineUser(response));
      dispatch(getSpamReasonsSuccess(response.data.data));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setSnackbar({ snackbar: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setSpamLoader(false));
    }
  };
};

export const addToSpamData = (payload: IAddToSpam) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setSpamLoader(true));
    try {
      const response = await addToSpam(payload);
      await dispatch(declineUser(response));
      dispatch(redirect('/'));
      dispatch(
        setMessage({ message: 'addToSpamSuccessfully', status: Status.SUCCESS })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setSnackbar({ snackbar: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setSpamLoader(false));
    }
  };
};
