import { ThunkDispatch } from 'redux-thunk';
import { IChannel } from '../../domains/models';
import { IHomeResponse } from '../../domains/models/home';
import { togglePrerender } from '../../utils/prerender';
import { IState } from '../reducers';
import { getHomeData } from '../thunk/home';

export enum HomeActionTypes {
  SET_HOME_LOADER = 'home/set-home-loader',
  GET_HOME_DATA_SUCCESS = 'home/get-home-data-success',
  GET_IS_SUBSCRIBE_IN_HOME_SUCCESS = 'home/get-is-subscribe-in-home-success'
}

export interface ISetHomeLoader {
  type: HomeActionTypes.SET_HOME_LOADER;
  payload: boolean;
}

export const setHomeLoader = (isLoading: boolean): ISetHomeLoader => {
  togglePrerender(isLoading);
  return ({
    type: HomeActionTypes.SET_HOME_LOADER,
    payload: isLoading
  });
};

export const homeRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, HomeAction>) => {
    await dispatch(getHomeData());
  };
};

export interface IGetHomeDataSuccess {
  type: HomeActionTypes.GET_HOME_DATA_SUCCESS;
  payload: IHomeResponse;
}

export const getHomeDataSuccess = (
  payload: IHomeResponse
): IGetHomeDataSuccess => ({
  type: HomeActionTypes.GET_HOME_DATA_SUCCESS,
  payload
});

export interface IGetSubscribeIsSuccess {
  type: HomeActionTypes.GET_IS_SUBSCRIBE_IN_HOME_SUCCESS;
  payload: {
    channel: IChannel;
  };
}

export const getSubscribeIsSuccess = (payload: {
  channel: IChannel;
}): IGetSubscribeIsSuccess => ({
  type: HomeActionTypes.GET_IS_SUBSCRIBE_IN_HOME_SUCCESS,
  payload
});

export type HomeAction =
  | ISetHomeLoader
  | IGetHomeDataSuccess
  | IGetSubscribeIsSuccess;
