import { RedirectAction, RedirectActionTypes } from '../actions/redirect';

export interface IRedirectState {
  redirectTo: string;
}

export const initialRedirectState: IRedirectState = {
  redirectTo: ''
};

export default function redirectReducer(
  state: IRedirectState = initialRedirectState,
  action: RedirectAction
) {
  switch (action.type) {
    case RedirectActionTypes.REDIRECT:
      return {
        ...state,
        redirectTo: action.payload
      };
    case RedirectActionTypes.CLEAR_REDIRECT:
      return { ...state, redirectTo: '' };
    default:
      return state;
  }
}
