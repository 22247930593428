export enum DecliningActionTypes {
  DECLINE_USER = 'declining/decline-user'
}

export interface IDeclineUserAction {
  type: DecliningActionTypes.DECLINE_USER;
  payload: boolean;
}

export const declineUserAction = (declined: boolean): IDeclineUserAction => ({
  type: DecliningActionTypes.DECLINE_USER,
  payload: declined
});

export type DecliningAction = IDeclineUserAction;
