import { AlertColor } from '@mui/material';
import { MessageAction, MessageActionTypes } from '../actions/message';

export interface IMessageState {
  message: string;
  status: AlertColor | null;
}

export const initialMessageState: IMessageState = {
  message: '',
  status: null
};

export default function messageReducer(
  state: IMessageState = initialMessageState,
  action: MessageAction
) {
  switch (action.type) {
    case MessageActionTypes.SET_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      };
    case MessageActionTypes.CLEAR_MESSAGE:
      return { ...state, message: '', status: null };
    default:
      return state;
  }
}
