import { ThunkDispatch } from 'redux-thunk';
import {
  ICategoriesList,
  ICategory,
  IPreferredCategoriesPayload,
  IPreferredCategoriesResponse
} from '../../domains/models/categories';
import { IVideosResponse } from '../../domains/models/videos';
import { togglePrerender } from '../../utils/prerender';
import { IState } from '../reducers';
import {
  getCategoriesData,
  getCategoriesListData,
  getCategoryData,
  getPreferredCategoriesData,
  postPreferredCategoriesData
} from '../thunk/categories';
import { VideosAction } from './videos';

export enum CategoriesActionTypes {
  SET_CATEGORIES_LOADER = 'categories/set-categories-loader',
  GET_CATEGORIES_SUCCESS = 'videos/get-categories-success',
  GET_CATEGORY_VIDEOS_SUCCESS = 'videos/get-category_videos_success',
  GET_CATEGORIES_LIST_SUCCESS = 'videos/get-categories-list-success',
  GET_PREFERRED_CATEGORIES_SUCCESS = 'videos/get-preferred-categories-success'
}

export interface ISetCategoriesLoader {
  type: CategoriesActionTypes.SET_CATEGORIES_LOADER;
  payload: boolean;
}

export const setCategoriesLoader = (
  isLoading: boolean
): ISetCategoriesLoader => {
  togglePrerender(isLoading);
  return ({
    type: CategoriesActionTypes.SET_CATEGORIES_LOADER,
    payload: isLoading
  });
};

export const categoriesRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, CategoriesAction>) => {
    await dispatch(getCategoriesData());
  };
};

export interface IGetCategoriesSuccess {
  type: CategoriesActionTypes.GET_CATEGORIES_SUCCESS;
  payload: ICategory[];
}

export const getCategoriesSuccess = (
  payload: ICategory[]
): IGetCategoriesSuccess => ({
  type: CategoriesActionTypes.GET_CATEGORIES_SUCCESS,
  payload
});

export const categoryRequested = (
  id: string,
  page: number,
  sort: string,
  isUpdating: boolean
) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(getCategoryData(id, page, sort, isUpdating));
  };
};

export interface IGetCategoryVideosSuccess {
  type: CategoriesActionTypes.GET_CATEGORY_VIDEOS_SUCCESS;
  payload: {
    videos: IVideosResponse;
    isUpdating: boolean;
  };
}

export const getCategoryVideosSuccess = (payload: {
  videos: IVideosResponse;
  isUpdating: boolean;
}): IGetCategoryVideosSuccess => ({
  type: CategoriesActionTypes.GET_CATEGORY_VIDEOS_SUCCESS,
  payload
});

export const categoriesListRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, CategoriesAction>) => {
    await dispatch(getCategoriesListData());
  };
};

export interface IGetCategoriesListSuccess {
  type: CategoriesActionTypes.GET_CATEGORIES_LIST_SUCCESS;
  payload: ICategoriesList[];
}

export const getCategoriesListSuccess = (
  payload: ICategoriesList[]
): IGetCategoriesListSuccess => ({
  type: CategoriesActionTypes.GET_CATEGORIES_LIST_SUCCESS,
  payload
});

export const preferredCategoriesRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, CategoriesAction>) => {
    await dispatch(getPreferredCategoriesData());
  };
};

export interface IGetPreferredCategoriesSuccess {
  type: CategoriesActionTypes.GET_PREFERRED_CATEGORIES_SUCCESS;
  payload: IPreferredCategoriesResponse;
}

export const getPreferredCategoriesSuccess = (
  payload: IPreferredCategoriesResponse
): IGetPreferredCategoriesSuccess => ({
  type: CategoriesActionTypes.GET_PREFERRED_CATEGORIES_SUCCESS,
  payload
});

export const postPreferredCategoriesRequested = (
  payload: IPreferredCategoriesPayload
) => {
  return async (dispatch: ThunkDispatch<void, IState, CategoriesAction>) => {
    await dispatch(postPreferredCategoriesData(payload));
  };
};

export type CategoriesAction =
  | ISetCategoriesLoader
  | IGetCategoriesSuccess
  | IGetCategoryVideosSuccess
  | IGetCategoriesListSuccess
  | IGetPreferredCategoriesSuccess;
