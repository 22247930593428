import { IRegisterPayload } from '../../domains/models';
import { getToken } from '../../utils/token';
import { AuthAction, AuthActionTypes } from '../actions/auth';

export interface IAuthState {
  isLoading: boolean;
  isLoggedIn: boolean;
  errors: Partial<IRegisterPayload> | null;
  repeatedVerificationEmail: string;
}

const token = getToken();
export const initialUser = token ? { isLoggedIn: true } : { isLoggedIn: false };

export const initialAuthState: IAuthState = {
  ...initialUser,
  isLoading: false,
  errors: {
    name: '',
    email: '',
    dob: '',
    password: '',
    passwordConfirmation: ''
  },
  repeatedVerificationEmail: ''
};

export default function authReducer(
  state: IAuthState = initialAuthState,
  action: AuthAction
) {
  switch (action.type) {
    case AuthActionTypes.SET_AUTH_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true
      };
    case AuthActionTypes.LOGIN_FAILED:
      return {
        ...state,
        isLoggedIn: false
      };
    case AuthActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false
      };
    case AuthActionTypes.SET_REGISTER_API_ERRORS: {
      return {
        ...state,
        errors: action.payload
      };
    }
    case AuthActionTypes.GET_REPEATED_VERIFICATION_EMAIL: {
      return {
        ...state,
        repeatedVerificationEmail: action.payload
      };
    }
    default:
      return state;
  }
}
