import { ReactComponent as MyAccount } from '../../../assets/images/account.svg';
import { ReactComponent as BrowseChannels } from '../../../assets/images/browseChannels.svg';
import { ReactComponent as Categories } from '../../../assets/images/categories.svg';
import { ReactComponent as History } from '../../../assets/images/history.svg';
import { ReactComponent as Home } from '../../../assets/images/home.svg';
import { ReactComponent as LogIn } from '../../../assets/images/login.svg';
import { ReactComponent as Playlist } from '../../../assets/images/playlist.svg';
import { ReactComponent as Recent } from '../../../assets/images/recent.svg';
import { ReactComponent as Settings } from '../../../assets/images/settings.svg';
import { ReactComponent as SignOut } from '../../../assets/images/signOut.svg';
import { ReactComponent as SignUp } from '../../../assets/images/signup.svg';
import { ReactComponent as SubscribedChannels } from '../../../assets/images/subscribedChannels.svg';
import { ReactComponent as Trending } from '../../../assets/images/trending.svg';
import { ReactComponent as Wallet } from '../../../assets/images/wallet.svg';
import { ReactComponent as Watch } from '../../../assets/images/watch.svg';

export const menuTop = [
  {
    src: Home,
    title: 'home',
    url: '/'
  },
  {
    src: Categories,
    title: 'categories',
    url: '/categories'
  },
  {
    src: Trending,
    title: 'trending',
    url: '/trending'
  },
  {
    src: Recent,
    title: 'recent',
    url: '/recent'
  },
  {
    src: BrowseChannels,
    title: 'browseChannels',
    url: '/channels'
  },
  {
    src: SubscribedChannels,
    title: 'subscribedChannels',
    url: '/subscribed'
  },
  {
    src: Playlist,
    title: 'playlist',
    url: '/playlists'
  },
  {
    src: MyAccount,
    title: 'myChannel',
    url: '/channels/my'
  },
  {
    src: Wallet,
    title: 'wallet',
    url: '/account/wallet'
  }
];

export const menuMiddle = [
  {
    src: History,
    title: 'history',
    url: '/history'
  },
  {
    src: Watch,
    title: 'watchLater',
    url: '/wishlist'
  }
];

export const menuBottomNotAuthorization = [
  {
    src: Settings,
    title: 'settings',
    url: '/account/profile'
  },
  {
    src: LogIn,
    title: 'login',
    url: '/login'
  },
  {
    src: SignUp,
    title: 'signUp',
    url: '/register'
  }
];

export const menuBottomAuthorization = [
  {
    src: Settings,
    title: 'settings',
    url: '/account/profile'
  },
  {
    src: SignOut,
    title: 'signOut',
    url: '/signOut'
  }
];
