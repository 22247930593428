import { ThunkDispatch } from 'redux-thunk';
import { IHistoryResponse } from '../../domains/models/history';
import { IState } from '../reducers';
import {
  deleteHistoryData,
  deleteVideoHistoryData,
  getHistoryData
} from '../thunk/history';

export enum HistoryActionTypes {
  SET_HISTORY_LOADER = 'history/set-history-loader',
  GET_HISTORY_SUCCESS = 'history/get-history-success',
  DELETE_VIDEO_HISTORY_SUCCESS = 'history/delete-video-history-success',
  DELETE_HISTORY_SUCCESS = 'history/delete-history-success'
}

export interface ISetHistoryLoader {
  type: HistoryActionTypes.SET_HISTORY_LOADER;
  payload: boolean;
}

export const setHistoryLoader = (isLoading: boolean): ISetHistoryLoader => ({
  type: HistoryActionTypes.SET_HISTORY_LOADER,
  payload: isLoading
});

export const historyRequested = (page: number, isUpdating: boolean) => {
  return async (dispatch: ThunkDispatch<void, IState, HistoryAction>) => {
    await dispatch(getHistoryData(page, isUpdating));
  };
};

export interface IGetHistorySuccess {
  type: HistoryActionTypes.GET_HISTORY_SUCCESS;
  payload: IHistoryResponse;
}

export const getHistorySuccess = (
  payload: IHistoryResponse
): IGetHistorySuccess => ({
  type: HistoryActionTypes.GET_HISTORY_SUCCESS,
  payload
});

export const deleteVideoHistoryRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, HistoryAction>) => {
    await dispatch(deleteVideoHistoryData(id));
  };
};

export const deleteHistoryRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, HistoryAction>) => {
    await dispatch(deleteHistoryData());
  };
};

export interface IDeleteVideoHistorySuccess {
  type: HistoryActionTypes.DELETE_VIDEO_HISTORY_SUCCESS;
  payload: number;
}

export const deleteVideoHistorySuccess = (
  payload: number
): IDeleteVideoHistorySuccess => ({
  type: HistoryActionTypes.DELETE_VIDEO_HISTORY_SUCCESS,
  payload
});

export interface IDeleteHistorySuccess {
  type: HistoryActionTypes.DELETE_HISTORY_SUCCESS;
}

export const deleteHistorySuccess = (): IDeleteHistorySuccess => ({
  type: HistoryActionTypes.DELETE_HISTORY_SUCCESS
});

export type HistoryAction =
  | ISetHistoryLoader
  | IGetHistorySuccess
  | IDeleteHistorySuccess
  | IDeleteVideoHistorySuccess;
