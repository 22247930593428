import {IAd} from '../../domains/models';
import {AdsAction, AdsActionTypes} from '../actions/ads';

export type IAdsState = {
  ads: IAd[] | [];
  isLoading: boolean;
};

export const initialAdsState: IAdsState = {
  ads: [],
  isLoading: false,
};

export default function adsReducer(
    state: IAdsState = initialAdsState,
    action: AdsAction
) {
  switch (action.type) {
    case AdsActionTypes.SET_ADS_LOADER: {
      return {
        ...state,
        isLoading: action.payload
      };
    }

    case AdsActionTypes.GET_ADS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ads: action.payload
      };
    }

    case AdsActionTypes.GET_ADS_FAILED: {
      return {
        ...state,
        isLoading: false,
        ads: []
      };
    }

    default:
      return state;
  }
}
