import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IHistoryVideo } from '../../domains/models/history';
import {
  deleteWishlist,
  getWishlist,
  toggleVideoWishlist
} from '../../services/wishlist';
import { handleDateHorizontalBlocks } from '../../utils/handleDate';
import { handleErrors } from '../../utils/handleErrors';
import { Status } from '../../views/shared/components/CustomAlert/component';
import { setMessage } from '../actions/message';
import { toggleVideoWishlistSuccess } from '../actions/videos';
import {
  deleteVideoWishlistSuccess,
  deleteWishlistSuccess,
  getWishlistSuccess,
  setWishlistLoader
} from '../actions/wishlist';
import { IState } from '../reducers';
import { declineUser } from './auth';

export const getWishlistData = (page: number, isUpdating: boolean) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setWishlistLoader(true));
    try {
      const response = await getWishlist(page);
      await dispatch(declineUser(response));
      const { wishlist, paginate } = response.data.data;
      const transformVideos =
        handleDateHorizontalBlocks<IHistoryVideo>(wishlist);
      dispatch(
        getWishlistSuccess({
          wishlistData: {
            wishlistVideoData: transformVideos,
            totalPages: paginate.totalPages
          },
          isUpdating
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setWishlistLoader(false));
    }
  };
};

export const deleteVideoWishlistData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setWishlistLoader(true));
    try {
      const response = await toggleVideoWishlist(id);
      await dispatch(declineUser(response));
      dispatch(deleteVideoWishlistSuccess(id));
      dispatch(
        setMessage({
          message: 'videoDeletedSuccessfully',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setWishlistLoader(false));
    }
  };
};

export const deleteWishlistData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setWishlistLoader(true));
    try {
      const response = await deleteWishlist();
      await dispatch(declineUser(response));
      dispatch(deleteWishlistSuccess());
      dispatch(
        setMessage({
          message: 'watchLaterDeletedSuccessfully',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setWishlistLoader(false));
    }
  };
};

export const toggleVideoWishlistData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setWishlistLoader(true));
    try {
      const response = await toggleVideoWishlist(id);
      await dispatch(declineUser(response));
      dispatch(toggleVideoWishlistSuccess());
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setWishlistLoader(false));
    }
  };
};
