import dayjs from 'dayjs';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IChannel } from '../../domains/models';
import { ICreateChannelPayload } from '../../domains/models/channels';
import {
  changeChannel,
  createChannel,
  deleteChannel,
  editChannel,
  getAssociatedChannels,
  getChannel,
  getChannels,
  getChannelsList,
  getIsSubscribed,
  getMyChannels,
  getPreferredChannels,
  getSubscribedChannels,
  getSubscribers
} from '../../services/channels';
import { handlePlaylistDate, handleVideosDate } from '../../utils/handleDate';
import { handleError422, handleErrors } from '../../utils/handleErrors';
import { Status } from '../../views/shared/components/CustomAlert/component';
import { getSubscribeIsSuccess } from '../actions/home';
import { setMessage } from '../actions/message';
import { redirect } from '../actions/redirect';
import { setSnackbar } from '../actions/snackbar';
import {
  changeChannelSuccess,
  deleteChannelSuccess,
  getAssociatedChannelsSuccess,
  getChannelsListSuccess,
  getChannelsSuccess,
  getChannelSuccess,
  getIsSubscribedInChannelsSuccess,
  getIsSubscribedInChannelSuccess,
  getMyChannelsSuccess,
  getPreferredChannelsSuccess,
  getSubscribedChannelsSuccess,
  getSubscribersSuccess,
  setChannelApiErrors,
  setChannelsLoader
} from '../actions/сhannels';
import { IState } from '../reducers';
import { declineUser } from './auth';
import { getBalanceData } from './profile';

export const getChannelsData = (page: number, sort: string) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setChannelsLoader(true));
    try {
      const response = await getChannels(page, sort);
      await dispatch(declineUser(response));
      const { paginate, channels } = response.data.data;
      const transformChannels = channels.map((channel: IChannel) => ({
        ...channel,
        createdAt: dayjs(channel.createdAt).format('D MMM YYYY')
      }));
      dispatch(
        getChannelsSuccess({
          channels: {
            channelsData: transformChannels,
            total: paginate.total,
            totalPages: paginate.totalPages
          },
          isUpdating: page === 1
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setChannelsLoader(false));
    }
  };
};

export const getSubscribedChannelsData = (page: number, sort: string) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setChannelsLoader(true));
    try {
      const response = await getSubscribedChannels(page, sort);
      await dispatch(declineUser(response));
      const { paginate, channels } = response.data.data;
      const transformChannels = channels.map((channel: IChannel) => ({
        ...channel,
        createdAt: dayjs(channel.createdAt).format('D MMM YYYY')
      }));
      dispatch(
        getSubscribedChannelsSuccess({
          channels: {
            channelsData: transformChannels,
            total: paginate.total,
            totalPages: paginate.totalPages
          },
          isUpdating: page === 1
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setChannelsLoader(false));
    }
  };
};

export const getChannelData = (id: string) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setChannelsLoader(true));
    try {
      const response = await getChannel(id);
      await dispatch(declineUser(response));
      const { channel, home, playlist, videos } = response.data.data;
      const transformChannel = {
        ...channel,
        createdAt: dayjs(channel.createdAt).format('D MMM YYYY')
      };
      dispatch(
        getChannelSuccess({
          channelData: transformChannel,
          home: handleVideosDate(home),
          playlist: handlePlaylistDate(playlist),
          videos: handleVideosDate(videos)
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setChannelsLoader(false));
    }
  };
};

export const createChannelData = (payload: ICreateChannelPayload) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setChannelsLoader(true));
    try {
      const response = await createChannel(payload);
      await dispatch(declineUser(response));
      const { channel } = response.data.data;
      dispatch(redirect(`/channels/${channel.id}/home`));
      dispatch(
        setMessage({
          message: 'channelCreatedSuccessfully',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      if (error.response.status === 422) {
        const error422Data = handleError422(error.response.data.errors);
        dispatch(setChannelApiErrors(error422Data));
      } else {
        const errorData = handleErrors(error);
        dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      }
    } finally {
      dispatch(setChannelsLoader(false));
    }
  };
};

export const getMyChannelsData = (page: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setChannelsLoader(true));
    try {
      const response = await getMyChannels(page);
      await dispatch(declineUser(response));
      const { paginate, channels } = response.data.data;
      const transformChannels = channels.map((channel: IChannel) => ({
        ...channel,
        createdAt: dayjs(channel.createdAt).format('D MMM YYYY')
      }));
      dispatch(
        getMyChannelsSuccess({
          channels: {
            channelsData: transformChannels,
            total: paginate.total,
            totalPages: paginate.totalPages
          },
          isUpdating: page === 1
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setChannelsLoader(false));
    }
  };
};

export const getIsSubscribedInChannelData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await getIsSubscribed(id);
      await dispatch(declineUser(response));
      await dispatch(getBalanceData());
      const { channel } = response.data.data;
      const transformChannel = {
        ...channel,
        createdAt: dayjs(channel.createdAt).format('D MMM YYYY')
      };
      dispatch(
        getIsSubscribedInChannelSuccess({
          channel: transformChannel
        })
      );

      channel.subscribeStatus
        ? dispatch(
            setMessage({
              message: 'successfullySubscribedChannel',
              status: Status.SUCCESS
            })
          )
        : dispatch(
            setMessage({
              message: 'successfullyUnsubscribedChannel',
              status: Status.WARNING
            })
          );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const getIsSubscribedInChannelsData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await getIsSubscribed(id);
      await dispatch(declineUser(response));
      await dispatch(getBalanceData());
      const { channel } = response.data.data;
      const transformChannel = {
        ...channel,
        createdAt: dayjs(channel.createdAt).format('D MMM YYYY')
      };
      dispatch(
        getIsSubscribedInChannelsSuccess({
          channel: transformChannel
        })
      );
      dispatch(
        getSubscribeIsSuccess({
          channel: transformChannel
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const editChannelData = (payload: ICreateChannelPayload, id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setChannelsLoader(true));
    try {
      const response = await editChannel(payload, id);
      await dispatch(declineUser(response));
      const { channel } = response.data.data;
      dispatch(redirect(`/channels/${channel.id}/home`));
      dispatch(
        setMessage({
          message: 'channelUpdatedSuccessfully',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      if (error.response.status === 422) {
        const error422Data = handleError422(error.response.data.errors);
        dispatch(setChannelApiErrors(error422Data));
      } else {
        const errorData = handleErrors(error);
        dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      }
    } finally {
      dispatch(setChannelsLoader(false));
    }
  };
};

export const deleteChannelData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setChannelsLoader(true));
    try {
      const response = await deleteChannel(id);
      await dispatch(declineUser(response));
      dispatch(deleteChannelSuccess(id));
      dispatch(redirect(`/channels/my`));
      dispatch(
        setMessage({
          message: 'channelDeletedSuccessfully',
          status: Status.WARNING
        })
      );
      dispatch(
        setSnackbar({
          snackbar: 'channelDeletedSuccessfully',
          status: Status.WARNING
        })
      );
    } catch (error: any) {
      if (error.response.status === 422) {
        const error422Data = handleError422(error.response.data.errors);
        dispatch(setChannelApiErrors(error422Data));
      } else {
        const errorData = handleErrors(error);
        dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      }
    } finally {
      dispatch(setChannelsLoader(false));
    }
  };
};

export const getSubscribersData = (page: number, channelId: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setChannelsLoader(true));
    try {
      const response = await getSubscribers(page, channelId);
      await dispatch(declineUser(response));
      dispatch(
        getSubscribersSuccess({
          subscribersData: response.data.data,
          isUpdating: page === 1
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setChannelsLoader(false));
    }
  };
};

export const getPreferredChannelsData = (page: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setChannelsLoader(true));
    try {
      const response = await getPreferredChannels(page);
      await dispatch(declineUser(response));
      const { paginate, preferredChannels } = response.data.data;
      const transformChannels = preferredChannels.map((channel: IChannel) => ({
        ...channel,
        createdAt: dayjs(channel.createdAt).format('D MMM YYYY')
      }));
      dispatch(
        getPreferredChannelsSuccess({
          channels: {
            preferredChannels: transformChannels,
            paginate
          },
          isUpdating: page === 1
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setChannelsLoader(false));
    }
  };
};

export const getChannelsListData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await getChannelsList();
      await dispatch(declineUser(response));
      dispatch(getChannelsListSuccess(response.data.data.channels));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setSnackbar({ snackbar: errorData, status: Status.ERROR }));
    }
  };
};

export const changeChannelData = (videoId: number, channelId: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await changeChannel(videoId, channelId);
      await dispatch(declineUser(response));
      dispatch(
        setMessage({
          message: 'yourVideoSuccessfullyUpdated',
          status: Status.SUCCESS
        })
      );
      dispatch(changeChannelSuccess(videoId));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setSnackbar({ snackbar: errorData, status: Status.ERROR }));
    }
  };
};

export const getAssociatedChannelsData = (channelId: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await getAssociatedChannels(channelId);
      dispatch(getAssociatedChannelsSuccess(response.data.data));
    } catch (error) {
      // const errorData = handleErrors(error);
      // dispatch(setMessage({ message: errorData, status: Status.ERROR })); // TO-DO: Не меняется цвет
      dispatch(
        getAssociatedChannelsSuccess({
          channelsData: [],
          total: 0,
          totalPages: 0
        })
      );
    }
  };
};
