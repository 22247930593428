import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../assets/styles/configs/themeMaterials';

export const useStyles = makeStyles((fullTheme: Theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: '30px 80px 45px',
      [fullTheme.breakpoints.down('sm')]: {
        padding: '0'
      },
      maxWidth: '450px'
    },
    '& .MuiDialogTitle-root': {
      fontFamily: theme.fontFamily.primary,
      color: theme.common.primary,
      textAlign: 'center',
      fontSize: '18px',
      lineHeight: '27px'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: theme.bg.overlay
    }
  }
}));
