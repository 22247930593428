import { IHistoryVideo } from '../../domains/models/history';
import { WishlistAction, WishlistActionTypes } from '../actions/wishlist';

export interface IWishlistState {
  isLoading: boolean;
  wishlistVideoData: IHistoryVideo[] | null;
  totalPages: number;
  isVideoInTheWishlist: boolean;
}

export const initialWishlistState: IWishlistState = {
  isLoading: false,
  wishlistVideoData: null,
  totalPages: 0,
  isVideoInTheWishlist: false
};

export default function wishlistReducer(
  state: IWishlistState = initialWishlistState,
  action: WishlistAction
) {
  switch (action.type) {
    case WishlistActionTypes.SET_WISHLIST_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case WishlistActionTypes.GET_WISHLIST_SUCCESS: {
      const oldVideos = state.wishlistVideoData ? state.wishlistVideoData : [];
      const { isUpdating, wishlistData } = action.payload;
      return {
        ...state,
        wishlistVideoData: isUpdating
          ? wishlistData.wishlistVideoData
          : [...oldVideos, ...wishlistData.wishlistVideoData],
        totalPages: wishlistData.totalPages
      };
    }
    case WishlistActionTypes.DELETE_VIDEO_WISHLIST_SUCCESS: {
      return {
        ...state,
        wishlistVideoData: state.wishlistVideoData
          ? state.wishlistVideoData.filter(
              (video) => video.videoTape.id !== action.payload
            )
          : null
      };
    }
    case WishlistActionTypes.DELETE_WISHLIST_SUCCESS: {
      return {
        ...state,
        wishlistVideoData: []
      };
    }
    default:
      return state;
  }
}
