import { IHistoryVideo } from '../../domains/models/history';
import { HistoryAction, HistoryActionTypes } from '../actions/history';

export interface IHistoryState {
  isLoading: boolean;
  historyVideoData: IHistoryVideo[] | null;
  totalPages: number;
}

export const initialHistoryState: IHistoryState = {
  isLoading: false,
  historyVideoData: null,
  totalPages: 0
};

export default function historyReducer(
  state: IHistoryState = initialHistoryState,
  action: HistoryAction
) {
  switch (action.type) {
    case HistoryActionTypes.SET_HISTORY_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case HistoryActionTypes.GET_HISTORY_SUCCESS: {
      const oldVideos = state.historyVideoData ? state.historyVideoData : [];
      const { isUpdating, historyData } = action.payload;
      return {
        ...state,
        historyVideoData: isUpdating
          ? historyData.historyVideoData
          : [...oldVideos, ...historyData.historyVideoData],
        totalPages: historyData.totalPages
      };
    }
    case HistoryActionTypes.DELETE_VIDEO_HISTORY_SUCCESS: {
      return {
        ...state,
        historyVideoData: state.historyVideoData
          ? state.historyVideoData.filter(
              (video) => video.id !== action.payload
            )
          : null
      };
    }
    case HistoryActionTypes.DELETE_HISTORY_SUCCESS: {
      return {
        ...state,
        historyVideoData: []
      };
    }
    default:
      return state;
  }
}
