import { AxiosResponse } from 'axios';
import {
  IChangePasswordPayload,
  IDeleteAccountPayload,
  IProfilePayload
} from '../domains/models';
import { IAvailableChannelsForMonetization } from '../domains/models/channels';
import {
  IBalance,
  IChannelMonetization, IExperience,
  IReferrals,
  ISendReferralEmail,
  IUserAnalyticDetail,
  IUserAnalyticResponse
} from '../domains/models/profile';
import { AxiosDataResponse } from '../domains/models/response';
import { instanceAxios } from './instanceAxios';

export type IGetBalanceResponse = {
  balance: IBalance;
  referrals: IReferrals[];
  experience: IExperience;
};

export const getProfile = () => {
  return instanceAxios.get(`/user/profile/me`);
};

export const getBalance = (): Promise<
  AxiosResponse<{ data: IGetBalanceResponse }>
> => {
  return instanceAxios.get(`/user/profile/balance`);
};

export const postProfile = (userData: IProfilePayload) => {
  return instanceAxios.post(`/user/profile/edit`, userData, {
    headers: { 'Content-type': 'multipart/form-data' }
  });
};

export const putChangePassword = (userData: IChangePasswordPayload) => {
  return instanceAxios.put(`/user/profile/change-password`, userData);
};

export const deleteAccount = (password: IDeleteAccountPayload) => {
  return instanceAxios.delete(`/user/profile/delete`, {
    data: JSON.stringify(password)
  });
};

export const postChannelMonetization = (payload: IChannelMonetization) => {
  return instanceAxios.post(`/user/creator`, payload);
};

export const getChannelMonetization = (): Promise<
  AxiosDataResponse<IAvailableChannelsForMonetization>
> => {
  return instanceAxios.get(`/user/creator`);
};

export const getUserAnalytics = (): Promise<
  AxiosDataResponse<IUserAnalyticResponse>
> => {
  return instanceAxios.get(`/user/monetization`);
};

export const getUserAnalyticsDetail = (
  page: number,
  limit: number
): Promise<AxiosDataResponse<IUserAnalyticDetail>> => {
  return instanceAxios.get(
    `/user/monetization/details?page=${page}&pagination=${limit}`
  );
};

export const claimReward = () => {
  return instanceAxios.post(`/referral/claim`);
};

export const sendReferralEmail = (payload: ISendReferralEmail) => {
  return instanceAxios.post(`/referral/email`, payload);
};
