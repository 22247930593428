import {
  createTheme,
  CssBaseline,
  Grid,
  ThemeProvider
} from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React, { Suspense, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { getFullProfileRequested } from './application/actions/profile';
import { myChannelsRequested } from './application/actions/сhannels';
import {
  receiveDeclining,
  receiveProfile,
  receiveRedirect
} from './application/reducers';
import {
  getDesignTokens,
  theme as customTheme
} from './assets/styles/configs/themeMaterials';
import { PlaylistType } from './domains/models/playlist';
import './i18n';
import { getToken } from './utils/token';
import { ErrorBoundary } from './views/components/ErrorBoundary';
import { Loader } from './views/components/Loader';
import { ProtectedRoute } from './views/components/ProtectedRoute';
import { Layout } from './views/containers/Layout';

const HomePage = React.lazy(() => import('./views/containers/HomePage'));
const AccountPage = React.lazy(() => import('./views/containers/AccountPage'));
const BrowseChannels = React.lazy(
  () => import('./views/containers/BrowseChannels')
);
const Category = React.lazy(() => import('./views/containers/Category'));
const CategoryVideo = React.lazy(
  () => import('./views/containers/CategoryVideo')
);
const CreateChannel = React.lazy(
  () => import('./views/containers/CreateChannel')
);
const EditChannel = React.lazy(() => import('./views/containers/EditChannel'));
const Channel = React.lazy(() => import('./views/containers/Channel'));
const ConfirmRegister = React.lazy(
  () => import('./views/containers/ConfirmRegister')
);
const ConfirmWithdrawal = React.lazy(
  () => import('./views/containers/ConfirmWithdrawal')
);
const Embed = React.lazy(() => import('./views/containers/Embed'));
const ErrorPage = React.lazy(() => import('./views/containers/ErrorPage'));
const ForgotPassword = React.lazy(
  () => import('./views/containers/ForgotPassword')
);
const History = React.lazy(() => import('./views/containers/History'));
const Login = React.lazy(() => import('./views/containers/Login'));
const MyChannels = React.lazy(() => import('./views/containers/MyChannels'));
const Playlist = React.lazy(() => import('./views/containers/Playlist'));
const PlaylistVideo = React.lazy(
  () => import('./views/containers/PlaylistVideo')
);
const PreferredCategory = React.lazy(
  () => import('./views/containers/PreferredCategory')
);
const PreferredChannel = React.lazy(
  () => import('./views/containers/PreferredChannel')
);
const Recent = React.lazy(() => import('./views/containers/Recent'));
const Recommended = React.lazy(() => import('./views/containers/Recommended'));
const Register = React.lazy(() => import('./views/containers/Register'));
const SearchResults = React.lazy(
  () => import('./views/containers/SearchResults')
);
const Spam = React.lazy(() => import('./views/containers/Spam'));
const Subscribed = React.lazy(() => import('./views/containers/Subscribed'));
const SubscribersOfChannel = React.lazy(
  () => import('./views/containers/SubscribersOfChannel')
);
const TagVideo = React.lazy(() => import('./views/containers/TagVideo'));
const TrendingVideos = React.lazy(() => import('./views/containers/Trending'));
const UploadVideo = React.lazy(() => import('./views/containers/UploadVideo'));
const UploadVideoInfo = React.lazy(
  () => import('./views/containers/UploadVideoInfo')
);
const VideosPage = React.lazy(() => import('./views/containers/VideosPage'));
const WatchLater = React.lazy(() => import('./views/containers/WatchLater'));

interface ComponentProps {
  type?: PlaylistType;
}

type ComponentTypes = PlaylistType;

function App() {
  const dispatch = useDispatch();
  const theme = createTheme(getDesignTokens('dark'));
  const { redirectTo } = useSelector(receiveRedirect);
  const { user } = useSelector(receiveProfile);
  const { declined } = useSelector(receiveDeclining);
  const token = getToken();

  useEffect(() => {
    if (token) {
      dispatch(getFullProfileRequested());
      dispatch(myChannelsRequested(1));
    }
  }, [token, dispatch]);

  const getComponentWithLayout = (
    Component: React.FC<ComponentProps>,
    type?: ComponentTypes
  ) => {
    return (
      <Layout>
        <Suspense fallback={false}>
          <Component type={type} />
        </Suspense>
      </Layout>
    );
  };

  const getComponent = (Component: React.FC) => {
    return (
      <Suspense fallback={false}>
        <Component />
      </Suspense>
    );
  };

  if (token && !user) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <Grid
            container
            alignItems={'center'}
            sx={{
              backgroundColor: customTheme.bg.mainBlock,
              height: '100vh'
            }}
          >
            <Loader />
          </Grid>
        </ErrorBoundary>
      </ThemeProvider>
    );
  }

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {redirectTo && !token && <Redirect to={redirectTo} />}
          <ErrorBoundary>
            <Switch>
              <Route
                path="/login"
                exact={true}
                render={() => getComponent(Login)}
              />
              <Route
                path="/password/reset"
                exact={true}
                render={() => getComponent(ForgotPassword)}
              />
              <Route
                path="/register"
                exact={true}
                render={() => getComponent(Register)}
              />
              <Route
                path="/confirm-register"
                exact={true}
                render={() => getComponent(ConfirmRegister)}
              />
              <Route
                path="/categories"
                exact={true}
                render={() => getComponentWithLayout(Category)}
              />
              <Route
                path="/trending"
                exact={true}
                render={() => getComponentWithLayout(TrendingVideos)}
              />
              <ProtectedRoute
                path="/account"
                authenticationPath="/login"
                render={() => getComponentWithLayout(AccountPage)}
              />
              <ProtectedRoute
                path="/withdrawal"
                exact={true}
                authenticationPath="/login"
                render={() => getComponent(ConfirmWithdrawal)}
              />
              <ProtectedRoute
                path="/categories/preferred"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(PreferredCategory)}
              />
              <ProtectedRoute
                path="/channels/preferred"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(PreferredChannel)}
              />
              <Route
                exact={true}
                path={`/categories/:id`}
                render={() => getComponentWithLayout(CategoryVideo)}
              />
              <Route
                exact={true}
                path={`/tags/list/:id`}
                render={() => getComponentWithLayout(TagVideo)}
              />
              <ProtectedRoute
                path="/subscribed"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(Subscribed)}
              />
              <Route
                path="/recent"
                exact={true}
                render={() => getComponentWithLayout(Recent)}
              />
              <Route
                path="/recommended"
                exact={true}
                render={() => getComponentWithLayout(Recommended)}
              />
              <ProtectedRoute
                path="/history"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(History)}
              />
              <ProtectedRoute
                path="/playlists"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(Playlist)}
              />
              <ProtectedRoute
                path="/playlists/:playlistId/videos/:videoId"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(VideosPage)}
              />
              <ProtectedRoute
                path="/user-playlist/:id"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(PlaylistVideo)}
              />
              <ProtectedRoute
                path="/channel-playlist/:id"
                exact={true}
                authenticationPath="/login"
                render={() =>
                  getComponentWithLayout(PlaylistVideo, PlaylistType.CHANNEL)
                }
              />
              <ProtectedRoute
                path="/wishlist"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(WatchLater)}
              />
              <ProtectedRoute
                path="/spam"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(Spam)}
              />
              <Route
                path="/user/search/:query"
                render={() => getComponentWithLayout(SearchResults)}
              />
              <ProtectedRoute
                path="/channels/my"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(MyChannels)}
              />
              <ProtectedRoute
                path="/create-channel"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(CreateChannel)}
              />
              <ProtectedRoute
                path="/edit-channel/:id"
                exact={true}
                authenticationPath="/login"
                render={() => getComponentWithLayout(EditChannel)}
              />
              <Route
                path="/channels/:id"
                render={() => getComponentWithLayout(Channel)}
              />
              <ProtectedRoute
                path="/subscribers/:id"
                authenticationPath="/login"
                render={() => getComponentWithLayout(SubscribersOfChannel)}
              />
              <ProtectedRoute
                path="/video/upload"
                authenticationPath="/login"
                exact={true}
                render={() => getComponentWithLayout(UploadVideo)}
              />
              <ProtectedRoute
                path="/video/upload/info"
                authenticationPath="/login"
                exact={true}
                render={() => getComponentWithLayout(UploadVideoInfo)}
              />
              <ProtectedRoute
                path="/edit-video/info"
                authenticationPath="/login"
                exact={true}
                render={() => getComponentWithLayout(UploadVideoInfo)}
              />
              <Route
                path="/video/:id"
                render={() => getComponentWithLayout(VideosPage)}
              />
              <Route path="/embed" render={() => getComponent(Embed)} />
              <Route
                path="/channels"
                exact={true}
                render={() => getComponentWithLayout(BrowseChannels)}
              />
              <Route
                path="/"
                exact={true}
                render={() => getComponentWithLayout(HomePage)}
              />
              <Route path="*" component={ErrorPage} />
            </Switch>
          </ErrorBoundary>
          {declined && <Redirect to={'/'} />}
        </SnackbarProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
