import {
  IUploadVideo,
  IUploadVideoPreviewResponse,
  IUploadVideoResponse,
  IVideoData,
  IVideosResponse
} from '../../domains/models/videos';
import { getComments, updateLikesComments } from '../../utils/handleComments';
import { VideosAction, VideosActionTypes } from '../actions/videos';

export interface IVideosState {
  isLoading: boolean;
  isDropzoneLoading: boolean;
  recentVideos: IVideosResponse | null;
  trendingVideos: IVideosResponse | null;
  recommendedVideos: IVideosResponse | null;
  suggestVideos: IVideosResponse | null;
  tagVideos: IVideosResponse | null;
  video: IVideoData | null;
  uploadVideoData: IUploadVideoResponse | null;
  videoApiErrors: Partial<IUploadVideo> | null;
  preview: IUploadVideoPreviewResponse | null;
  youtubeLink: string;
  canMonetise: boolean;
  isDeletingCaptcha: boolean;
  isSharePopupOpen: boolean;
}

export const initialVideosState: IVideosState = {
  isLoading: false,
  isDropzoneLoading: false,
  recentVideos: null,
  trendingVideos: null,
  recommendedVideos: null,
  suggestVideos: null,
  tagVideos: null,
  video: null,
  uploadVideoData: null,
  videoApiErrors: null,
  preview: null,
  youtubeLink: '',
  canMonetise: true,
  isDeletingCaptcha: false,
  isSharePopupOpen: false
};

export default function videosReducer(
  state: IVideosState = initialVideosState,
  action: VideosAction
) {
  switch (action.type) {
    case VideosActionTypes.SET_VIDEOS_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case VideosActionTypes.SET_VIDEOS_DROPZONE_LOADER:
      return {
        ...state,
        isDropzoneLoading: action.payload
      };
    case VideosActionTypes.GET_RECENT_VIDEOS_SUCCESS: {
      const { recentVideos, isUpdating } = action.payload;
      const oldVideos = state.recentVideos ? state.recentVideos.videoData : [];
      return {
        ...state,
        recentVideos: {
          videoData: isUpdating
            ? recentVideos.videoData
            : [...oldVideos, ...recentVideos.videoData],
          total: recentVideos.total,
          totalPages: recentVideos.totalPages
        }
      };
    }
    case VideosActionTypes.GET_TRENDING_VIDEOS_SUCCESS: {
      const oldVideos = state.trendingVideos
        ? state.trendingVideos.videoData
        : [];
      const { trendingVideos, isUpdating } = action.payload;
      return {
        ...state,
        trendingVideos: {
          videoData: isUpdating
            ? trendingVideos.videoData
            : [...oldVideos, ...trendingVideos.videoData],
          total: trendingVideos.total,
          totalPages: action.payload.trendingVideos.totalPages
        }
      };
    }
    case VideosActionTypes.GET_RECOMMENDED_VIDEOS_SUCCESS: {
      const oldVideos = state.recommendedVideos
        ? state.recommendedVideos.videoData
        : [];
      const { recommendedVideos, isUpdating } = action.payload;
      return {
        ...state,
        recommendedVideos: {
          videoData: isUpdating
            ? recommendedVideos.videoData
            : [...oldVideos, ...recommendedVideos.videoData],
          total: recommendedVideos.total,
          totalPages: recommendedVideos.totalPages
        }
      };
    }
    case VideosActionTypes.GET_SUGGEST_VIDEOS_SUCCESS: {
      return {
        ...state,
        suggestVideos: {
          videoData: action.payload.videoData,
          total: action.payload.total,
          totalPages: action.payload.totalPages
        }
      };
    }
    case VideosActionTypes.GET_VIDEO_SUCCESS: {
      const { comments, ...rest } = action.payload.videoData;
      const addedComments =
        state.video && action.payload.page !== 1
          ? [
              ...state.video.comments.commentsData,
              ...comments.commentsData
            ].filter(
              (comment, index, allComments) =>
                allComments.findIndex(
                  (anotherComments) => anotherComments.id === comment.id
                ) === index
            )
          : comments.commentsData;
      return {
        ...state,
        video: {
          ...rest,
          comments: {
            commentsData: addedComments,
            paginate: comments.paginate
          }
        }
      };
    }
    case VideosActionTypes.TOGGLE_VIDEO_WISHLIST_SUCCESS: {
      return {
        ...state,
        video: {
          ...state.video,
          wishlistStatus: state.video?.wishlistStatus ? null : 1
        }
      };
    }
    case VideosActionTypes.LIKE_VIDEO_SUCCESS: {
      return {
        ...state,
        video: {
          ...state.video,
          likeCount: action.payload.likeCount,
          dislikeCount: action.payload.dislikeCount
        }
      };
    }
    case VideosActionTypes.GET_IS_SUBSCRIBED_IN_VIDEO_SUCCESS: {
      return {
        ...state,
        video: {
          ...state.video,
          subscribeStatus: action.payload
        }
      };
    }
    case VideosActionTypes.UPLOAD_VIDEO_SUCCESS: {
      return {
        ...state,
        uploadVideoData: action.payload
      };
    }
    case VideosActionTypes.SET_VIDEO_API_ERRORS: {
      return {
        ...state,
        videoApiErrors: action.payload
      };
    }
    case VideosActionTypes.UPLOAD_VIDEO_PREVIEW_SUCCESS: {
      return {
        ...state,
        preview: action.payload
      };
    }
    case VideosActionTypes.CLEAR_VIDEO_PREVIEW: {
      return {
        ...state,
        preview: null
      };
    }
    case VideosActionTypes.GET_YOUTUBE_LINK: {
      return {
        ...state,
        youtubeLink: action.payload
      };
    }
    case VideosActionTypes.CREATE_COMMENT_SUCCESS: {
      let newComments;

      if (!action.payload.parentId && state.video) {
        newComments = [action.payload, ...state.video.comments.commentsData];
      } else {
        newComments =
          state.video &&
          getComments(state.video.comments.commentsData, action.payload);
      }
      const addedComments = {
        ...state.video?.comments,
        commentsData: newComments
      };
      return {
        ...state,
        video: {
          ...state.video,
          comments: addedComments
        }
      };
    }
    case VideosActionTypes.LIKE_COMMENT_SUCCESS: {
      const updateLikes =
        state.video &&
        updateLikesComments(
          state.video.comments.commentsData,
          action.payload,
          action.id
        );
      const addedComments = {
        ...state.video?.comments,
        commentsData: updateLikes
      };
      return {
        ...state,
        video: {
          ...state.video,
          comments: addedComments,
          pinnedComment: state?.video?.pinnedComment
            ? {
                ...state.video.pinnedComment,
                likeCount: action.payload.likeCount,
                dislikeCount: action.payload.dislikeCount
              }
            : null
        }
      };
    }
    case VideosActionTypes.PIN_COMMENT_SUCCESS: {
      const sortedComments = state.video?.pinnedComment
        ? [
            { ...state.video?.pinnedComment, pinned: 0 },
            ...state.video.comments.commentsData
          ].sort((a, b) => b.createdAt.localeCompare(a.createdAt))
        : state.video && state.video.comments.commentsData;

      const findComment =
        state.video &&
        sortedComments?.find((comment) => comment.id === action.id);

      const filteredComments =
        state.video &&
        sortedComments?.filter((comment) => comment.id !== action.id);

      const addedComments = {
        ...state.video?.comments,
        commentsData: action.unpin ? sortedComments : filteredComments
      };

      return {
        ...state,
        video: {
          ...state.video,
          comments: addedComments,
          pinnedComment: !action.unpin
            ? {
                ...findComment,
                pinned: 1
              }
            : null
        }
      };
    }
    case VideosActionTypes.GET_TAG_VIDEOS_SUCCESS: {
      const oldVideos = state.tagVideos ? state.tagVideos.videoData : [];
      const { tagVideos, isUpdating } = action.payload;
      return {
        ...state,
        tagVideos: {
          videoData: isUpdating
            ? tagVideos.videoData
            : [...oldVideos, ...tagVideos.videoData],
          total: tagVideos.total,
          totalPages: tagVideos.totalPages
        }
      };
    }
    case VideosActionTypes.CLEAR_VIDEO: {
      return {
        ...state,
        video: null,
        suggestVideos: null
      };
    }
    case VideosActionTypes.SET_MONETISE: {
      return {
        ...state,
        canMonetise: action.payload
      };
    }
    case VideosActionTypes.DELETE_CAPTCHA: {
      return {
        ...state,
        isDeletingCaptcha: action.payload
      };
    }

    case VideosActionTypes.TOGGLE_SHARE_POPUP: {
      return {
        ...state,
        isSharePopupOpen: action.payload
      };
    }
    default:
      return state;
  }
}
