import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getToken } from '../utils/token';

const { REACT_APP_SERVER_URL, REACT_APP_API_URL, REACT_APP_URL } = process.env;

export const instanceAxios: AxiosInstance = axios.create({
  baseURL: `${REACT_APP_URL}${REACT_APP_SERVER_URL}${REACT_APP_API_URL}`,
  withCredentials: true,
  headers: {
    Accept: 'application/json'
  }
});

instanceAxios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken();

    if (config.headers && !config.headers['Content-type']) {
      config.headers = {
        ...config.headers,
        'Content-type': 'application/json'
      };
    }

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: 'Bearer ' + token
      };
    }
    return config;
  },
  (error) => {
    throw new Error(error);
  }
);
