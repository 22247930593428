import { ThunkDispatch } from 'redux-thunk';
import { ICategoriesList } from '../../domains/models/categories';
import {
  IChannel,
  IChannelData,
  IChannelsResponse,
  ICreateChannelPayload,
  IGetIsSubscribedResponse,
  IGetPreferredChannelsResponse,
  IGetSubscribersResponse
} from '../../domains/models/channels';
import { IPlaylist } from '../../domains/models/playlist';
import { togglePrerender } from '../../utils/prerender';
import { IState } from '../reducers';
import {
  changeChannelData,
  createChannelData,
  deleteChannelData,
  editChannelData,
  getAssociatedChannelsData,
  getChannelData,
  getChannelsData,
  getChannelsListData,
  getIsSubscribedInChannelData,
  getIsSubscribedInChannelsData,
  getMyChannelsData,
  getPreferredChannelsData,
  getSubscribedChannelsData,
  getSubscribersData
} from '../thunk/сhannels';

export enum ChannelActionTypes {
  SET_CHANNELS_LOADER = 'channels/set-channels-loader',
  GET_CHANNEL_SUCCESS = 'channel/get-channel-success',
  GET_CHANNELS_SUCCESS = 'channels/get-channels-success',
  GET_SUBSCRIBED_CHANNELS_SUCCESS = 'channels/get-subscribed-channels-success',
  GET_MY_CHANNELS_SUCCESS = 'channels/get-my-channels-success',
  SET_CHANNEL_API_ERRORS = 'channel/set-channel-api-errors',
  DELETE_CHANNEL_SUCCESS = 'channel/delete-channel-success',
  GET_IS_SUBSCRIBED_IN_CHANNEL_SUCCESS = 'channel/get-is-subscribed-in-channel-success',
  GET_IS_SUBSCRIBED_IN_CHANNELS_SUCCESS = 'channel/get-is-subscribed-in-channels-success',
  CREATE_CHANNEL_PLAYLIST_SUCCESS = 'channel/create-channel-playlist-success',
  DELETE_CHANNEL_PLAYLIST_SUCCESS = 'channel/delete-channel-playlist-success',
  GET_SUBSCRIBERS_SUCCESS = 'channel/get-subscribers-success',
  GET_PREFERRED_CHANNELS_SUCCESS = 'channel/get-preferred-channels-success',
  GET_CHANNELS_LIST_SUCCESS = 'channel/get-channels-list-success',
  CHANGE_CHANNEL_SUCCESS = 'channel/change-channel-success',
  DELETE_VIDEO_SUCCESS = 'channel/delete-video-success',
  GET_ASSOCIATED_CHANNELS_SUCCESS = 'channel/get-associated-channels-success'
}

export interface ISetChannelsLoader {
  type: ChannelActionTypes.SET_CHANNELS_LOADER;
  payload: boolean;
}

export const setChannelsLoader = (isLoading: boolean): ISetChannelsLoader => {
  togglePrerender(isLoading);
  return {
    type: ChannelActionTypes.SET_CHANNELS_LOADER,
    payload: isLoading
  };
};

export const channelRequested = (id: string) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(getChannelData(id));
  };
};

export interface IGetChannelSuccess {
  type: ChannelActionTypes.GET_CHANNEL_SUCCESS;
  payload: IChannelData;
}

export const getChannelSuccess = (
  payload: IChannelData
): IGetChannelSuccess => ({
  type: ChannelActionTypes.GET_CHANNEL_SUCCESS,
  payload
});

export const channelsRequested = (page: number, sort: string) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(getChannelsData(page, sort));
  };
};

export interface IGetChannelsSuccess {
  type: ChannelActionTypes.GET_CHANNELS_SUCCESS;
  payload: {
    channels: IChannelsResponse;
    isUpdating: boolean;
  };
}

export const getChannelsSuccess = (payload: {
  channels: IChannelsResponse;
  isUpdating: boolean;
}): IGetChannelsSuccess => ({
  type: ChannelActionTypes.GET_CHANNELS_SUCCESS,
  payload
});

export const subscribedChannelsRequested = (page: number, sort: string) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(getSubscribedChannelsData(page, sort));
  };
};

export interface ISubscribedChannelsSuccess {
  type: ChannelActionTypes.GET_SUBSCRIBED_CHANNELS_SUCCESS;
  payload: {
    channels: IChannelsResponse;
    isUpdating: boolean;
  };
}

export const getSubscribedChannelsSuccess = (payload: {
  channels: IChannelsResponse;
  isUpdating: boolean;
}): ISubscribedChannelsSuccess => ({
  type: ChannelActionTypes.GET_SUBSCRIBED_CHANNELS_SUCCESS,
  payload
});

export const myChannelsRequested = (page: number) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(getMyChannelsData(page));
  };
};

export interface IMyChannelsSuccess {
  type: ChannelActionTypes.GET_MY_CHANNELS_SUCCESS;
  payload: {
    channels: IChannelsResponse;
    isUpdating: boolean;
  };
}

export const getMyChannelsSuccess = (payload: {
  channels: IChannelsResponse;
  isUpdating: boolean;
}): IMyChannelsSuccess => ({
  type: ChannelActionTypes.GET_MY_CHANNELS_SUCCESS,
  payload
});

export const createChannelRequested = (payload: ICreateChannelPayload) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(createChannelData(payload));
  };
};

export interface ISetChannelApiErrors {
  type: ChannelActionTypes.SET_CHANNEL_API_ERRORS;
  payload: Partial<IChannel> | null;
}

export const setChannelApiErrors = (
  errors: Partial<IChannel> | null
): ISetChannelApiErrors => ({
  type: ChannelActionTypes.SET_CHANNEL_API_ERRORS,
  payload: errors
});

export const isSubscribedInChannelRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(getIsSubscribedInChannelData(id));
  };
};

export const isSubscribedInChannelsRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(getIsSubscribedInChannelsData(id));
  };
};

export interface IGetIsSubscribedInChannelSuccess {
  type: ChannelActionTypes.GET_IS_SUBSCRIBED_IN_CHANNEL_SUCCESS;
  payload: IGetIsSubscribedResponse;
}

export const getIsSubscribedInChannelSuccess = (
  payload: IGetIsSubscribedResponse
): IGetIsSubscribedInChannelSuccess => ({
  type: ChannelActionTypes.GET_IS_SUBSCRIBED_IN_CHANNEL_SUCCESS,
  payload
});

export interface IGetIsSubscribedInChannelsSuccess {
  type: ChannelActionTypes.GET_IS_SUBSCRIBED_IN_CHANNELS_SUCCESS;
  payload: IGetIsSubscribedResponse;
}

export const getIsSubscribedInChannelsSuccess = (
  payload: IGetIsSubscribedResponse
): IGetIsSubscribedInChannelsSuccess => ({
  type: ChannelActionTypes.GET_IS_SUBSCRIBED_IN_CHANNELS_SUCCESS,
  payload
});

export const editChannelRequested = (
  payload: ICreateChannelPayload,
  id: number
) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(editChannelData(payload, id));
  };
};

export const deleteChannelRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(deleteChannelData(id));
  };
};

export interface IDeleteChannelSuccess {
  type: ChannelActionTypes.DELETE_CHANNEL_SUCCESS;
  id: number;
}

export const deleteChannelSuccess = (id: number): IDeleteChannelSuccess => ({
  type: ChannelActionTypes.DELETE_CHANNEL_SUCCESS,
  id
});

export interface ICreateChannelPlaylistSuccess {
  type: ChannelActionTypes.CREATE_CHANNEL_PLAYLIST_SUCCESS;
  payload: IPlaylist;
}

export const createChannelPlaylistSuccess = (
  payload: IPlaylist
): ICreateChannelPlaylistSuccess => ({
  type: ChannelActionTypes.CREATE_CHANNEL_PLAYLIST_SUCCESS,
  payload
});

export interface IDeleteChannelPlaylistSuccess {
  type: ChannelActionTypes.DELETE_CHANNEL_PLAYLIST_SUCCESS;
  payload: number;
}

export const deleteChannelPlaylistSuccess = (
  payload: number
): IDeleteChannelPlaylistSuccess => ({
  type: ChannelActionTypes.DELETE_CHANNEL_PLAYLIST_SUCCESS,
  payload
});

export const subscribersRequested = (page: number, channelId: number) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(getSubscribersData(page, channelId));
  };
};

export interface IGetSubscribersSuccess {
  type: ChannelActionTypes.GET_SUBSCRIBERS_SUCCESS;
  payload: {
    subscribersData: IGetSubscribersResponse;
    isUpdating: boolean;
  };
}

export const getSubscribersSuccess = (payload: {
  subscribersData: IGetSubscribersResponse;
  isUpdating: boolean;
}): IGetSubscribersSuccess => ({
  type: ChannelActionTypes.GET_SUBSCRIBERS_SUCCESS,
  payload
});

export const preferredChannelsRequested = (page: number) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(getPreferredChannelsData(page));
  };
};

export interface IGetPreferredChannelsSuccess {
  type: ChannelActionTypes.GET_PREFERRED_CHANNELS_SUCCESS;
  payload: {
    channels: IGetPreferredChannelsResponse;
    isUpdating: boolean;
  };
}

export const getPreferredChannelsSuccess = (payload: {
  channels: IGetPreferredChannelsResponse;
  isUpdating: boolean;
}): IGetPreferredChannelsSuccess => ({
  type: ChannelActionTypes.GET_PREFERRED_CHANNELS_SUCCESS,
  payload
});

export const getChannelsListRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(getChannelsListData());
  };
};

export interface IGetChannelsListSuccess {
  type: ChannelActionTypes.GET_CHANNELS_LIST_SUCCESS;
  payload: ICategoriesList[];
}

export const getChannelsListSuccess = (
  payload: ICategoriesList[]
): IGetChannelsListSuccess => ({
  type: ChannelActionTypes.GET_CHANNELS_LIST_SUCCESS,
  payload
});

export interface IGetAssociatedChannelsSuccess {
  type: ChannelActionTypes.GET_ASSOCIATED_CHANNELS_SUCCESS;
  payload: IChannelsResponse;
}

export const getAssociatedChannelsSuccess = (
  payload: IChannelsResponse
): IGetAssociatedChannelsSuccess => ({
  type: ChannelActionTypes.GET_ASSOCIATED_CHANNELS_SUCCESS,
  payload
});

export const getAssociatedChannelsRequested = (channelId: number) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(getAssociatedChannelsData(channelId));
  };
};

export const changeChannelRequested = (videoId: number, channelId: number) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(changeChannelData(videoId, channelId));
  };
};

export interface IChangeChannelSuccess {
  type: ChannelActionTypes.CHANGE_CHANNEL_SUCCESS;
  payload: number;
}

export const changeChannelSuccess = (
  payload: number
): IChangeChannelSuccess => ({
  type: ChannelActionTypes.CHANGE_CHANNEL_SUCCESS,
  payload
});

export interface IDeleteVideoSuccess {
  type: ChannelActionTypes.DELETE_VIDEO_SUCCESS;
  payload: number;
}

export const deleteVideoSuccess = (payload: number): IDeleteVideoSuccess => ({
  type: ChannelActionTypes.DELETE_VIDEO_SUCCESS,
  payload
});

export type ChannelAction =
  | IGetIsSubscribedInChannelSuccess
  | IGetIsSubscribedInChannelsSuccess
  | IGetChannelSuccess
  | IDeleteChannelSuccess
  | ISetChannelsLoader
  | IGetChannelsSuccess
  | ISubscribedChannelsSuccess
  | ISetChannelApiErrors
  | IMyChannelsSuccess
  | ICreateChannelPlaylistSuccess
  | IDeleteChannelPlaylistSuccess
  | IGetSubscribersSuccess
  | IGetPreferredChannelsSuccess
  | IGetChannelsListSuccess
  | IChangeChannelSuccess
  | IDeleteVideoSuccess
  | IGetAssociatedChannelsSuccess;
