import { ThunkDispatch } from 'redux-thunk';
import {
  IAddToSpam,
  ISpamReason,
  ISpamResponse
} from '../../domains/models/spam';
import { IState } from '../reducers';
import { addToSpamData, getSpamData, getSpamReasonsData } from '../thunk/spam';

export enum SpamActionTypes {
  SET_SPAM_LOADER = 'spam/set-spam-loader',
  GET_SPAM_SUCCESS = 'spam/get-spam-success',
  GET_SPAM_REASONS_SUCCESS = 'spam/get-spam-reasons-success'
}

export interface ISetSpamLoader {
  type: SpamActionTypes.SET_SPAM_LOADER;
  payload: boolean;
}

export const setSpamLoader = (isLoading: boolean): ISetSpamLoader => ({
  type: SpamActionTypes.SET_SPAM_LOADER,
  payload: isLoading
});

export const spamRequested = (page: number, isUpdating: boolean) => {
  return async (dispatch: ThunkDispatch<void, IState, SpamAction>) => {
    await dispatch(getSpamData(page, isUpdating));
  };
};

export interface IGetSpamSuccess {
  type: SpamActionTypes.GET_SPAM_SUCCESS;
  payload: ISpamResponse;
}

export const getSpamSuccess = (payload: ISpamResponse): IGetSpamSuccess => ({
  type: SpamActionTypes.GET_SPAM_SUCCESS,
  payload
});

export const getSpamReasonsRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, SpamAction>) => {
    await dispatch(getSpamReasonsData());
  };
};

export interface IGetSpamReasonsSuccess {
  type: SpamActionTypes.GET_SPAM_REASONS_SUCCESS;
  payload: ISpamReason[];
}

export const getSpamReasonsSuccess = (
  payload: ISpamReason[]
): IGetSpamReasonsSuccess => ({
  type: SpamActionTypes.GET_SPAM_REASONS_SUCCESS,
  payload
});

export const addToSpamRequested = (payload: IAddToSpam) => {
  return async (dispatch: ThunkDispatch<void, IState, SpamAction>) => {
    await dispatch(addToSpamData(payload));
  };
};

export type SpamAction =
  | ISetSpamLoader
  | IGetSpamSuccess
  | IGetSpamReasonsSuccess;
