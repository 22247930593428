import dayjs from 'dayjs';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  ISubmitCommentsValues,
  IVideo,
  TypeCaptcha
} from '../../domains/models/videos';
import { getIsSubscribed } from '../../services/channels';
import {
  createComment,
  deleteVideo,
  dislikeComment,
  dislikeVideo,
  editVideo,
  getRecentVideos,
  getRecommendedVideos,
  getSuggestVideos,
  getTagVideos,
  getTrendingVideos,
  getVideo,
  likeComment,
  likeVideo,
  pinComment,
  postUploadVideo,
  resetCaptcha,
  sharedVideo,
  unpinComment,
  uploadVideo,
  uploadVideoPreview
} from '../../services/videos';
import { handleError422, handleErrors } from '../../utils/handleErrors';
import { Status } from '../../views/shared/components/CustomAlert/component';
import { setMessage } from '../actions/message';
import { userPlaylistVideoRequested } from '../actions/playlist';
import { setSnackbar } from '../actions/snackbar';
import {
  createCommentSuccess,
  deleteCaptcha,
  getIsSubscribedInVideoSuccess,
  getRecentVideosSuccess,
  getRecommendedVideosSuccess,
  getSuggestVideosSuccess,
  getTagVideosSuccess,
  getTrendingVideosSuccess,
  getVideoSuccess,
  likeCommentSuccess,
  likeVideosSuccess,
  pinCommentSuccess,
  setMonetise,
  setVideoApiErrors,
  setVideosDropzoneLoader,
  setVideosLoader,
  suggestVideosRequested,
  toggleSharePopup,
  uploadVideoPreviewSuccess,
  uploadVideoSuccess,
  videoRequested
} from '../actions/videos';
import { deleteVideoSuccess } from '../actions/сhannels';
import { IState } from '../reducers';
import { declineUser } from './auth';
import { getBalanceData } from './profile';

export const getRecentVideosData = (page: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setVideosLoader(true));
    try {
      const response = await getRecentVideos(page);
      await dispatch(declineUser(response));
      const { paginate, videos } = response.data.data;
      const transformVideos = videos.map((video: IVideo) => ({
        ...video,
        createdAt: dayjs(video.createdAt).format('D MMM YYYY')
      }));

      dispatch(
        getRecentVideosSuccess({
          recentVideos: {
            videoData: transformVideos,
            total: paginate.total,
            totalPages: paginate.totalPages
          },
          isUpdating: page === 1
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setVideosLoader(false));
    }
  };
};

export const getTrendingVideosData = (
  page: number,
  sort: string,
  isUpdating: boolean
) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setVideosLoader(true));
    try {
      const response = await getTrendingVideos(page, sort);
      await dispatch(declineUser(response));
      const { paginate, videos } = response.data.data;
      const transformVideos = videos.map((video: IVideo) => ({
        ...video,
        createdAt: dayjs(video.createdAt).format('D MMM YYYY')
      }));
      dispatch(
        getTrendingVideosSuccess({
          trendingVideos: {
            videoData: transformVideos,
            total: paginate.total,
            totalPages: paginate.totalPages
          },
          isUpdating
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setVideosLoader(false));
    }
  };
};

export const getRecommendedVideosData = (
  page: number,
  sort: string,
  isUpdating: boolean
) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setVideosLoader(true));
    try {
      const response = await getRecommendedVideos(page, sort);
      await dispatch(declineUser(response));
      const { paginate, videos } = response.data.data;
      const transformVideos = videos.map((video: IVideo) => ({
        ...video,
        createdAt: dayjs(video.createdAt).format('D MMM YYYY')
      }));
      dispatch(
        getRecommendedVideosSuccess({
          recommendedVideos: {
            videoData: transformVideos,
            total: paginate.total,
            totalPages: paginate.totalPages
          },
          isUpdating
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setVideosLoader(false));
    }
  };
};

export const getSuggestVideosData = (id: string) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setVideosLoader(true));
    try {
      const response = await getSuggestVideos(id);
      await dispatch(declineUser(response));
      const { paginate, videos } = response.data.data;
      const transformVideos = videos.map((video: IVideo) => ({
        ...video,
        createdAt: dayjs(video.createdAt).format('D MMM YYYY')
      }));

      dispatch(
        getSuggestVideosSuccess({
          videoData: transformVideos,
          total: paginate.total,
          totalPages: paginate.totalPages
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setVideosLoader(false));
    }
  };
};

export const getVideoData = (id: string, page: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setVideosLoader(true));
    try {
      const response = await getVideo(id, page);
      await dispatch(declineUser(response));
      const { video: videoData } = response.data.data;

      dispatch(
        getVideoSuccess({
          videoData: {
            ...videoData,
            createdAt: dayjs(videoData.createdAt).format('D MMM YYYY')
          },
          page
        })
      );
      dispatch(setMonetise(response.data.data.canMonetise));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setVideosLoader(false));
    }
  };
};

export const getVideoPageData = (
  id: string,
  page: number,
  playlistId?: string
) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setVideosLoader(true));
    try {
      await Promise.allSettled([
        dispatch(videoRequested(id, page)),
        dispatch(suggestVideosRequested(id)),
        playlistId && dispatch(userPlaylistVideoRequested(playlistId))
      ]);
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setVideosLoader(false));
    }
  };
};

export const likeVideoData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await likeVideo(id);
      await dispatch(declineUser(response));
      await dispatch(getBalanceData());
      dispatch(likeVideosSuccess(response.data.data));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const dislikeVideoData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await dislikeVideo(id);
      await dispatch(declineUser(response));
      await dispatch(getBalanceData());
      dispatch(likeVideosSuccess(response.data.data));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const getIsSubscribedInVideoData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await getIsSubscribed(id);
      await dispatch(declineUser(response));
      await dispatch(getBalanceData());
      dispatch(
        getIsSubscribedInVideoSuccess(
          response.data.data.channel.subscribeStatus
        )
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const uploadVideoData = (id?: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setVideosLoader(true));
    try {
      const response = id ? await editVideo(id) : await uploadVideo();
      await dispatch(declineUser(response));
      dispatch(uploadVideoSuccess(response.data.data));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setVideosLoader(false));
    }
  };
};

export const uploadVideoPreviewData = (
  payload: FormData,
  uploadProgress: () => void
) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setVideosDropzoneLoader(true));
    try {
      const response = await uploadVideoPreview(payload, uploadProgress);
      await dispatch(declineUser(response));
      dispatch(uploadVideoPreviewSuccess(response.data.data));
      dispatch(
        setSnackbar({
          snackbar: 'yourVideoSuccessfullyUploaded',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setVideosDropzoneLoader(false));
    }
  };
};

export const postUploadVideoData = (payload: FormData) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setVideosLoader(true));
    try {
      const response = await postUploadVideo(payload);
      await dispatch(declineUser(response));
      dispatch(toggleSharePopup(true));
    } catch (error: any) {
      if (error.response.status === 422) {
        const error422Data = handleError422(error.response.data.errors);
        dispatch(setVideoApiErrors(error422Data));
        if (error422Data && Object.keys(error422Data)?.length) {
          const firstFieldError = Object.keys(error422Data)[0];
          dispatch(
            setMessage({
              message: error422Data[firstFieldError],
              status: Status.ERROR
            })
          );
        }
      } else {
        const errorData = handleErrors(error);
        dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      }
    } finally {
      dispatch(setVideosLoader(false));
    }
  };
};

export const createCommentData = (payload: ISubmitCommentsValues) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await createComment(payload);
      await dispatch(declineUser(response));
      await dispatch(getBalanceData());
      dispatch(createCommentSuccess(response.data.data.comment));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const likeCommentData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await likeComment(id);
      await dispatch(declineUser(response));
      await dispatch(getBalanceData());
      dispatch(likeCommentSuccess(response.data.data, id));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const dislikeCommentData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await dislikeComment(id);
      await dispatch(declineUser(response));
      await dispatch(getBalanceData());
      dispatch(likeCommentSuccess(response.data.data, id));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const pinCommentData = (videoId: number, id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await pinComment(videoId, id);
      await dispatch(declineUser(response));
      dispatch(pinCommentSuccess(id));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const unpinCommentData = (videoId: number, id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await unpinComment(videoId, id);
      await dispatch(declineUser(response));
      dispatch(pinCommentSuccess(id, true));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const deleteVideoData = (videoId: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await deleteVideo(videoId);
      await dispatch(declineUser(response));
      dispatch(
        setMessage({
          message: 'videoDeletedSuccessfully',
          status: Status.SUCCESS
        })
      );
      dispatch(deleteVideoSuccess(videoId));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const getTagVideosData = (id: string, page: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setVideosLoader(true));
    try {
      const response = await getTagVideos(id, page);
      await dispatch(declineUser(response));
      const { paginate, videos } = response.data.data;
      const transformVideos = videos.map((video: IVideo) => ({
        ...video,
        createdAt: dayjs(video.createdAt).format('D MMM YYYY')
      }));
      dispatch(
        getTagVideosSuccess({
          tagVideos: {
            videoData: transformVideos,
            total: paginate.total,
            totalPages: paginate.totalPages
          },
          isUpdating: page === 1
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setVideosLoader(false));
    }
  };
};

export const resetCaptchaData = (type: TypeCaptcha) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await resetCaptcha(type);
      await dispatch(declineUser(response));
      dispatch(
        setSnackbar({
          snackbar: 'youPassedHumanVerifying',
          status: Status.SUCCESS
        })
      );
      dispatch(deleteCaptcha(true));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setSnackbar({ snackbar: errorData, status: Status.ERROR }));
    }
  };
};

export const sharedVideoData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await sharedVideo(id);
      await dispatch(declineUser(response));
      await dispatch(getBalanceData());
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setSnackbar({ snackbar: errorData, status: Status.ERROR }));
    }
  };
};
