import { instanceAxios } from './instanceAxios';

export const getHistory = (page: number) => {
  return instanceAxios.get(`/history?page=${page}`);
};

export const deleteVideoHistory = (id: number) => {
  return instanceAxios.delete(`/history/${id}`);
};

export const deleteHistory = () => {
  return instanceAxios.delete(`/history`);
};
