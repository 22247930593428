import { AxiosPromise } from 'axios';
import { instanceAxios } from './instanceAxios';

export const getWishlist = (page: number): Promise<AxiosPromise> => {
  return instanceAxios.get(`/wishlist?page=${page}`);
};

export const toggleVideoWishlist = (id: number): Promise<AxiosPromise> => {
  return instanceAxios.post(`/wishlist/toggle-video/${id}`);
};

export const deleteWishlist = (): Promise<AxiosPromise> => {
  return instanceAxios.delete(`/wishlist`);
};
