import { AlertColor } from '@mui/material';
import { SnackbarAction, SnackbarActionTypes } from '../actions/snackbar';

export interface ISnackbarState {
  snackbar: string;
  status: AlertColor | null;
}

export const initialSnackbarState: ISnackbarState = {
  snackbar: '',
  status: null
};

export default function snackbarReducer(
  state: ISnackbarState = initialSnackbarState,
  action: SnackbarAction
) {
  switch (action.type) {
    case SnackbarActionTypes.SET_SNACKBAR:
      return {
        ...state,
        snackbar: action.payload.snackbar,
        status: action.payload.status
      };
    case SnackbarActionTypes.CLEAR_SNACKBAR:
      return { ...state, snackbar: '', status: null };
    default:
      return state;
  }
}
