import { ISettings, IWithdrawalPayload } from '../../domains/models/withdrawal';
import { WithdrawalAction, WithdrawalActionTypes } from '../actions/withdrawal';

export type IWithdrawalState = {
  isLoading: boolean;
  withdrawalErrors: Partial<IWithdrawalPayload> | null;
  settings: ISettings | null;
  withdrawalEmail: string;
};
export const initialWithdrawalState: IWithdrawalState = {
  isLoading: false,
  withdrawalErrors: null,
  settings: null,
  withdrawalEmail: ''
};

export default function withdrawalReducer(
  state: IWithdrawalState = initialWithdrawalState,
  action: WithdrawalAction
) {
  switch (action.type) {
    case WithdrawalActionTypes.SET_WITHDRAWAL_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case WithdrawalActionTypes.SET_WITHDRAWAL_API_ERRORS:
      return {
        ...state,
        withdrawalErrors: action.payload
      };
    case WithdrawalActionTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload.settings
      };
    case WithdrawalActionTypes.SEND_WITHDRAWAL_EMAIL:
      return {
        ...state,
        withdrawalEmail: action.payload
      };
    default:
      return state;
  }
}
