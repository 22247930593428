import { ThunkDispatch } from 'redux-thunk';
import {
  ICreatePlaylist,
  IPlaylist,
  IPlaylistVideoResponse,
  PlaylistType
} from '../../domains/models/playlist';
import { IEditName } from '../../views/shared/components/EditableName/hook';
import { IState } from '../reducers';
import {
  addToPlaylistData,
  createPlaylistData,
  deleteAllPlaylistsData,
  deletePlaylistData,
  deletePlaylistVideoData,
  editNameData,
  getPlaylistData,
  getUserPlaylistVideoData
} from '../thunk/playlist';

export enum PlaylistActionTypes {
  SET_PLAYLIST_LOADER = 'playlist/set-playlist-loader',
  GET_PLAYLIST_SUCCESS = 'playlist/get-playlist-success',
  GET_PLAYLIST_VIDEO_SUCCESS = 'playlist/get-playlist-video-success',
  DELETE_USER_PLAYLIST_VIDEO_SUCCESS = 'playlist/delete-uer-playlist-video-success',
  DELETE_USER_PLAYLIST_SUCCESS = 'playlist/delete-user-playlist-success',
  TOGGLE_VIDEO_PLAYLIST = 'playlist/toggle-video-playlist',
  EDIT_NAME_SUCCESS = 'playlist/edit-name-success',
  DELETE_All_PLAYLISTS_SUCCESS = 'playlist/delete-all-playlists-success',
  SET_AUTOPLAY = 'playlist/set-autoplay',
  SET_THEATREMODE = 'playlist/set-theatre-mode'
}

export interface ISetPlaylistLoader {
  type: PlaylistActionTypes.SET_PLAYLIST_LOADER;
  payload: boolean;
}

export const setPlaylistLoader = (isLoading: boolean): ISetPlaylistLoader => ({
  type: PlaylistActionTypes.SET_PLAYLIST_LOADER,
  payload: isLoading
});

export const playlistRequested = (id?: number) => {
  return async (dispatch: ThunkDispatch<void, IState, PlaylistAction>) => {
    await dispatch(getPlaylistData(id));
  };
};

export const addToPlaylistRequested = (playlistId: string, videoId: number) => {
  return async (dispatch: ThunkDispatch<void, IState, PlaylistAction>) => {
    await dispatch(addToPlaylistData(playlistId, videoId));
  };
};

export interface IGetPlaylistSuccess {
  type: PlaylistActionTypes.GET_PLAYLIST_SUCCESS;
  payload: IPlaylist[];
}

export const getPlaylistSuccess = (
  payload: IPlaylist[]
): IGetPlaylistSuccess => ({
  type: PlaylistActionTypes.GET_PLAYLIST_SUCCESS,
  payload
});

export const userPlaylistVideoRequested = (id: string) => {
  return async (dispatch: ThunkDispatch<void, IState, PlaylistAction>) => {
    await dispatch(getUserPlaylistVideoData(id));
  };
};

export interface IGetPlaylistVideoSuccess {
  type: PlaylistActionTypes.GET_PLAYLIST_VIDEO_SUCCESS;
  payload: IPlaylistVideoResponse;
}

export const getUserPlaylistVideoSuccess = (
  payload: IPlaylistVideoResponse
): IGetPlaylistVideoSuccess => ({
  type: PlaylistActionTypes.GET_PLAYLIST_VIDEO_SUCCESS,
  payload
});

export const deletePlaylistVideoRequested = (
  videoId: number,
  playlistId: number
) => {
  return async (dispatch: ThunkDispatch<void, IState, PlaylistAction>) => {
    await dispatch(deletePlaylistVideoData(videoId, playlistId));
  };
};

export interface IDeleteUserPlaylistVideoSuccess {
  type: PlaylistActionTypes.DELETE_USER_PLAYLIST_VIDEO_SUCCESS;
  payload: number;
}

export const deleteUserPlaylistVideoSuccess = (
  payload: number
): IDeleteUserPlaylistVideoSuccess => ({
  type: PlaylistActionTypes.DELETE_USER_PLAYLIST_VIDEO_SUCCESS,
  payload
});

export const deletePlaylistRequested = (
  playlistId: number,
  type?: PlaylistType
) => {
  return async (dispatch: ThunkDispatch<void, IState, PlaylistAction>) => {
    await dispatch(deletePlaylistData(playlistId, type));
  };
};

export interface IDeleteUserPlaylistSuccess {
  type: PlaylistActionTypes.DELETE_USER_PLAYLIST_SUCCESS;
  payload: number;
}

export const deleteUserPlaylistSuccess = (
  payload: number
): IDeleteUserPlaylistSuccess => ({
  type: PlaylistActionTypes.DELETE_USER_PLAYLIST_SUCCESS,
  payload
});

export const createPlaylistRequested = (
  playlistData: ICreatePlaylist,
  type?: PlaylistType
) => {
  return async (dispatch: ThunkDispatch<void, IState, PlaylistAction>) => {
    await dispatch(createPlaylistData(playlistData, type));
  };
};

export interface IToggleVideoPlaylist {
  type: PlaylistActionTypes.TOGGLE_VIDEO_PLAYLIST;
}

export const toggleVideoPlaylist = (): IToggleVideoPlaylist => ({
  type: PlaylistActionTypes.TOGGLE_VIDEO_PLAYLIST
});

export const editNameRequested = (payload: IEditName, id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, PlaylistAction>) => {
    await dispatch(editNameData(payload, id));
  };
};

export interface IEditNameSuccess {
  type: PlaylistActionTypes.EDIT_NAME_SUCCESS;
  payload: IPlaylist;
}

export const editNameSuccess = (payload: IPlaylist): IEditNameSuccess => ({
  type: PlaylistActionTypes.EDIT_NAME_SUCCESS,
  payload
});

export const deleteAllPlaylistsRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, PlaylistAction>) => {
    await dispatch(deleteAllPlaylistsData());
  };
};

export interface IDeleteAllPlaylistsSuccess {
  type: PlaylistActionTypes.DELETE_All_PLAYLISTS_SUCCESS;
}

export const deleteAllPlaylistsSuccess = (): IDeleteAllPlaylistsSuccess => ({
  type: PlaylistActionTypes.DELETE_All_PLAYLISTS_SUCCESS
});

export interface ISetAutoplay {
  type: PlaylistActionTypes.SET_AUTOPLAY;
  payload: boolean;
}

export const setAutoplay = (payload: boolean): ISetAutoplay => ({
  type: PlaylistActionTypes.SET_AUTOPLAY,
  payload
});

export interface ISetTheatreMode {
  type: PlaylistActionTypes.SET_THEATREMODE;
  payload: boolean;
}

export const setTheatreMode = (payload: boolean): ISetTheatreMode => ({
  type: PlaylistActionTypes.SET_THEATREMODE,
  payload
});

export type PlaylistAction =
  | ISetPlaylistLoader
  | IGetPlaylistSuccess
  | IGetPlaylistVideoSuccess
  | IDeleteUserPlaylistVideoSuccess
  | IDeleteUserPlaylistSuccess
  | IToggleVideoPlaylist
  | IEditNameSuccess
  | IDeleteAllPlaylistsSuccess
  | ISetAutoplay
  | ISetTheatreMode;
