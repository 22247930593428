import {
  ICategoriesList,
  ICategory,
  IPreferredCategoriesResponse
} from '../../domains/models/categories';
import { IVideosResponse } from '../../domains/models/videos';
import { CategoriesAction, CategoriesActionTypes } from '../actions/categories';

export interface ICategoriesState {
  isLoading: boolean;
  categories: ICategory[] | null;
  categoryVideos: IVideosResponse | null;
  categoriesList: ICategoriesList[] | null;
  preferred: IPreferredCategoriesResponse | null;
}

export const initialCategoriesState: ICategoriesState = {
  isLoading: false,
  categories: null,
  categoryVideos: null,
  categoriesList: null,
  preferred: null
};

export default function CategoriesReducer(
  state: ICategoriesState = initialCategoriesState,
  action: CategoriesAction
) {
  switch (action.type) {
    case CategoriesActionTypes.SET_CATEGORIES_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case CategoriesActionTypes.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.payload
      };
    }
    case CategoriesActionTypes.GET_CATEGORY_VIDEOS_SUCCESS: {
      const oldVideos = state.categoryVideos
        ? state.categoryVideos.videoData
        : [];
      const { videos, isUpdating } = action.payload;
      return {
        ...state,
        categoryVideos: {
          videoData: isUpdating
            ? videos.videoData
            : [...oldVideos, ...videos.videoData],
          total: videos.total,
          totalPages: videos.totalPages
        }
      };
    }
    case CategoriesActionTypes.GET_CATEGORIES_LIST_SUCCESS: {
      return {
        ...state,
        categoriesList: action.payload
      };
    }
    case CategoriesActionTypes.GET_PREFERRED_CATEGORIES_SUCCESS: {
      return {
        ...state,
        preferred: action.payload
      };
    }
    default:
      return state;
  }
}
