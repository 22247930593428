import { AxiosDataResponse } from '../domains/models/response';
import {
  IAddToSpam,
  ISpamReason,
  ISpamResponseData
} from '../domains/models/spam';
import { instanceAxios } from './instanceAxios';

export const getSpam = (
  page: number
): Promise<AxiosDataResponse<ISpamResponseData>> => {
  return instanceAxios.get(`/spam?page=${page}`);
};

export const getSpamReasons = (): Promise<AxiosDataResponse<ISpamReason[]>> => {
  return instanceAxios.get(`/spam/reasons`);
};

export const addToSpam = (payload: IAddToSpam): Promise<AxiosDataResponse> => {
  return instanceAxios.post(`/spam`, payload);
};
