import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { getSearchResults } from '../../services/search';
import { handleVideosDate } from '../../utils/handleDate';
import { handleErrors } from '../../utils/handleErrors';
import { Status } from '../../views/shared/components/CustomAlert/component';
import { setMessage } from '../actions/message';
import { getSearchDataSuccess, setSearchLoader } from '../actions/search';
import { IState } from '../reducers';
import { declineUser } from './auth';

export const getSearchData = (payload: { query: string; page: number }) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setSearchLoader(true));
    try {
      const response = await getSearchResults(payload.query, payload.page);
      await dispatch(declineUser(response));
      const { paginate, videos, channels } = response.data.data;
      const transformResponseData = {
        paginate,
        videos: handleVideosDate(videos),
        channels
      };
      const isUpdating = payload.page === 1;
      dispatch(getSearchDataSuccess(transformResponseData, isUpdating));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setSearchLoader(false));
    }
  };
};
