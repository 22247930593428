import { ThunkDispatch } from 'redux-thunk';
import {
  IForgotPasswordPayload,
  ILoginPayload,
  IRegisterPayload
} from '../../domains/models';
import { IState } from '../reducers';
import {
  forgotPassword,
  login,
  register,
  registerVerification,
  resendVerificationEmailData
} from '../thunk/auth';

export enum AuthActionTypes {
  LOGIN_REQUESTED = 'auth/login-requested',
  LOGIN_SUCCESS = 'auth/login-success',
  LOGIN_FAILED = 'auth/login-failed',
  LOGOUT_SUCCESS = 'auth/logout-success',
  SET_AUTH_LOADER = 'loader/set-auth-loader',
  REGISTER_REQUESTED = 'auth/register-requested',
  REGISTER_VERIFICATION_REQUESTED = 'auth/register-verification-requested',
  SET_REGISTER_API_ERRORS = 'auth/set-errors',
  FORGOT_PASSWORD_REQUESTED = 'auth/forgot-password-requested',
  GET_REPEATED_VERIFICATION_EMAIL = 'auth/get-repeated-verification-email'
}

export interface ISetAuthLoaderAction {
  type: AuthActionTypes.SET_AUTH_LOADER;
  payload: boolean;
}

export const setAuthLoader = (isLoading: boolean): ISetAuthLoaderAction => ({
  type: AuthActionTypes.SET_AUTH_LOADER,
  payload: isLoading
});

export interface ILoginRequestedAction {
  type: AuthActionTypes.LOGIN_REQUESTED;
  payload: ILoginPayload;
}

export const loginRequested = (payload: ILoginPayload) => {
  return async (dispatch: ThunkDispatch<void, IState, AuthAction>) => {
    await dispatch(login(payload));
  };
};

export interface ILoginSuccessAction {
  type: AuthActionTypes.LOGIN_SUCCESS;
}

export const loginSuccess = () => ({
  type: AuthActionTypes.LOGIN_SUCCESS
});

export interface ILoginFailedAction {
  type: AuthActionTypes.LOGIN_FAILED;
}

export const loginFailed = (): ILoginFailedAction => ({
  type: AuthActionTypes.LOGIN_FAILED
});

export interface ILogoutSuccessAction {
  type: AuthActionTypes.LOGOUT_SUCCESS;
}

export const logoutSuccess = () => ({
  type: AuthActionTypes.LOGOUT_SUCCESS
});

export interface IForgotPasswordRequestedAction {
  type: AuthActionTypes.FORGOT_PASSWORD_REQUESTED;
  payload: IForgotPasswordPayload;
}

export const forgotPasswordRequested = (payload: IForgotPasswordPayload) => {
  return async (dispatch: ThunkDispatch<void, IState, AuthAction>) => {
    await dispatch(forgotPassword(payload));
  };
};

export interface IRegisterRequestedAction {
  type: AuthActionTypes.REGISTER_REQUESTED;
  payload: IRegisterPayload;
}

export const registerRequested = (payload: IRegisterPayload) => {
  return async (dispatch: ThunkDispatch<void, IState, AuthAction>) => {
    await dispatch(register(payload));
  };
};

export interface IRegisterVerificationRequested {
  type: AuthActionTypes.REGISTER_VERIFICATION_REQUESTED;
  payload: string;
}

export interface ISetRegisterApiErrors {
  type: AuthActionTypes.SET_REGISTER_API_ERRORS;
  payload: IRegisterPayload | null;
}

export const setRegisterApiErrors = (
  errors: Partial<IRegisterPayload> | null
) => ({
  type: AuthActionTypes.SET_REGISTER_API_ERRORS,
  payload: errors
});

export interface IRegisterVerificationRequested {
  type: AuthActionTypes.REGISTER_VERIFICATION_REQUESTED;
  payload: string;
}

export const registerVerificationRequested = (id: string) => {
  return async (dispatch: ThunkDispatch<void, IState, AuthAction>) => {
    await dispatch(registerVerification(id));
  };
};

export interface IGetRepeatedVerificationEmail {
  type: AuthActionTypes.GET_REPEATED_VERIFICATION_EMAIL;
  payload: string;
}

export const getRepeatedVerificationEmail = (
  email: string
): IGetRepeatedVerificationEmail => ({
  type: AuthActionTypes.GET_REPEATED_VERIFICATION_EMAIL,
  payload: email
});

export const resendVerificationEmailRequested = (email: string) => {
  return async (dispatch: ThunkDispatch<void, IState, AuthAction>) => {
    await dispatch(resendVerificationEmailData(email));
  };
};

export type AuthAction =
  | ILoginRequestedAction
  | ILoginSuccessAction
  | ILoginFailedAction
  | ILogoutSuccessAction
  | IRegisterRequestedAction
  | IRegisterVerificationRequested
  | ISetAuthLoaderAction
  | IForgotPasswordRequestedAction
  | ISetRegisterApiErrors
  | IGetRepeatedVerificationEmail;
