import { Button, Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { useStyles } from './styles';

export enum Variant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

type ICustomButton = {
  children: React.ReactNode;
  disabled?: boolean;
  buttonVariant?: Variant;
  type?: 'submit' | 'reset' | 'button';
  sx?: SxProps;
  clickHandler?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  width?: number;
  height?: number;
  fontSize?: number;
};

export const CustomButton = ({
  children,
  disabled,
  buttonVariant,
  type = 'button',
  clickHandler,
  width,
  height,
  fontSize,
  ...props
}: ICustomButton) => {
  const { btn, btnPrimary, btnSecondary } = useStyles({
    width,
    height,
    fontSize
  });

  return (
    <Grid {...props}>
      <Button
        className={`${btn} ${buttonVariant === Variant.PRIMARY ? btnPrimary : btnSecondary
          }`}
        variant={'contained'}
        disabled={disabled}
        type={type}
        onClick={(e) => clickHandler && clickHandler(e)}
      >
        {children}
      </Button>
    </Grid>
  );
};
