import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {getAds} from '../../services/ads';
import {getAdsFailed, getAdsSuccess, setAdsLoader} from '../actions/ads';
import {IState} from '../reducers';

export const getAdsData = () => {
  return async (
      dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setAdsLoader(true));
    try {
      const response = await getAds();
      dispatch(getAdsSuccess(response.data.data.ads));
    } catch (error: any) {
      dispatch(getAdsFailed);
    } finally {
      dispatch(setAdsLoader(false));
    }
  };
};

