import CloseIcon from '@mui/icons-material/Close';
import {
  Drawer as DrawerMobile,
  Grid,
  Link as MaterialLink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { logout } from '../../../application/thunk/auth';
import { ReactComponent as BloggerIOS } from '../../../assets/images/bloggerIOS.svg';
import { ReactComponent as FAQ } from '../../../assets/images/faq.svg';
import BaseTubeLogo from '../../../assets/images/BaseTube.png';
import { CustomDialog } from '../../shared/components/CustomDialog';
import { SocialLinksSidebar } from '../../shared/components/SocialLinksSidebar';
import {
  menuBottomAuthorization,
  menuBottomNotAuthorization,
  menuMiddle,
  menuTop
} from './sidebarData';
import { DrawerDesktop, useStyles } from './styles';

interface AppBarProps extends MuiAppBarProps {
  open: boolean;
  isMobileOpen: boolean;
  isAuthenticated: boolean;
  setIsMobileOpen: (isMobileOpen: boolean) => void;
}

export const Sidebar = ({
  open,
  isMobileOpen,
  setIsMobileOpen,
  isAuthenticated
}: AppBarProps) => {
  const {
    list,
    listItem,
    icon,
    link,
    linkSmall,
    bottomMenu,
    mobileDrawer,
    sidebarLogoWrapper
  } = useStyles({ open });
  const [popupOpened, setPopupOpened] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const menuBottom = isAuthenticated
    ? menuBottomAuthorization
    : menuBottomNotAuthorization;

  const handleCloseSidebar = () => {
    if (matchesMd) {
      setIsMobileOpen(false);
    }
  };

  const getListDrawer = (isOpen: boolean) => (
    <List className={list}>
      <Grid
        container
        direction={'column'}
        justifyContent={'space-between'}
        height={matchesMd ? '100%' : 'calc(100% - 112px)'}
        flexWrap={'nowrap'}
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <Grid item flexGrow={1}>
          {matchesMd && (
            <Grid className={sidebarLogoWrapper}>
              <img src={BaseTubeLogo} style={{ width: 138 }} alt="" />
              <IconButton onClick={handleCloseSidebar}>
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
          {menuTop.map(({ title, src: ImgSrc, url }, index) => (
            <ListItem className={listItem} key={index}>
              <MaterialLink
                component={RouterLink}
                className={isOpen ? link : `${link} ${linkSmall}`}
                to={url}
                onClick={handleCloseSidebar}
              >
                <ListItemIcon className={icon}>
                  <ImgSrc />
                </ListItemIcon>
                <ListItemText>{t(title)}</ListItemText>
              </MaterialLink>
            </ListItem>
          ))}
        </Grid>
        <Grid item flexGrow={1}>
          {menuMiddle.map(({ title, src: ImgSrc, url }, index) => (
            <ListItem className={listItem} key={index}>
              <MaterialLink
                component={RouterLink}
                className={isOpen ? link : `${link} ${linkSmall}`}
                to={url}
                onClick={handleCloseSidebar}
              >
                <ListItemIcon className={icon}>
                  <ImgSrc />
                </ListItemIcon>
                <ListItemText>{t(title)}</ListItemText>
              </MaterialLink>
            </ListItem>
          ))}
        </Grid>
        <Grid className={bottomMenu} item flexGrow={0.2}>
          <ListItem className={listItem}>
            <MaterialLink
              className={isOpen ? link : `${link} ${linkSmall}`}
              /* FIXME wrong url */
              href={'https://base-tube.gitbook.io/base.tube-documentation'}
              target={'_blank'}
              onClick={handleCloseSidebar}
            >
              <ListItemIcon className={icon}>
                <FAQ />
              </ListItemIcon>
              <ListItemText>{t('faq')}</ListItemText>
            </MaterialLink>
          </ListItem>
          <ListItem className={listItem}>
            <MaterialLink
              className={isOpen ? link : `${link} ${linkSmall}`}
              href={'https://base.tube/blog/'}
              target={'_blank'}
              onClick={handleCloseSidebar}
            >
              <ListItemIcon className={icon}>
                <BloggerIOS />
              </ListItemIcon>
              <ListItemText>{t('blog')}</ListItemText>
            </MaterialLink>
          </ListItem>
          {menuBottom.map(({ title, src: ImgSrc, url }, index) => (
            <ListItem className={listItem} key={index}>
              {title === 'signOut' ? (
                <Typography
                  className={isOpen ? link : `${link} ${linkSmall}`}
                  onClick={() => setPopupOpened(true)}
                >
                  <ListItemIcon className={icon}>
                    <ImgSrc />
                  </ListItemIcon>
                  <ListItemText>{t(title)}</ListItemText>
                </Typography>
              ) : (
                <MaterialLink
                  component={RouterLink}
                  className={isOpen ? link : `${link} ${linkSmall}`}
                  to={url}
                  onClick={handleCloseSidebar}
                >
                  <ListItemIcon className={icon}>
                    <ImgSrc />
                  </ListItemIcon>
                  <ListItemText>{t(title)}</ListItemText>
                </MaterialLink>
              )}
            </ListItem>
          ))}
        </Grid>
        <Grid item>
          <SocialLinksSidebar open={open} />
        </Grid>
      </Grid>
    </List>
  );

  return (
    <>
      {matchesMd ? (
        <DrawerMobile
          className={mobileDrawer}
          variant="temporary"
          open={isMobileOpen}
          onClose={() => {
            setIsMobileOpen(false);
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          {getListDrawer(isMobileOpen)}
        </DrawerMobile>
      ) : (
        <DrawerDesktop
          sx={{ minHeight: '100%' }}
          variant="permanent"
          open={open}
        >
          {getListDrawer(open)}
        </DrawerDesktop>
      )}
      <Grid>
        <CustomDialog
          popupOpened={popupOpened}
          setPopupOpened={setPopupOpened}
          title={t('areYouSureToLogout')}
          primaryBtn={t('logout')}
          secondaryBtn={t('cancel')}
          actionHandler={() => {
            dispatch(logout());
          }}
        />
      </Grid>
    </>
  );
};
