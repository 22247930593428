import { ThunkDispatch } from 'redux-thunk';
import {
  IChangePasswordPayload,
  IDeleteAccountPayload,
  IProfilePayload,
  IProfileResponse,
  IUserAnalytic
} from '../../domains/models';
import { IAvailableChannelsForMonetization } from '../../domains/models/channels';
import {
  IChannelMonetization,
  IFullBalance, ISendReferralEmail,
  IUserAnalyticDetail
} from '../../domains/models/profile';
import { IState } from '../reducers';
import {
  changePassword,
  channelsMonetizationData,
  channelsWithMonetizationData,
  claimRewardData,
  deleteAccountData,
  editProfile,
  getBalanceData,
  getFullProfileData,
  getProfileData,
  getUserAnalyticsData,
  getUserAnalyticsDetailData, sendReferralEmailData
} from '../thunk/profile';
import { ChannelAction } from './сhannels';

export enum ProfileActionTypes {
  GET_PROFILE_SUCCESS = 'profile/get-user-profile-success',
  GET_PROFILE_FAILED = 'profile/get-user-profile-failed',
  GET_BALANCE_SUCCESS = 'profile/get-balance-success',
  EDIT_PROFILE_SUCCESS = 'profile/edit-profile-success',
  SET_PROFILE_LOADER = 'profile/set-profile-loader',
  SET_PROFILE_API_ERRORS = 'profile/set-profile-api-errors',
  SET_PROFILE_API_PASSWORD_ERRORS = 'profile/set-profile-api-password-errors',
  GET_ACTIVE_WITHDRAWAL = 'withdrawal/get-active-withdrawal',
  SET_IS_SIDEBAR_OPENED = 'profile/set-is-sidebar-opened',
  GET_AVAILABLE_CHANNELS_FOR_MONETIZATION = 'profile/get-available-channels-for-monetization',
  SET_PENDING_REQUEST = 'profile/set-pending-request',
  GET_USER_ANALYTIC = 'profile/get-user-analytic',
  GET_USER_ANALYTIC_SUCCESS = 'profile/get-user-analytic-success',
  CLEAR_USER_ANALYTIC = 'profile/clear-user-analytic',
  GET_USER_ANALYTIC_DETAIL = 'profile/get-user-analytic-detail',
  GET_USER_ANALYTIC_DETAIL_SUCCESS = 'profile/get-user-analytic-detail-success',
  CLEAR_USER_ANALYTIC_DETAIL = 'profile/clear-user-analytic-detail',
  SET_CLOSE_MODAL_FREE = 'profile/close-modal-free'
}

export interface ISetProfileLoaderAction {
  type: ProfileActionTypes.SET_PROFILE_LOADER;
  payload: boolean;
}

export const setProfileLoader = (
  isLoading: boolean
): ISetProfileLoaderAction => ({
  type: ProfileActionTypes.SET_PROFILE_LOADER,
  payload: isLoading
});

export const getProfileRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, ProfileAction>) => {
    await dispatch(getProfileData());
  };
};

export const getBalanceRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, ProfileAction>) => {
    await dispatch(getBalanceData());
  };
};

export const getFullProfileRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, ProfileAction>) => {
    await dispatch(getFullProfileData());
  };
};

export interface IGetProfileSuccessAction {
  type: ProfileActionTypes.GET_PROFILE_SUCCESS;
  payload: IProfileResponse;
}

export const getProfileSuccess = (payload: IProfileResponse) => ({
  type: ProfileActionTypes.GET_PROFILE_SUCCESS,
  payload
});

export interface IGetBalanceSuccessAction {
  type: ProfileActionTypes.GET_BALANCE_SUCCESS;
  payload: IFullBalance;
}

export const getBalanceSuccess = (
  payload: IFullBalance
): IGetBalanceSuccessAction => ({
  type: ProfileActionTypes.GET_BALANCE_SUCCESS,
  payload
});

export interface IGetProfileFailedAction {
  type: ProfileActionTypes.GET_PROFILE_FAILED;
}

export const getProfileFailed = () => ({
  type: ProfileActionTypes.GET_PROFILE_FAILED
});

export const editProfileRequested = (payload: IProfilePayload) => {
  return async (dispatch: ThunkDispatch<void, IState, ProfileAction>) => {
    await dispatch(editProfile(payload));
  };
};

export interface IEditProfileSuccessAction {
  type: ProfileActionTypes.EDIT_PROFILE_SUCCESS;
  payload: IProfileResponse;
}

export const editProfileSuccess = (payload: IProfileResponse) => ({
  type: ProfileActionTypes.EDIT_PROFILE_SUCCESS,
  payload
});

export const changePasswordRequested = (payload: IChangePasswordPayload) => {
  return async (dispatch: ThunkDispatch<void, IState, ProfileAction>) => {
    await dispatch(changePassword(payload));
  };
};

export interface ISetProfileApiErrors {
  type: ProfileActionTypes.SET_PROFILE_API_ERRORS;
  payload: Partial<IProfileResponse> | null;
}

export const setProfileApiErrors = (
  errors: Partial<IProfileResponse> | null
) => ({
  type: ProfileActionTypes.SET_PROFILE_API_ERRORS,
  payload: errors
});

export interface ISetProfileApiPasswordErrors {
  type: ProfileActionTypes.SET_PROFILE_API_PASSWORD_ERRORS;
  payload: Partial<IChangePasswordPayload> | null;
}

export const setProfileApiPasswordErrors = (
  errors: Partial<IChangePasswordPayload> | null
) => ({
  type: ProfileActionTypes.SET_PROFILE_API_PASSWORD_ERRORS,
  payload: errors
});

export const deleteAccountRequested = (payload: IDeleteAccountPayload) => {
  return async (dispatch: ThunkDispatch<void, IState, ProfileAction>) => {
    await dispatch(deleteAccountData(payload));
  };
};

export interface IGetActiveWithdrawal {
  type: ProfileActionTypes.GET_ACTIVE_WITHDRAWAL;
  payload: boolean;
}

export const getActiveWithdrawal = (payload: boolean) => ({
  type: ProfileActionTypes.GET_ACTIVE_WITHDRAWAL,
  payload
});

export interface ISetIsSidebarOpened {
  type: ProfileActionTypes.SET_IS_SIDEBAR_OPENED;
  payload: boolean;
}

export const setIsSidebarOpened = (payload: boolean) => ({
  type: ProfileActionTypes.SET_IS_SIDEBAR_OPENED,
  payload
});

export const requestChannelsMonetization = (payload: IChannelMonetization) => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(channelsMonetizationData(payload));
  };
};

export const requestChannelsWithMonetization = () => {
  return async (dispatch: ThunkDispatch<void, IState, ChannelAction>) => {
    await dispatch(channelsWithMonetizationData());
  };
};

export interface IAvailableChannels {
  type: ProfileActionTypes.GET_AVAILABLE_CHANNELS_FOR_MONETIZATION;
  payload: IAvailableChannelsForMonetization;
}

export const availableChannelsForMonetization = (
  payload: IAvailableChannelsForMonetization
): IAvailableChannels => ({
  type: ProfileActionTypes.GET_AVAILABLE_CHANNELS_FOR_MONETIZATION,
  payload
});

export interface ISetPendingRequests {
  type: ProfileActionTypes.SET_PENDING_REQUEST;
  payload: boolean;
}

export const setPendingRequests = (payload: boolean): ISetPendingRequests => ({
  type: ProfileActionTypes.SET_PENDING_REQUEST,
  payload
});

export interface IGetUserAnalytics {
  type: ProfileActionTypes.GET_USER_ANALYTIC;
}

export interface IGetUserAnalyticsDetail {
  type: ProfileActionTypes.GET_USER_ANALYTIC_DETAIL;
}

export interface IGetUserAnalyticsSuccess {
  type: ProfileActionTypes.GET_USER_ANALYTIC_SUCCESS;
  payload: IUserAnalytic;
}

export interface IGetUserAnalyticsDetailSuccess {
  type: ProfileActionTypes.GET_USER_ANALYTIC_DETAIL_SUCCESS;
  payload: IUserAnalyticDetail;
}

export const getUserAnalytics = () => {
  return async (dispatch: ThunkDispatch<void, IState, ProfileAction>) => {
    dispatch(() => ({ type: ProfileActionTypes.GET_USER_ANALYTIC }));
    await dispatch(getUserAnalyticsData());
  };
};

export const getUserAnalyticsSuccess = (
  payload: IUserAnalytic
): IGetUserAnalyticsSuccess => ({
  type: ProfileActionTypes.GET_USER_ANALYTIC_SUCCESS,
  payload
});

export const getUserAnalyticsDetailSuccess = (
  payload: IUserAnalyticDetail
): IGetUserAnalyticsDetailSuccess => ({
  type: ProfileActionTypes.GET_USER_ANALYTIC_DETAIL_SUCCESS,
  payload
});

export const getUserAnalyticsDetailLoad = (): IGetUserAnalyticsDetail => ({
  type: ProfileActionTypes.GET_USER_ANALYTIC_DETAIL
});

export const getUserAnalyticsDetail = (page: number, limit: number) => {
  return async (dispatch: ThunkDispatch<void, IState, ProfileAction>) => {
    await dispatch(getUserAnalyticsDetailData(page, limit));
  };
};

export interface ICloseModalFree {
  type: ProfileActionTypes.SET_CLOSE_MODAL_FREE;
  payload: boolean;
}

export const setCloseModalFree = (payload: boolean): ICloseModalFree => ({
  type: ProfileActionTypes.SET_CLOSE_MODAL_FREE,
  payload
});

export const claimRewardRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, ProfileAction>) => {
    await dispatch(claimRewardData());
  };
};

export const sendReferralEmailRequested = (payload: ISendReferralEmail) => {
  return async (dispatch: ThunkDispatch<void, IState, ProfileAction>) => {
    await dispatch(sendReferralEmailData(payload));
  };
};

export type ProfileAction =
  | IGetProfileSuccessAction
  | IEditProfileSuccessAction
  | IGetProfileFailedAction
  | ISetProfileLoaderAction
  | ISetProfileApiErrors
  | ISetProfileApiPasswordErrors
  | IGetBalanceSuccessAction
  | IGetActiveWithdrawal
  | ISetIsSidebarOpened
  | IAvailableChannels
  | ISetPendingRequests
  | IGetUserAnalytics
  | IGetUserAnalyticsSuccess
  | IGetUserAnalyticsDetail
  | IGetUserAnalyticsDetailSuccess
  | ICloseModalFree;
