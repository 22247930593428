import { makeStyles } from '@mui/styles';
import { theme } from '../../../../assets/styles/configs/themeMaterials';

export const useStyles = makeStyles(() => ({
  title: {
    '&.MuiTypography-root': {
      fontFamily: theme.fontFamily.primary,
      fontSize: '60px',
      textAlign: 'center',
      color: theme.common.primary
    }
  }
}));