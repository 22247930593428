import dayjs from 'dayjs';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  ICreatePlaylist,
  IPlaylist,
  PlaylistType
} from '../../domains/models/playlist';
import {
  addToPlaylist,
  createPlaylist,
  deleteAllPlaylists,
  deletePlaylist,
  deletePlaylistVideo,
  editName,
  getPlaylist,
  getPlaylistVideo,
  getPlaylistWithCheckingVideo
} from '../../services/playlist';
import { handleVideosDate } from '../../utils/handleDate';
import { handleErrors } from '../../utils/handleErrors';
import { Status } from '../../views/shared/components/CustomAlert/component';
import { IEditName } from '../../views/shared/components/EditableName/hook';
import { setMessage } from '../actions/message';
import {
  deleteAllPlaylistsSuccess,
  deleteUserPlaylistSuccess,
  deleteUserPlaylistVideoSuccess,
  editNameSuccess,
  getPlaylistSuccess,
  getUserPlaylistVideoSuccess,
  setPlaylistLoader,
  toggleVideoPlaylist
} from '../actions/playlist';
import { setSnackbar } from '../actions/snackbar';
import {
  createChannelPlaylistSuccess,
  deleteChannelPlaylistSuccess
} from '../actions/сhannels';
import { IState } from '../reducers';
import { declineUser } from './auth';

export const getPlaylistData = (id?: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setPlaylistLoader(true));
    try {
      const response = id
        ? await getPlaylistWithCheckingVideo(id)
        : await getPlaylist();
      await dispatch(declineUser(response));
      const { playlists } = response.data.data;
      const transformPlaylists = playlists.map((playlist: IPlaylist) => ({
        ...playlist,
        updatedAt: dayjs(playlist.updatedAt).format('D MMM YYYY')
      }));
      dispatch(getPlaylistSuccess(transformPlaylists));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setPlaylistLoader(false));
    }
  };
};

export const getUserPlaylistVideoData = (id: string) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setPlaylistLoader(true));
    try {
      const response = await getPlaylistVideo(id);
      await dispatch(declineUser(response));
      const transformPlaylist = {
        ...response.data.data,
        updatedAt: dayjs(response.data.data.updatedAt).format('D MMM YYYY'),
        videos: handleVideosDate(response.data.data.videos)
      };
      dispatch(getUserPlaylistVideoSuccess(transformPlaylist));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setPlaylistLoader(false));
    }
  };
};

export const deletePlaylistVideoData = (
  videoId: number,
  playlistId: number
) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setPlaylistLoader(true));
    try {
      const response = await deletePlaylistVideo(videoId, playlistId);
      await dispatch(declineUser(response));
      dispatch(toggleVideoPlaylist());
      dispatch(deleteUserPlaylistVideoSuccess(videoId));
      dispatch(
        setSnackbar({
          snackbar: 'removedFromPlaylist',
          status: Status.WARNING
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setPlaylistLoader(false));
    }
  };
};

export const deletePlaylistData = (playlistId: number, type?: PlaylistType) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setPlaylistLoader(true));
    try {
      const response = await deletePlaylist(playlistId);
      await dispatch(declineUser(response));
      type
        ? dispatch(deleteChannelPlaylistSuccess(playlistId))
        : dispatch(deleteUserPlaylistSuccess(playlistId));
      await dispatch(declineUser(response));
      dispatch(
        setMessage({
          message: 'playlistDeletedSuccessfully',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setPlaylistLoader(false));
    }
  };
};

export const createPlaylistData = (
  playlistData: ICreatePlaylist,
  type?: PlaylistType
) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setPlaylistLoader(true));
    try {
      const response = await createPlaylist(playlistData);
      await dispatch(declineUser(response));
      dispatch(toggleVideoPlaylist());
      dispatch(
        setSnackbar({
          snackbar: 'addedToPlaylist',
          status: Status.SUCCESS
        })
      );
      if (type) {
        dispatch(createChannelPlaylistSuccess(response.data.data));
      }
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setPlaylistLoader(false));
    }
  };
};

export const addToPlaylistData = (playlistId: string, videoId: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setPlaylistLoader(true));
    try {
      const response = await addToPlaylist(playlistId, videoId)
      await dispatch(declineUser(response));
      dispatch(toggleVideoPlaylist());
      dispatch(
        setSnackbar({
          snackbar: 'addedToPlaylist',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setPlaylistLoader(false));
    }
  };
};

export const editNameData = (payload: IEditName, id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setPlaylistLoader(true));
    try {
      const response = await editName(payload, id);
      await dispatch(declineUser(response));
      dispatch(editNameSuccess(response.data.data[0]));
      dispatch(
        setSnackbar({
          snackbar: 'playListNameChanged',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setSnackbar({ snackbar: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setPlaylistLoader(false));
    }
  };
};

export const deleteAllPlaylistsData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setPlaylistLoader(true));
    try {
      const response = await deleteAllPlaylists();
      await dispatch(declineUser(response));
      dispatch(deleteAllPlaylistsSuccess());
      dispatch(
        setMessage({
          message: 'allPlaylistsDeletedSuccessfully',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setPlaylistLoader(false));
    }
  };
};
