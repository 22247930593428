import { AppBar, Box, Grid, Link, Toolbar, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { setIsSidebarOpened } from '../../../application/actions/profile';
import { receiveProfile } from '../../../application/reducers';
import { ReactComponent as Hamburger } from '../../../assets/images/hamburger.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg';
import BaseTubeLogo from '../../../assets/images/BaseTube.png';
import { theme } from '../../../assets/styles/configs/themeMaterials';
import { getSearchAutocomplete } from '../../../services/search';
import { getToken } from '../../../utils/token';
import { AutocompleteSearch } from '../AutocompleteSearch';
import { UserMenu } from '../UserMenu';
import { useStyles } from './styles';

const getSearchUrl = (pathname: string, search: string): string => {
  if (!pathname.includes('search/')) {
    return '';
  }
  const urls = pathname.split('search/');
  try {
    const url = urls[urls.length - 1];
    return url + search;
  } catch (_) {
    return '';
  }
};

const handleAutocomplete = async (value: string): Promise<string[]> => {
  const res = await getSearchAutocomplete(value);
  return res.data.data.suggestions;
};

type IHeader = {
  isMobileOpen: boolean;
  setIsMobileOpen: (isMobileOpen: boolean) => void;
};

export const Header = ({ isMobileOpen, setIsMobileOpen }: IHeader) => {
  const {
    appBar,
    logoWrapper,
    hamburger,
    logoLink,
    searchBar
  } = useStyles();
  const token = getToken();
  const { balance, user, isSidebarOpened, experience } =
    useSelector(receiveProfile);
  const matches = useMediaQuery('(max-width:440px)');
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [query, setQuery] = useState(
    getSearchUrl(location.pathname, location.search)
  );
  const [optionList, setOptionList] = useState<string[]>([]);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

  const handleSearch = (value: SyntheticEvent): void => {
    const target = value?.target as HTMLInputElement;
    if (!target) {
      return;
    }
    if (target.outerText) {
      return setQuery(target.outerText);
    }
    if (!target?.outerText && !target?.value) {
      if (location.pathname.includes('search/')) {
        history.push(`/`);
      } else {
        history.push(location.pathname + location.search);
      }
      return setQuery('');
    }
    return setQuery(target?.value || '');
  };

  const getAutocompleteList = useCallback(async () => {
    if (query) {
      const response: string[] = await handleAutocomplete(query);
      setOptionList(response);
    } else {
      setOptionList([]);
    }
  }, [query]);

  useEffect(() => {
    getAutocompleteList();
  }, [getAutocompleteList]);

  const handleSearchButton = (): void =>
    setIsSearchBarVisible(!isSearchBarVisible);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1
        }}
      >
        <AppBar className={appBar} position="fixed" color="inherit">
          <Toolbar
            sx={{
              justifyContent: 'space-between'
            }}
          >
            <Grid className={logoWrapper}>
              <IconButton
                aria-label="search"
                className={hamburger}
                onClick={() => {
                  dispatch(setIsSidebarOpened(!isSidebarOpened));
                  setIsMobileOpen(!isMobileOpen);
                  localStorage.setItem(
                    'isSidebarOpened',
                    `${!isSidebarOpened}`
                  );
                }}
              >
                <Hamburger />
              </IconButton>
              <Link component={RouterLink} to="/" className={logoLink}>
                <img src={BaseTubeLogo} style={{ width: 138 }} alt="" />
              </Link>
            </Grid>

            {matches ? (
              <IconButton
                onClick={handleSearchButton}
                sx={{ bgcolor: theme.bg.searchField }}
              >
                <SearchIcon />
              </IconButton>
            ) : (
              <AutocompleteSearch
                onChangeInput={handleSearch}
                query={query}
                optionList={optionList}
              />
            )}
            <UserMenu
              rno={balance && balance.coinsAmount}
              picture={user && user.picture}
              isAuthenticated={token}
              name={user && user.name}
              experience={experience}
            />
          </Toolbar>
        </AppBar>
        {matches && isSearchBarVisible && (
          <Box className={searchBar}>
            <AutocompleteSearch
              onChangeInput={handleSearch}
              query={query}
              optionList={optionList}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
