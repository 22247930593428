import { makeStyles } from '@mui/styles';
import { theme } from '../../../../assets/styles/configs/themeMaterials';

export const useStyles = makeStyles(() => ({
  collapse: {
    width: '100%'
  },
  alert: {
    whiteSpace: 'pre-line',
    alignItems: 'center',
    '& .MuiAlert-message': {
      fontSize: '14px',
      fontFamily: theme.fontFamily.primary
    },

    '&.success': {
      '&.MuiAlert-root': {
        backgroundColor: theme.common.infoLight
      },
      '& .MuiAlert-message': {
        color: theme.common.infoDark,
        '& path': {
          fill: theme.common.infoDark
        }
      },
      '& .MuiIconButton-root': {
        '& path': {
          fill: theme.common.infoDark
        }
      }
    },
    '&.warning': {
      '&.MuiAlert-root': {
        backgroundColor: theme.common.removeLight
      },
      '& .MuiAlert-message': {
        color: theme.common.removeDark,
        '& path': {
          fill: theme.common.removeDark
        }
      },
      '& .MuiIconButton-root, & .MuiAlert-icon': {
        '& path': {
          fill: theme.common.removeDark
        }
      }
    },
    '&.error': {
      '&.MuiAlert-root': {
        backgroundColor: theme.common.errorLight
      },
      '& .MuiAlert-message': {
        color: theme.text.errorDark,
        '& path': {
          fill: theme.common.errorDark
        }
      },
      '& .MuiIconButton-root, & .MuiAlert-icon': {
        '& path': {
          fill: theme.common.errorDark
        }
      }
    }
  }
}));