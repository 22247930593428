import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  IForgotPasswordPayload,
  ILoginPayload,
  IRegisterPayload
} from '../../domains/models';
import { DataResponse } from '../../domains/models/response';
import {
  getEmailVerification,
  postForgotPassword,
  postLogin,
  postLogout,
  postRegister,
  resendVerificationEmail
} from '../../services/auth';
import { handleError422, handleErrors } from '../../utils/handleErrors';
import { getToken, setToken, unsetToken } from '../../utils/token';
import { setVpnMessage, unsetVpnMessage } from '../../utils/vpnMessage';
import { Status } from '../../views/shared/components/CustomAlert/component';
import {
  getRepeatedVerificationEmail,
  loginFailed,
  loginSuccess,
  logoutSuccess,
  setAuthLoader,
  setRegisterApiErrors
} from '../actions/auth';
import { declineUserAction } from '../actions/declining';
import { setMessage } from '../actions/message';
import { getProfileFailed } from '../actions/profile';
import { redirect } from '../actions/redirect';
import { IState } from '../reducers';
import { getProfileData } from './profile';

export const login = (payload: ILoginPayload) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setAuthLoader(true));
    try {
      const response = await postLogin(payload);
      const { token } = response.data.data;
      setToken(token);
      setVpnMessage();
      dispatch(loginSuccess());
    } catch (error: any) {
      dispatch(loginFailed());
      unsetVpnMessage();
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      dispatch(getRepeatedVerificationEmail(payload.email));
    } finally {
      dispatch(setAuthLoader(false));
    }
  };
};

export const register = (payload: IRegisterPayload) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setAuthLoader(true));
    try {
      const response = await postRegister({
        ...payload,
        dob: dayjs(payload.dob).format('MM/DD/YYYY')
      });
      dispatch(
        setMessage({
          message: response?.data?.data?.message[0] ?? '',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      if (error.response.status === 422) {
        const error422Data = handleError422(error.response.data.errors);
        dispatch(setRegisterApiErrors(error422Data));
      } else {
        const errorData = handleErrors(error);
        dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      }
    } finally {
      dispatch(setAuthLoader(false));
    }
  };
};

export const registerVerification = (id: string) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setAuthLoader(true));
    try {
      const response = await getEmailVerification(id);
      const { token } = response.data.data;
      setToken(token);
      setVpnMessage();
      await dispatch(getProfileData());
      dispatch(loginSuccess());
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setAuthLoader(false));
    }
  };
};

export const forgotPassword = (payload: IForgotPasswordPayload) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setAuthLoader(true));
    try {
      const response = await postForgotPassword(payload);
      const { message } = response.data.data;
      dispatch(
        setMessage({
          message,
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setAuthLoader(false));
    }
  };
};

export const logout = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      await postLogout();
      unsetToken();
      unsetVpnMessage();
      dispatch(logoutSuccess());
      dispatch(getProfileFailed());
      dispatch(redirect('/login'));
      dispatch(getRepeatedVerificationEmail(''));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const declineUser = (response: AxiosResponse<DataResponse<any>>) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      if (response?.data?.declined && getToken()) {
        await postLogout();
        unsetToken();
        unsetVpnMessage();
        dispatch(logoutSuccess());
        dispatch(declineUserAction(true));
        dispatch(
          setMessage({
            message: 'Your Account is waiting for admin approval!',
            status: Status.ERROR
          })
        );
      } else {
        dispatch(declineUserAction(false));
      }
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const resendVerificationEmailData = (email: string) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setAuthLoader(true));
    try {
      const response = await resendVerificationEmail(email);
      dispatch(
        setMessage({
          message: response?.data?.data?.message[0] ?? '',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setAuthLoader(false));
    }
  };
};
