import { ICategoriesList } from '../../domains/models/categories';
import {
  IChannel,
  IChannelData,
  IChannelsResponse,
  IGetPreferredChannelsResponse,
  IGetSubscribersResponse
} from '../../domains/models/channels';
import { ChannelAction, ChannelActionTypes } from '../actions/сhannels';

export interface IChannelsState {
  isLoading: boolean;
  channel: IChannelData | null;
  channels: IChannelsResponse | null;
  myChannels: IChannelsResponse | null;
  subscribedChannels: IChannelsResponse | null;
  channelErrors: Partial<IChannel> | null;
  isSubscribed: boolean;
  subscribersOfChannel: IGetSubscribersResponse | null;
  channelsList: ICategoriesList[] | null;
  preferredChannels: IGetPreferredChannelsResponse | null;
  myChannelsNumber: number;
  assotiatedChannels: IChannelsResponse | null;
}

export const initialChannelsState: IChannelsState = {
  isLoading: false,
  channel: null,
  channels: null,
  myChannels: null,
  subscribedChannels: null,
  channelErrors: null,
  isSubscribed: false,
  subscribersOfChannel: null,
  channelsList: null,
  preferredChannels: null,
  myChannelsNumber: 0,
  assotiatedChannels: null
};

export const handleChannelSubscribing = (
  channelData: IChannel[],
  payloadChannel: IChannel
) => {
  return channelData.map((channel) => {
    if (channel.id === payloadChannel.id) {
      return payloadChannel;
    }
    return channel;
  });
};

export default function channelReducer(
  state: IChannelsState = initialChannelsState,
  action: ChannelAction
) {
  switch (action.type) {
    case ChannelActionTypes.DELETE_CHANNEL_SUCCESS:
      const channelsData =
        state?.myChannels?.channelsData.filter(
          (data) => data.id !== +action.id
        ) ?? [];
      return {
        ...state,
        myChannels: { ...state.myChannels, channelsData },
        myChannelsNumber: state.myChannelsNumber - 1
      };
    case ChannelActionTypes.SET_CHANNELS_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case ChannelActionTypes.GET_CHANNEL_SUCCESS: {
      return {
        ...state,
        channel: action.payload,
        myChannelsNumber: state.myChannelsNumber + 1
      };
    }
    case ChannelActionTypes.GET_CHANNELS_SUCCESS: {
      const oldChannels = state?.channels?.channelsData ?? [];
      const { channels, isUpdating } = action.payload;
      return {
        ...state,
        channels: {
          channelsData: isUpdating
            ? channels.channelsData
            : [...oldChannels, ...channels.channelsData],
          total: channels.total,
          totalPages: channels.totalPages
        }
      };
    }
    case ChannelActionTypes.GET_SUBSCRIBED_CHANNELS_SUCCESS: {
      const oldChannels = state?.subscribedChannels?.channelsData ?? [];
      const { channels, isUpdating } = action.payload;
      return {
        ...state,
        subscribedChannels: {
          channelsData: isUpdating
            ? channels.channelsData
            : [...oldChannels, ...channels.channelsData],
          total: channels.total,
          totalPages: channels.totalPages
        }
      };
    }
    case ChannelActionTypes.GET_MY_CHANNELS_SUCCESS: {
      const oldChannels = state?.myChannels?.channelsData ?? [];
      const { channels, isUpdating } = action.payload;
      return {
        ...state,
        myChannels: {
          channelsData: isUpdating
            ? channels.channelsData
            : [...oldChannels, ...channels.channelsData],
          total: channels.total,
          totalPages: channels.totalPages
        },
        myChannelsNumber: channels.total
      };
    }
    case ChannelActionTypes.SET_CHANNEL_API_ERRORS: {
      return {
        ...state,
        channelErrors: action.payload
      };
    }
    case ChannelActionTypes.GET_IS_SUBSCRIBED_IN_CHANNELS_SUCCESS: {
      const updateChannels = state.channels
        ? handleChannelSubscribing(
            state.channels.channelsData,
            action.payload.channel
          )
        : [];
      const updateSubscribedChannels = state.subscribedChannels
        ? handleChannelSubscribing(
            state.subscribedChannels.channelsData,
            action.payload.channel
          )
        : [];
      const updatePreferredChannels = state.preferredChannels
        ? handleChannelSubscribing(
            state.preferredChannels.preferredChannels,
            action.payload.channel
          )
        : [];

      return {
        ...state,
        channels: state.channels
          ? {
              ...state.channels,
              channelsData: updateChannels
            }
          : null,
        subscribedChannels: state.subscribedChannels
          ? {
              ...state.subscribedChannels,
              channelsData: updateSubscribedChannels
            }
          : null,
        preferredChannels: state.preferredChannels
          ? {
              ...state.preferredChannels,
              preferredChannels: updatePreferredChannels
            }
          : null
      };
    }
    case ChannelActionTypes.GET_IS_SUBSCRIBED_IN_CHANNEL_SUCCESS: {
      return {
        ...state,
        channel: { ...state.channel, channelData: action.payload.channel }
      };
    }
    case ChannelActionTypes.CREATE_CHANNEL_PLAYLIST_SUCCESS: {
      return {
        ...state,
        channel: {
          ...state.channel,
          playlist: [...(state.channel?.playlist ?? []), action.payload]
        }
      };
    }
    case ChannelActionTypes.DELETE_CHANNEL_PLAYLIST_SUCCESS: {
      const filteredPlaylist = state.channel?.playlist.filter(
        (playlist) => playlist.id !== action.payload
      );
      return {
        ...state,
        channel: state.channel
          ? {
              ...state.channel,
              playlist: filteredPlaylist
            }
          : null
      };
    }
    case ChannelActionTypes.GET_SUBSCRIBERS_SUCCESS: {
      const oldSubscribers = state?.subscribersOfChannel?.subscribers ?? [];
      const { subscribersData, isUpdating } = action.payload;
      return {
        ...state,
        subscribersOfChannel: {
          subscribers: isUpdating
            ? subscribersData.subscribers
            : [...oldSubscribers, ...subscribersData.subscribers],
          paginate: subscribersData.paginate
        }
      };
    }
    case ChannelActionTypes.GET_PREFERRED_CHANNELS_SUCCESS: {
      const oldChannels = state?.preferredChannels?.preferredChannels ?? [];
      const { channels, isUpdating } = action.payload;
      return {
        ...state,
        preferredChannels: {
          preferredChannels: isUpdating
            ? channels.preferredChannels
            : [...oldChannels, ...channels.preferredChannels],
          paginate: channels.paginate
        }
      };
    }
    case ChannelActionTypes.GET_CHANNELS_LIST_SUCCESS: {
      return {
        ...state,
        channelsList: action.payload
      };
    }
    case ChannelActionTypes.CHANGE_CHANNEL_SUCCESS: {
      return {
        ...state,
        channel: {
          ...state.channel,
          videos:
            state?.channel?.videos.filter(
              (video) => video.id !== action.payload
            ) || null
        }
      };
    }
    case ChannelActionTypes.DELETE_VIDEO_SUCCESS: {
      return {
        ...state,
        channel: {
          ...state.channel,
          videos:
            state?.channel?.videos.filter(
              (video) => video.id !== action.payload
            ) || null
        }
      };
    }
    case ChannelActionTypes.GET_ASSOCIATED_CHANNELS_SUCCESS: {
      return {
        ...state,
        assotiatedChannels: action.payload
      };
    }
    default:
      return state;
  }
}
