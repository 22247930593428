import {
  IForgotPasswordPayload,
  ILoginPayload,
  IRegisterPayload
} from '../domains/models';
import { instanceAxios } from './instanceAxios';

export const postLogin = (userData: ILoginPayload) => {
  return instanceAxios.post(`/auth/login`, JSON.stringify(userData));
};

export const postRegister = (userData: IRegisterPayload) => {
  return instanceAxios.post(`/auth/register`, JSON.stringify(userData));
};

export const getEmailVerification = (id: string) => {
  return instanceAxios.get(`/auth/email-verification?verificationCode=${id}`);
};

export const postForgotPassword = (userData: IForgotPasswordPayload) => {
  return instanceAxios.post('/auth/forgot-password', JSON.stringify(userData));
};

export const postLogout = () => {
  return instanceAxios.post('/auth/logout');
};

export const resendVerificationEmail = (email: string) => {
  return instanceAxios.post('/auth/email-verification', { email });
};
