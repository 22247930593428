import dayjs from "dayjs";
import { IChannel } from "../../domains/models";
import { IUserAnalyticList, IUserAnalyticVideo } from "../../domains/models/profile";
import { IPaginate } from "../../domains/models/response";

export class ProfileMapper {
  private static instance: ProfileMapper = new ProfileMapper();

  static Instance(): ProfileMapper {
    return ProfileMapper.instance;
  }

  metaPaginate(source: IPaginate): IPaginate {
    const res: IPaginate = {} as IPaginate;
    res.total = source.total;
    res.currentPage = source.currentPage;
    res.totalPages = source.totalPages;
    res.perPage = source.perPage;
    return {...res};
  }

  metaAnalyserDetail(source: IUserAnalyticList): IUserAnalyticList {
    const res: IUserAnalyticList = {} as IUserAnalyticList;
    res.comments = source.comments;
    res.dislikes = source.dislikes;
    res.earnings = +source.earnings.toFixed(2);
    res.likes = source.likes;
    res.views = source.views;
    res.video = ProfileMapper.Instance().metaAnalyserDetailVideo(source.video);
    return {...res};
  }

  metaAnalyserDetailVideo(source: IUserAnalyticVideo): IUserAnalyticVideo {
    const res: IUserAnalyticVideo = {} as IUserAnalyticVideo;
    res.createdAt = dayjs(source.createdAt).format('MMM D, YYYY');
    res.defaultImage = source.defaultImage;
    res.duration = source.duration;
    res.id = source.id;
    res.title = source.title;
    res.watchCount = source.watchCount;
    res.getChannel = ProfileMapper.Instance().metaAnalyserDetailChannel(source.getChannel);
    return {...res};
  }

  metaAnalyserDetailChannel (source: IChannel): IChannel {
    const res: IChannel = {} as IChannel;
    res.categories = source.categories;
    res.cover = source.cover;
    res.createdAt = source.createdAt;
    res.description = source.description;
    res.facebookLink = source.facebookLink;
    res.id = source.id;
    res.instagramLink = source.instagramLink;
    res.isApproved = source.isApproved;
    res.name = source.name;
    res.picture = source.picture;
    res.status = source.status;
    res.subscribeStatus = source.subscribeStatus;
    res.subscribersCount = source.subscribersCount;
    res.twitterLink = source.twitterLink;
    res.uniqueId = source.uniqueId;
    res.updatedAt = source.updatedAt;
    res.userId = source.userId;
    res.videosCount = source.videosCount;
    res.isOwner = source.isOwner;
    res.categoryName = source.categoryName;
    return {...res};
  }
}
