export enum RedirectActionTypes {
  REDIRECT = 'redirect/redirect',
  CLEAR_REDIRECT = 'redirect/clear-redirect'
}

export interface IRedirect {
  type: RedirectActionTypes.REDIRECT;
  payload: string;
}

export const redirect = (payload: string) => ({
  type: RedirectActionTypes.REDIRECT,
  payload
});

export interface IClearRedirect {
  type: RedirectActionTypes.CLEAR_REDIRECT;
}

export const clearRedirect = (): IClearRedirect => ({
  type: RedirectActionTypes.CLEAR_REDIRECT
});

export type RedirectAction = IRedirect | IClearRedirect;
