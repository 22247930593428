import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  IChangePasswordPayload,
  IDeleteAccountPayload,
  IProfilePayload
} from '../../domains/models';
import {
  IChannelMonetization,
  ISendReferralEmail,
  IUserAnalyticList
} from '../../domains/models/profile';
import { ProfileMapper } from '../../services/mapper/profile';
import {
  claimReward,
  deleteAccount,
  getBalance,
  getChannelMonetization,
  getProfile,
  getUserAnalytics,
  getUserAnalyticsDetail,
  postChannelMonetization,
  postProfile,
  putChangePassword,
  sendReferralEmail
} from '../../services/profile';
import { handleError422, handleErrors } from '../../utils/handleErrors';
import { setToken } from '../../utils/token';
import { Status } from '../../views/shared/components/CustomAlert/component';
import { setMessage } from '../actions/message';
import {
  availableChannelsForMonetization,
  editProfileSuccess,
  getBalanceRequested,
  getBalanceSuccess,
  getProfileFailed,
  getProfileRequested,
  getProfileSuccess,
  getUserAnalyticsDetailLoad,
  getUserAnalyticsDetailSuccess,
  getUserAnalyticsSuccess,
  setPendingRequests,
  setProfileApiErrors,
  setProfileApiPasswordErrors,
  setProfileLoader
} from '../actions/profile';
import { redirect } from '../actions/redirect';
import { setSnackbar } from '../actions/snackbar';
import { setMonetise } from '../actions/videos';
import { IState } from '../reducers';
import { declineUser } from './auth';

export const getProfileData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setProfileLoader(true));
    try {
      const response = await getProfile();
      await dispatch(declineUser(response));
      const {
        name,
        dob,
        email,
        picture,
        withdrawalAddress,
        description,
        referralCode,
        id
      } = response.data.data.user;
      const { hasActiveWithdrawal, canMonetise, hasPreferredCategories } =
        response.data.data;
      dispatch(
        getProfileSuccess({
          name,
          email,
          dob,
          picture,
          withdrawalAddress,
          description,
          hasActiveWithdrawal,
          canMonetise,
          hasPreferredCategories,
          referralCode,
          id
        })
      );
      dispatch(setMonetise(canMonetise));
    } catch (error) {
      dispatch(getProfileFailed());
      setToken(null);
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      dispatch(redirect('/login'));
    } finally {
      dispatch(setProfileLoader(false));
    }
  };
};

export const getBalanceData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setProfileLoader(true));
    try {
      const response = await getBalance();
      await dispatch(declineUser(response));
      dispatch(
        getBalanceSuccess({
          balance: response.data.data.balance,
          referrals: response.data.data.referrals,
          experience: response.data.data.experience
        })
      );
    } catch (error) {
      dispatch(getProfileFailed());
      setToken(null);
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      dispatch(redirect('/login'));
    } finally {
      dispatch(setProfileLoader(false));
    }
  };
};

export const getFullProfileData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setProfileLoader(true));
    try {
      await Promise.allSettled([
        dispatch(getProfileRequested()),
        dispatch(getBalanceRequested())
      ]);
    } catch (error) {
      dispatch(getProfileFailed());
      setToken(null);
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      dispatch(redirect('/login'));
    } finally {
      dispatch(setProfileLoader(false));
    }
  };
};

export const editProfile = (payload: IProfilePayload) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setProfileLoader(true));
    try {
      const response = await postProfile(payload);
      await dispatch(declineUser(response));
      const {
        name,
        dob,
        email,
        picture,
        withdrawalAddress,
        description,
        referralCode,
        id
      } = response.data.data.user;
      const { hasActiveWithdrawal, canMonetise, hasPreferredCategories } =
        response.data.data;
      dispatch(
        editProfileSuccess({
          name,
          email,
          dob,
          picture,
          withdrawalAddress,
          description,
          hasActiveWithdrawal,
          canMonetise,
          hasPreferredCategories,
          referralCode,
          id
        })
      );
      dispatch(redirect('/account/profile'));
    } catch (error: any) {
      if (error.response.status === 422) {
        const error422Data = handleError422(error.response.data.errors);
        dispatch(setProfileApiErrors(error422Data));
      } else {
        const errorData = handleErrors(error);
        dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      }
    } finally {
      dispatch(setProfileLoader(false));
    }
  };
};

export const changePassword = (payload: IChangePasswordPayload) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setProfileLoader(true));
    try {
      const response = await putChangePassword(payload);
      await dispatch(declineUser(response));
      dispatch(
        setMessage({ message: 'passwordUpdateSuccess', status: Status.SUCCESS })
      );
    } catch (error: any) {
      if (error.response.status === 422) {
        const error422Data = handleError422(error.response.data.errors);
        dispatch(setProfileApiPasswordErrors(error422Data));
      } else {
        const errorData = handleErrors(error);
        dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      }
    } finally {
      dispatch(setProfileLoader(false));
    }
  };
};

export const deleteAccountData = (payload: IDeleteAccountPayload) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setProfileLoader(true));
    try {
      await deleteAccount(payload);
      dispatch(getProfileFailed());
      setToken(null);
      dispatch(
        setMessage({
          message: 'accountDeletedSuccessfully',
          status: Status.SUCCESS
        })
      );
      dispatch(redirect('/home'));
    } catch (error: any) {
      if (error.response.status === 422) {
        const error422Data = handleError422(error.response.data.errors);
        dispatch(setProfileApiPasswordErrors(error422Data));
      } else {
        const errorData = handleErrors(error);
        dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      }
    } finally {
      dispatch(setProfileLoader(false));
    }
  };
};

export const channelsMonetizationData = (payload: IChannelMonetization) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setProfileLoader(true));
    try {
      const response = await postChannelMonetization(payload);
      await dispatch(declineUser(response));
      dispatch(setPendingRequests(true));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setProfileLoader(false));
    }
  };
};

export const channelsWithMonetizationData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setProfileLoader(true));
    try {
      const response = await getChannelMonetization();
      await dispatch(declineUser(response));
      dispatch(availableChannelsForMonetization(response.data.data));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setProfileLoader(false));
    }
  };
};

export const getUserAnalyticsData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      const response = await getUserAnalytics();
      await dispatch(declineUser(response));
      dispatch(getUserAnalyticsSuccess(response.data.data.analytic));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const getUserAnalyticsDetailData = (page: number, limit: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    try {
      dispatch(getUserAnalyticsDetailLoad());
      const response = await getUserAnalyticsDetail(page, limit);
      await dispatch(declineUser(response));
      const metaAnalyserDetail = ProfileMapper.Instance().metaAnalyserDetail;
      const metaPaginate = ProfileMapper.Instance().metaPaginate;
      const resultAnalytic = response.data.data.analytic.map(
        (el: IUserAnalyticList) => metaAnalyserDetail(el)
      );
      const resultPaginate = metaPaginate(response.data.data.paginate);
      dispatch(
        getUserAnalyticsDetailSuccess({
          analytic: [...resultAnalytic],
          paginate: { ...resultPaginate }
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const claimRewardData = () => {
  return async (dispatch: ThunkDispatch<void, IState, any>): Promise<void> => {
    try {
      await claimReward();
      const response = await getBalance();
      await dispatch(declineUser(response));
      dispatch(
        getBalanceSuccess({
          balance: response.data.data.balance,
          referrals: response.data.data.referrals,
          experience: response.data.data.experience
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    }
  };
};

export const sendReferralEmailData = (payload: ISendReferralEmail) => {
  return async (dispatch: ThunkDispatch<void, IState, any>): Promise<void> => {
    try {
      const response = await sendReferralEmail(payload);
      await dispatch(declineUser(response));
      dispatch(
        setSnackbar({
          snackbar: response?.data?.data?.message[0] ?? '',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setSnackbar({ snackbar: errorData, status: Status.ERROR }));
    }
  };
};
