import { AlertColor } from '@mui/material';

export enum MessageActionTypes {
  SET_MESSAGE = 'message/set-message',
  CLEAR_MESSAGE = 'message/clear-message'
}

export interface IMessageData {
  message: string;
  status?: AlertColor;
}

export interface ISetMessage {
  type: MessageActionTypes.SET_MESSAGE;
  payload: IMessageData;
}

export const setMessage = (payload: IMessageData) => ({
  type: MessageActionTypes.SET_MESSAGE,
  payload
});

export interface IClearMessage {
  type: MessageActionTypes.CLEAR_MESSAGE;
}

export const clearMessage = (): IClearMessage => ({
  type: MessageActionTypes.CLEAR_MESSAGE
});

export type MessageAction = ISetMessage | IClearMessage;
