import { ThunkDispatch } from 'redux-thunk';
import {
  IComment,
  ILike,
  ISubmitCommentsValues,
  IUploadVideo,
  IUploadVideoPreviewResponse,
  IUploadVideoResponse,
  IVideoData,
  IVideosResponse,
  TypeCaptcha
} from '../../domains/models/videos';
import { togglePrerender } from '../../utils/prerender';
import { IState } from '../reducers';
import {
  createCommentData,
  deleteVideoData,
  dislikeCommentData,
  dislikeVideoData,
  getIsSubscribedInVideoData,
  getRecentVideosData,
  getRecommendedVideosData,
  getSuggestVideosData,
  getTagVideosData,
  getTrendingVideosData,
  getVideoData,
  getVideoPageData,
  likeCommentData,
  likeVideoData,
  pinCommentData,
  postUploadVideoData,
  resetCaptchaData,
  sharedVideoData,
  unpinCommentData,
  uploadVideoData,
  uploadVideoPreviewData
} from '../thunk/videos';

export enum VideosActionTypes {
  SET_VIDEOS_LOADER = 'videos/set-videos-loader',
  SET_VIDEOS_DROPZONE_LOADER = 'videos/set-videos-dropzone-loader',
  GET_RECENT_VIDEOS_SUCCESS = 'videos/get-recent-videos-success',
  GET_TRENDING_VIDEOS_SUCCESS = 'videos/get-trending-videos-success',
  GET_RECOMMENDED_VIDEOS_SUCCESS = 'videos/get-recommended-videos-success',
  GET_SUGGEST_VIDEOS_SUCCESS = 'videos/get-suggest-videos-success',
  GET_VIDEO_SUCCESS = 'videos/get-video-success',
  TOGGLE_VIDEO_WISHLIST_SUCCESS = 'videos/toggle-video-wishlist-success',
  LIKE_VIDEO_SUCCESS = 'videos/like-video-success',
  GET_IS_SUBSCRIBED_IN_VIDEO_SUCCESS = 'videos/get-is-subscribed-in-video-success',
  UPLOAD_VIDEO_SUCCESS = 'videos/upload-video-success',
  SET_VIDEO_API_ERRORS = 'videos/set-video-api-errors',
  UPLOAD_VIDEO_PREVIEW_SUCCESS = 'videos/upload-video-preview-success',
  CLEAR_VIDEO_PREVIEW = 'videos/clear-video-preview',
  POST_UPLOAD_VIDEO_SUCCESS = 'videos/post-upload-video-success',
  GET_YOUTUBE_LINK = 'videos/get-youtube-link',
  CREATE_COMMENT_SUCCESS = 'videos/create-comment-success',
  LIKE_COMMENT_SUCCESS = 'videos/like-comment-success',
  PIN_COMMENT_SUCCESS = 'videos/pin-comment-success',
  GET_TAG_VIDEOS_SUCCESS = 'videos/get-tag-videos-success',
  CLEAR_VIDEO = 'videos/clear-video',
  SET_MONETISE = 'videos/set-monetise',
  DELETE_CAPTCHA = 'videos/set-captcha',
  TOGGLE_SHARE_POPUP = 'videos/toggle-share-popup'
}

export interface ISetVideosLoaderAction {
  type: VideosActionTypes.SET_VIDEOS_LOADER;
  payload: boolean;
}

export const setVideosLoader = (
  isLoading: boolean
): ISetVideosLoaderAction => {
  togglePrerender(isLoading);
  return ({
    type: VideosActionTypes.SET_VIDEOS_LOADER,
    payload: isLoading
  });
};

export interface ISetVideosDropzoneLoader {
  type: VideosActionTypes.SET_VIDEOS_DROPZONE_LOADER;
  payload: boolean;
}

export const setVideosDropzoneLoader = (
  isDropzoneLoading: boolean
): ISetVideosDropzoneLoader => ({
  type: VideosActionTypes.SET_VIDEOS_DROPZONE_LOADER,
  payload: isDropzoneLoading
});

export const recentVideosRequested = (page: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(getRecentVideosData(page));
  };
};

export interface IGetRecentVideosSuccess {
  type: VideosActionTypes.GET_RECENT_VIDEOS_SUCCESS;
  payload: {
    recentVideos: IVideosResponse;
    isUpdating: boolean;
  };
}

export const getRecentVideosSuccess = (payload: {
  recentVideos: IVideosResponse;
  isUpdating: boolean;
}): IGetRecentVideosSuccess => ({
  type: VideosActionTypes.GET_RECENT_VIDEOS_SUCCESS,
  payload
});

export const trendingVideosRequested = (
  page: number,
  sort: string,
  isUpdating: boolean
) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(getTrendingVideosData(page, sort, isUpdating));
  };
};

export interface IGetTrendingVideosSuccess {
  type: VideosActionTypes.GET_TRENDING_VIDEOS_SUCCESS;
  payload: {
    trendingVideos: IVideosResponse;
    isUpdating: boolean;
  };
}

export const getTrendingVideosSuccess = (payload: {
  trendingVideos: IVideosResponse;
  isUpdating: boolean;
}): IGetTrendingVideosSuccess => ({
  type: VideosActionTypes.GET_TRENDING_VIDEOS_SUCCESS,
  payload
});

export const suggestVideosRequested = (id: string) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(getSuggestVideosData(id));
  };
};

export interface IGetSuggestVideosSuccess {
  type: VideosActionTypes.GET_SUGGEST_VIDEOS_SUCCESS;
  payload: IVideosResponse;
}

export const getSuggestVideosSuccess = (
  payload: IVideosResponse
): IGetSuggestVideosSuccess => ({
  type: VideosActionTypes.GET_SUGGEST_VIDEOS_SUCCESS,
  payload
});

export const videoRequested = (id: string, page: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(getVideoData(id, page));
  };
};

export interface IGetVideoSuccess {
  type: VideosActionTypes.GET_VIDEO_SUCCESS;
  payload: {
    videoData: IVideoData;
    page: number;
  };
}

export const getVideoSuccess = (payload: {
  videoData: IVideoData;
  page: number;
}): IGetVideoSuccess => ({
  type: VideosActionTypes.GET_VIDEO_SUCCESS,
  payload
});

export const getVideosPageChaining = (
  id: string,
  page: number,
  playlistId?: string
) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(getVideoPageData(id, page, playlistId));
  };
};

export const recommendedVideosRequested = (
  page: number,
  sort: string,
  isUpdating: boolean
) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(getRecommendedVideosData(page, sort, isUpdating));
  };
};

export interface IGetRecommendedVideosSuccess {
  type: VideosActionTypes.GET_RECOMMENDED_VIDEOS_SUCCESS;
  payload: {
    recommendedVideos: IVideosResponse;
    isUpdating: boolean;
  };
}

export const getRecommendedVideosSuccess = (payload: {
  recommendedVideos: IVideosResponse;
  isUpdating: boolean;
}): IGetRecommendedVideosSuccess => ({
  type: VideosActionTypes.GET_RECOMMENDED_VIDEOS_SUCCESS,
  payload
});

export interface IToggleVideoWishlistSuccess {
  type: VideosActionTypes.TOGGLE_VIDEO_WISHLIST_SUCCESS;
}

export const toggleVideoWishlistSuccess = (): IToggleVideoWishlistSuccess => ({
  type: VideosActionTypes.TOGGLE_VIDEO_WISHLIST_SUCCESS
});

export const likeVideoRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(likeVideoData(id));
  };
};

export const dislikeVideoRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(dislikeVideoData(id));
  };
};

export interface ILikeVideosSuccess {
  type: VideosActionTypes.LIKE_VIDEO_SUCCESS;
  payload: ILike;
}

export const likeVideosSuccess = (payload: ILike): ILikeVideosSuccess => ({
  type: VideosActionTypes.LIKE_VIDEO_SUCCESS,
  payload
});

export const isSubscribedInVideoRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(getIsSubscribedInVideoData(id));
  };
};

export interface IGetIsSubscribedInVideoSuccess {
  type: VideosActionTypes.GET_IS_SUBSCRIBED_IN_VIDEO_SUCCESS;
  payload: number;
}

export const getIsSubscribedInVideoSuccess = (
  payload: number
): IGetIsSubscribedInVideoSuccess => ({
  type: VideosActionTypes.GET_IS_SUBSCRIBED_IN_VIDEO_SUCCESS,
  payload
});

export const uploadVideoRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(uploadVideoData());
  };
};

export interface IUploadVideoSuccess {
  type: VideosActionTypes.UPLOAD_VIDEO_SUCCESS;
  payload: IUploadVideoResponse;
}

export const uploadVideoSuccess = (
  payload: IUploadVideoResponse
): IUploadVideoSuccess => ({
  type: VideosActionTypes.UPLOAD_VIDEO_SUCCESS,
  payload
});

export const editVideoRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(uploadVideoData(id));
  };
};

export interface ISetVideoApiErrors {
  type: VideosActionTypes.SET_VIDEO_API_ERRORS;
  payload: Partial<IUploadVideo> | null;
}

export const setVideoApiErrors = (
  errors: Partial<IUploadVideo> | null
): ISetVideoApiErrors => ({
  type: VideosActionTypes.SET_VIDEO_API_ERRORS,
  payload: errors
});

export const uploadVideoPreviewRequested = (
  payload: FormData,
  uploadProgress: () => void
) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(uploadVideoPreviewData(payload, uploadProgress));
  };
};

export interface IUploadVideoPreviewSuccess {
  type: VideosActionTypes.UPLOAD_VIDEO_PREVIEW_SUCCESS;
  payload: IUploadVideoPreviewResponse;
}

export const uploadVideoPreviewSuccess = (
  payload: IUploadVideoPreviewResponse
): IUploadVideoPreviewSuccess => ({
  type: VideosActionTypes.UPLOAD_VIDEO_PREVIEW_SUCCESS,
  payload
});

export interface IClearVideoPreview {
  type: VideosActionTypes.CLEAR_VIDEO_PREVIEW;
}

export const clearVideoPreview = (): IClearVideoPreview => ({
  type: VideosActionTypes.CLEAR_VIDEO_PREVIEW
});

export const postUploadVideoRequested = (payload: FormData) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(postUploadVideoData(payload));
  };
};

export interface IPostUploadVideoSuccess {
  type: VideosActionTypes.POST_UPLOAD_VIDEO_SUCCESS;
  payload: IUploadVideoPreviewResponse;
}

export const postUploadVideoSuccess = (
  payload: IUploadVideoPreviewResponse
): IPostUploadVideoSuccess => ({
  type: VideosActionTypes.POST_UPLOAD_VIDEO_SUCCESS,
  payload
});

export interface IGetYoutubeLink {
  type: VideosActionTypes.GET_YOUTUBE_LINK;
  payload: string;
}

export const getYoutubeLink = (payload: string): IGetYoutubeLink => ({
  type: VideosActionTypes.GET_YOUTUBE_LINK,
  payload
});

export const createCommentRequested = (payload: ISubmitCommentsValues) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(createCommentData(payload));
  };
};

export interface ICreateCommentSuccess {
  type: VideosActionTypes.CREATE_COMMENT_SUCCESS;
  payload: IComment;
}

export const createCommentSuccess = (
  payload: IComment
): ICreateCommentSuccess => ({
  type: VideosActionTypes.CREATE_COMMENT_SUCCESS,
  payload
});

export const likeCommentRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(likeCommentData(id));
  };
};

export const dislikeCommentRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(dislikeCommentData(id));
  };
};

export interface ILikeCommentSuccess {
  type: VideosActionTypes.LIKE_COMMENT_SUCCESS;
  payload: ILike;
  id: number;
}

export const likeCommentSuccess = (
  payload: ILike,
  id: number
): ILikeCommentSuccess => ({
  type: VideosActionTypes.LIKE_COMMENT_SUCCESS,
  payload,
  id
});

export const pinCommentRequested = (videoId: number, id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(pinCommentData(videoId, id));
  };
};

export const unpinCommentRequested = (videoId: number, id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(unpinCommentData(videoId, id));
  };
};

export interface IPinCommentSuccess {
  type: VideosActionTypes.PIN_COMMENT_SUCCESS;
  id: number;
  unpin?: boolean;
}

export const pinCommentSuccess = (
  id: number,
  unpin?: boolean
): IPinCommentSuccess => ({
  type: VideosActionTypes.PIN_COMMENT_SUCCESS,
  id,
  unpin
});

export const deleteVideoRequested = (videoId: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(deleteVideoData(videoId));
  };
};

export const tagVideosRequested = (id: string, page: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(getTagVideosData(id, page));
  };
};

export interface IGetTagVideosSuccess {
  type: VideosActionTypes.GET_TAG_VIDEOS_SUCCESS;
  payload: {
    tagVideos: IVideosResponse;
    isUpdating: boolean;
  };
}

export const getTagVideosSuccess = (payload: {
  tagVideos: IVideosResponse;
  isUpdating: boolean;
}): IGetTagVideosSuccess => ({
  type: VideosActionTypes.GET_TAG_VIDEOS_SUCCESS,
  payload
});

export interface IClearVideo {
  type: VideosActionTypes.CLEAR_VIDEO;
}

export const clearVideo = (): IClearVideo => ({
  type: VideosActionTypes.CLEAR_VIDEO
});

export interface ISetMonetise {
  type: VideosActionTypes.SET_MONETISE;
  payload: boolean;
}

export const setMonetise = (payload: boolean): ISetMonetise => ({
  type: VideosActionTypes.SET_MONETISE,
  payload
});

export const resetCaptchaRequested = (type: TypeCaptcha) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(resetCaptchaData(type));
  };
};

export interface IDeleteCaptcha {
  type: VideosActionTypes.DELETE_CAPTCHA;
  payload: boolean;
}

export const deleteCaptcha = (payload: boolean): IDeleteCaptcha => ({
  type: VideosActionTypes.DELETE_CAPTCHA,
  payload
});

export interface IToggleSharePopup {
  type: VideosActionTypes.TOGGLE_SHARE_POPUP;
  payload: boolean;
}

export const toggleSharePopup = (payload: boolean): IToggleSharePopup => ({
  type: VideosActionTypes.TOGGLE_SHARE_POPUP,
  payload
});

export const sharedVideoRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, VideosAction>) => {
    await dispatch(sharedVideoData(id));
  };
};

export type VideosAction =
  | ISetVideosLoaderAction
  | ISetVideosDropzoneLoader
  | IGetRecentVideosSuccess
  | IGetTrendingVideosSuccess
  | IGetSuggestVideosSuccess
  | IGetVideoSuccess
  | IGetRecommendedVideosSuccess
  | IToggleVideoWishlistSuccess
  | ILikeVideosSuccess
  | IGetIsSubscribedInVideoSuccess
  | IUploadVideoSuccess
  | ISetVideoApiErrors
  | IUploadVideoPreviewSuccess
  | IClearVideoPreview
  | IPostUploadVideoSuccess
  | IGetYoutubeLink
  | ICreateCommentSuccess
  | ILikeCommentSuccess
  | IPinCommentSuccess
  | IGetTagVideosSuccess
  | IClearVideo
  | ISetMonetise
  | IDeleteCaptcha
  | IToggleSharePopup;
