import {
  ICreatePlaylist,
  IGetPlaylists,
  IPlaylistVideoResponse,
  PlaylistType
} from '../domains/models/playlist';
import { AxiosDataResponse } from '../domains/models/response';
import { IEditName } from '../views/shared/components/EditableName/hook';
import { instanceAxios } from './instanceAxios';

export const getPlaylistWithCheckingVideo = (
  id?: number
): Promise<AxiosDataResponse<IGetPlaylists>> => {
  return instanceAxios.get(`/playlist?videoId=${id}`);
};

export const getPlaylist = (): Promise<AxiosDataResponse<IGetPlaylists>> => {
  return instanceAxios.get(`/playlist?playlistType=USER`);
};

export const getPlaylistVideo = (
  id: string
): Promise<AxiosDataResponse<IPlaylistVideoResponse>> => {
  return instanceAxios.get(`/playlist/${id}`);
};

export const deletePlaylistVideo = (
  videoId: number,
  playlistId: number
): Promise<AxiosDataResponse> => {
  return instanceAxios.delete(`/playlist/${playlistId}/video/${videoId}`);
};

export const deletePlaylist = (
  playlistId: number
): Promise<AxiosDataResponse> => {
  return instanceAxios.delete(`/playlist/${playlistId}`);
};

export const createPlaylist = (
  payload: ICreatePlaylist
): Promise<AxiosDataResponse> => {
  return instanceAxios.post(`/playlist`, payload);
};

export const addToPlaylist = (
  playlistId: string,
  videoId: number
): Promise<AxiosDataResponse> => {
  return instanceAxios.post(`/playlist/${playlistId}/video/${videoId}`, {
    status: true
  });
};

export const editName = (
  payload: IEditName,
  id: number
): Promise<AxiosDataResponse> => {
  return instanceAxios.put(`/playlist/${id}`, payload);
};

export const deleteAllPlaylists = (): Promise<AxiosDataResponse> => {
  return instanceAxios.delete(`/playlist/all`, {
    data: {
      playlistType: PlaylistType.USER
    }
  });
};
