import { ThunkDispatch } from 'redux-thunk';
import {
  ISettingsResponse,
  IWithdrawalPayload
} from '../../domains/models/withdrawal';
import { IState } from '../reducers';
import {
  confirmWithdrawalData,
  getSettingsData,
  setWithdrawal
} from '../thunk/withdrawal';

export enum WithdrawalActionTypes {
  SET_WITHDRAWAL_LOADER = 'withdrawal/set-withdrawal-loader',
  GET_SETTINGS_SUCCESS = 'withdrawal/get-settings-success',
  SET_WITHDRAWAL_API_ERRORS = 'withdrawal/set-withdrawal-api-errors',
  SEND_WITHDRAWAL_EMAIL = 'withdrawal/send-withdrawal-email'
}

export interface ISetWithdrawalLoaderAction {
  type: WithdrawalActionTypes.SET_WITHDRAWAL_LOADER;
  payload: boolean;
}

export const setWithdrawalLoader = (
  isLoading: boolean
): ISetWithdrawalLoaderAction => ({
  type: WithdrawalActionTypes.SET_WITHDRAWAL_LOADER,
  payload: isLoading
});

export const withdrawalRequested = (payload: IWithdrawalPayload) => {
  return async (dispatch: ThunkDispatch<void, IState, WithdrawalAction>) => {
    await dispatch(setWithdrawal(payload));
  };
};

export const settingsRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, WithdrawalAction>) => {
    await dispatch(getSettingsData());
  };
};

export interface IGetSettingsSuccess {
  type: WithdrawalActionTypes.GET_SETTINGS_SUCCESS;
  payload: ISettingsResponse;
}

export const getSettingsSuccess = (
  settings: ISettingsResponse
): IGetSettingsSuccess => ({
  type: WithdrawalActionTypes.GET_SETTINGS_SUCCESS,
  payload: settings
});

export interface ISendWithdrawalEmail {
  type: WithdrawalActionTypes.SEND_WITHDRAWAL_EMAIL;
  payload: string;
}

export const sendWithdrawalEmail = (
  withdrawalEmail: string
): ISendWithdrawalEmail => ({
  type: WithdrawalActionTypes.SEND_WITHDRAWAL_EMAIL,
  payload: withdrawalEmail
});

export interface ISetWithdrawalApiErrors {
  type: WithdrawalActionTypes.SET_WITHDRAWAL_API_ERRORS;
  payload: Partial<IWithdrawalPayload> | null;
}

export const setWithdrawalApiErrors = (
  errors: Partial<IWithdrawalPayload> | null
) => ({
  type: WithdrawalActionTypes.SET_WITHDRAWAL_API_ERRORS,
  payload: errors
});

export const confirmWithdrawalRequested = (
  id: string | null,
  token: string | null
) => {
  return async (dispatch: ThunkDispatch<void, IState, WithdrawalAction>) => {
    await dispatch(confirmWithdrawalData(id, token));
  };
};

export type WithdrawalAction =
  | ISetWithdrawalLoaderAction
  | ISetWithdrawalApiErrors
  | IGetSettingsSuccess
  | ISendWithdrawalEmail;
