import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((fullTheme: Theme) => ({
  vpnAlert: {
    position: 'fixed',
    zIndex: 1,
    right: '15px',
    left: '15px',
    bottom: 0,
    [fullTheme.breakpoints.up('md')]: {
      width: '485px',
      left: 'auto'
    },
    [fullTheme.breakpoints.up('lg')]: {
      right: '35px'
    }
  }
}));
