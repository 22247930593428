import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@mui/material';
import React from 'react';
import { CustomButton, Variant } from '../ButtonCustom';
import { useStyles } from './styles';

type ICustomDialog = {
  children?: React.ReactNode;
  popupOpened: boolean;
  setPopupOpened: (popupOpened: boolean) => void;
  title: string;
  subtitle?: string;
  primaryBtn: string;
  secondaryBtn: string;
  styleDialog?: string;
  actionHandler: () => void;
  submit?: () => void;
};

export const CustomDialog = ({
  popupOpened,
  setPopupOpened,
  title,
  subtitle,
  primaryBtn,
  secondaryBtn,
  actionHandler,
  submit,
  styleDialog
}: ICustomDialog) => {
  const { dialog } = useStyles();

  return (
    <Grid>
      <Dialog
        className={`${dialog} ${styleDialog}`}
        open={popupOpened}
        onClose={() => setPopupOpened(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ width: '80%', margin: '0 auto' }}
        >
          {title}
        </DialogTitle>
        {subtitle && (
          <DialogContent sx={{ textAlign: 'center' }}>
            <DialogContentText id="alert-dialog-description">
              {subtitle}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '20px'
          }}
        >
          <CustomButton
            buttonVariant={Variant.SECONDARY}
            clickHandler={() => {
              setPopupOpened(false);
              submit && submit();
            }}
          >
            {secondaryBtn}
          </CustomButton>
          <CustomButton
            buttonVariant={Variant.PRIMARY}
            clickHandler={() => {
              actionHandler();
              setPopupOpened(false);
            }}
          >
            {primaryBtn}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
