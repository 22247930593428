import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export type ILoader = {
  size?: number;
  className?: string;
};

export const Loader = (props: ILoader) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        mt: '20px',
        alignSelf: 'center'
      }}
    >
      <CircularProgress {...props} />
    </Box>
  );
};
