import { Link, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import Stack from "@mui/material/Stack";
import { Link as RouterLink } from "react-router-dom";
import { theme } from '../../../assets/styles/configs/themeMaterials';
import { CustomButton, Variant } from "../../shared/components/ButtonCustom";
import { receiveProfile } from "../../../application/reducers";
import { setCloseModalFree} from "../../../application/actions/profile";
import CancelIcon from "@mui/icons-material/Cancel";

export const RegisterBanner = () => {
  const dispatch = useDispatch();
  const [isClose, setIsClose] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isSidebarOpened } = useSelector(receiveProfile);
  const { registerBanner, title, bold, bannerContent, btnContent, btnClose, cancelIcon } = useStyles();
  const { open, close } = theme.menuLeftBar;
  useEffect(() => {
    if (isClose) {
      dispatch(setCloseModalFree(isClose));
    }
  }, [dispatch, isClose]);

  const handleCloseModal = () => {
    setIsClose(true);
  };

  return (
    <Box className={registerBanner} width={`calc(100% - ${isSidebarOpened ? open : close }px)`}>
        <Typography className={btnClose}>
          <CancelIcon className={cancelIcon} onClick={handleCloseModal}/>
        </Typography>

        <div className={bannerContent}>
            <Typography className={`${title} ${bold}`} variant="h5">
              {t('dontWatchForFree')}
            </Typography>
            <Typography className={title} variant="subtitle1">
              {t('registerOnBaseTube')}
            </Typography>
        </div>
        <Stack spacing={2} direction="row" className={btnContent}>
            <Link component={RouterLink} to="/login" underline="none">
                <CustomButton buttonVariant={Variant.SECONDARY}>
                  {t('login')}
                </CustomButton>
            </Link>
            <Link component={RouterLink} to="/register" underline="none">
                <CustomButton buttonVariant={Variant.SECONDARY}>
                  {t('signUp')}
                </CustomButton>
            </Link>
        </Stack>
    </Box>
  );
};
