import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import { LargeTitle } from '../../shared/components/LargeTitle';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({
      hasError: true
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid
          container
          height={'100vh'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item>
            <LargeTitle>{'Something went wrong...'}</LargeTitle>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}
