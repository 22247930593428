import { PaletteMode } from '@mui/material';

export const theme = {
  button: {
    primary: 'linear-gradient(180deg, #FA7517 70%, #FA7517 100%)',
    secondary: 'linear-gradient(180deg, #FA7517 70%, #FA9933 100%)',
    round: 'linear-gradient(180deg, #FA7517 100%, #FA7517 70%)',
    switch: 'linear-gradient(to right, #FA7517 0%, #FA7517 100%)'
  },
  bg: {
    mainBlock: '#222',
    textField: '#eff1f3',
    textFieldSecondary: '#dfe9f4',
    searchField: '#eaedef',
    tagsItem: '#fafafa',
    overlay: 'rgba(139, 139, 139, 0.32)',
    cross: 'rgba(139, 139, 139, 0.41)',
    underline: 'rgba(199, 199, 199, 0.4)',
    transparent: 'rgba(17, 15, 15, 0.12)',
    popover: 'rgba(0, 0, 0, 0.5)'
  },
  border: {
    primary: '#eaedf1',
    buttonHover: 'rgba(25, 118, 210, 0.5)'
  },
  pieChart: [
    '#0172FF',
    '#0198FF',
    '#1A84A3',
    '#0E4757',
    '#034254',
    '#056682',
    '#057e82',
    '#00c6ff',
    '#00e1ff'
  ],
  statistic: {
    subtitle: '#7A7D86',
    body: '#656769'
  },
  text: {
    unimportant: 'rgba(139, 139, 139, 0.48)',
    search: 'rgba(170, 170, 170, 1)',
    errorDark: 'rgba(190, 34, 34, 0.84)'
  },
  common: {
    primary: '#FA7517',
    primaryLight: '#ffffff',
    primaryDark: '#000000',
    placeholder: '#c7c7c8',
    secondary: '#8b8b8b',
    secondaryLight: '#F2F3F5',
    secondaryDark: '#606062',
    twitter: 'linear-gradient(0deg, #1DA1F2, #1DA1F2), #FFFFFF',
    facebook: 'linear-gradient(0deg, #3a589e, #3a589e), #FFFFFF',
    infoLight: '#ccf1d2',
    infoDark: 'rgba(34, 146, 39, 0.72)',
    removeLight: '#c5e0fd',
    removeDark: 'rgba(62, 89, 231, 0.84)',
    errorDark: '#be2222',
    errorLight: '#f1cccc',
    hover: '#f5f5f5',
    divider: 'rgba(0, 0, 0, 0.12)',
    detail: '#FA7517',
    detailLight: '#fa8c3f'
  },
  boxShadow: {
    button: '0px 4px 15px rgba(250, 117, 23, 0.2)',
    switch: '0px 4px 4px rgba(0, 0, 0, 0.3)',
    userMenu:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    customShadow:
      '0px 0px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 20px 22px 2px rgb(0 0 0 / 12%)',
    glow: '0px 0px 8px 1px rgba(255, 255, 190, .60), inset 0px 0px 4px 1px rgba(255, 255, 190, .50)',
    socialIcons: '0px 4px 15px rgba(250, 117, 23, 0.2)',
    stroke: '1px 1px 1px rgba(0,0,0, 1)',
    sharePopover: 'drop-shadow( 3px 3px 2px rgba(0, 0, 0, .2))',
    socialLinkBtn:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
    socialLinkBtnHover:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);'
  },
  fontFamily: {
    primary: "'Nunito', serif"
  },
  menuLeftBar: {
    open: 240,
    close: 75
  }
};

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    text: {
      ...(mode === 'light'
        ? {
            ...theme.common
          }
        : {
            primary: theme.common.primaryLight
          })
    },
    background: {
      paper: '#121212',
      default: '#121212'
    }
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true
      },
      primary: '#fff'
    }
  }
});
