import { Box } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../assets/styles/configs/themeMaterials';

export const useStyles = makeStyles((fullTheme: Theme) => ({
  appBar: {
    color: theme.common.primary,
    boxShadow: 'none',
    height: '75px',
    borderBottom: `1px solid ${theme.border.primary}`,
    backgroundImage: 'none',
    [fullTheme.breakpoints.down('md')]: {
      height: '55px'
    }
  },
  searchIcon: {
    color: theme.text.search,
    padding: '20px 25px',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    position: 'absolute',
    [fullTheme.breakpoints.down('md')]: {
      padding: '10px'
    }
  },
  logoWrapper: {
    margin: '35px 0',
    display: 'flex',
    alignItems: 'center'
  },
  hamburger: {
    marginRight: '25px',
    width: '45px',
    height: '45px',
    [fullTheme.breakpoints.down('md')]: {
      marginRight: '10px'
    }
  },
  search: {
    position: 'relative'
  },
  logo: {
    marginRight: '10px',
    [fullTheme.breakpoints.down('md')]: {
      width: '30px'
    },
    [fullTheme.breakpoints.down('sm')]: {
      marginRight: 0
    }
  },
  logoText: {
    [fullTheme.breakpoints.down('md')]: {
      width: '70px'
    },
    [fullTheme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start'
  },
  searchBar: {
    backgroundColor: theme.common.primaryLight,
    position: 'fixed',
    top: 80,
    zIndex: 200000,
    width: '100%'
  }
}));

export const Search = styled(Box)(() => ({
  borderRadius: '215px',
  backgroundColor: theme.bg.searchField,
  margin: '10px'
}));

export const StyledInputBase = styled(InputBase)(({ theme: fullTheme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: '10px 20px',
    paddingLeft: fullTheme.spacing(6),
    transition: fullTheme.transitions.create('width'),
    width: '100%',
    [fullTheme.breakpoints.up('lg')]: {
      width: '440px'
    },
    [fullTheme.breakpoints.down('md')]: {
      padding: '5px 15px 5px 30px',
      fontSize: '12px'
    }
  }
}));
