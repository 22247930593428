import { Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useStyles } from './styles';

export const LargeTitle: FC<ReactNode> = ({ children }) => {
  const { title } = useStyles();
  return (
    <Typography component={'h1'} className={title}>
      {children}
    </Typography>
  );
};
