export const setToken = (userToken: string | null) => {
  localStorage.setItem('token', JSON.stringify(userToken));
};

export const unsetToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('wallet_provider');
};

export const getToken = () => {
  const tokenString = localStorage.getItem('token');
  return JSON.parse(tokenString as string);
};
