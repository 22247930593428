import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducers';

export const history = createBrowserHistory();
const routerMiddlewareInstance = routerMiddleware(history);

const getMiddleware = () => {
  if (process.env.NODE_ENV === 'production') {
    return applyMiddleware(routerMiddlewareInstance, thunk);
  } else {
    return applyMiddleware(routerMiddlewareInstance, thunk, createLogger());
  }
};

export const store = createStore(reducer, composeWithDevTools(getMiddleware()));
