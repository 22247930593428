import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../assets/styles/configs/themeMaterials';

export interface IListItemSocial {
  open: boolean;
}

export const useStyles = makeStyles((fullTheme: Theme) => ({
  listItemSocial: ({ open }: IListItemSocial) => {
    const hiddenStyle = open ? 'visible' : 'hidden';
    return {
      paddingLeft: '10px',
      visibility: hiddenStyle,
      [fullTheme.breakpoints.down('md')]: {
        visibility: 'visible'
      }
    };
  },
  linkSocial: {
    width: '30px',
    height: '30px',
    display: 'flex',
    marginRight: '2px',
    '&:hover': {
      background: theme.button.round,
      borderRadius: '50%',
      '& path, & circle': {
        fill: theme.common.primaryLight
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '30px',
    height: '30px',
    padding: '5px',
    borderRadius: '50%'
  },
  imgIcon: {
    width: '18px'
  },
  imgIconFacebook: {
    width: '10px'
  }
}));
