import { ThunkDispatch } from 'redux-thunk';
import { IWishlistResponse } from '../../domains/models/wishlist';
import { IState } from '../reducers';
import {
  deleteVideoWishlistData,
  deleteWishlistData,
  getWishlistData,
  toggleVideoWishlistData
} from '../thunk/wishlist';

export enum WishlistActionTypes {
  SET_WISHLIST_LOADER = 'wishlist/set-wishlist-loader',
  GET_WISHLIST_SUCCESS = 'wishlist/get-wishlist-success',
  DELETE_VIDEO_WISHLIST_SUCCESS = 'wishlist/delete-video-wishlist-success',
  DELETE_WISHLIST_SUCCESS = 'wishlist/delete-wishlist-success'
}

export interface ISetWishlistLoader {
  type: WishlistActionTypes.SET_WISHLIST_LOADER;
  payload: boolean;
}

export const setWishlistLoader = (isLoading: boolean): ISetWishlistLoader => ({
  type: WishlistActionTypes.SET_WISHLIST_LOADER,
  payload: isLoading
});

export const wishlistRequested = (page: number, isUpdating: boolean) => {
  return async (dispatch: ThunkDispatch<void, IState, WishlistAction>) => {
    await dispatch(getWishlistData(page, isUpdating));
  };
};

export interface IGetWishlistSuccess {
  type: WishlistActionTypes.GET_WISHLIST_SUCCESS;
  payload: IWishlistResponse;
}

export const getWishlistSuccess = (
  payload: IWishlistResponse
): IGetWishlistSuccess => ({
  type: WishlistActionTypes.GET_WISHLIST_SUCCESS,
  payload
});

export const deleteVideoWishlistRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, WishlistAction>) => {
    await dispatch(deleteVideoWishlistData(id));
  };
};

export const deleteWishlistRequested = () => {
  return async (dispatch: ThunkDispatch<void, IState, WishlistAction>) => {
    await dispatch(deleteWishlistData());
  };
};

export const toggleVideoWishlistRequested = (id: number) => {
  return async (dispatch: ThunkDispatch<void, IState, WishlistAction>) => {
    await dispatch(toggleVideoWishlistData(id));
  };
};

export interface IDeleteVideoWishlistSuccess {
  type: WishlistActionTypes.DELETE_VIDEO_WISHLIST_SUCCESS;
  payload: number;
}

export const deleteVideoWishlistSuccess = (
  payload: number
): IDeleteVideoWishlistSuccess => ({
  type: WishlistActionTypes.DELETE_VIDEO_WISHLIST_SUCCESS,
  payload
});

export interface IDeleteWishlistSuccess {
  type: WishlistActionTypes.DELETE_WISHLIST_SUCCESS;
}

export const deleteWishlistSuccess = (): IDeleteWishlistSuccess => ({
  type: WishlistActionTypes.DELETE_WISHLIST_SUCCESS
});

export type WishlistAction =
  | ISetWishlistLoader
  | IGetWishlistSuccess
  | IDeleteVideoWishlistSuccess
  | IDeleteWishlistSuccess;
