import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IHistoryVideo } from '../../domains/models/history';
import {
  deleteHistory,
  deleteVideoHistory,
  getHistory
} from '../../services/history';
import { handleDateHorizontalBlocks } from '../../utils/handleDate';
import { handleErrors } from '../../utils/handleErrors';
import { Status } from '../../views/shared/components/CustomAlert/component';
import {
  deleteHistorySuccess,
  deleteVideoHistorySuccess,
  getHistorySuccess,
  setHistoryLoader
} from '../actions/history';
import { setMessage } from '../actions/message';
import { IState } from '../reducers';
import { declineUser } from './auth';

export const getHistoryData = (page: number, isUpdating: boolean) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setHistoryLoader(true));
    try {
      const response = await getHistory(page);
      await dispatch(declineUser(response));
      const { history, paginate } = response.data.data;
      const transformVideos =
        handleDateHorizontalBlocks<IHistoryVideo>(history);
      dispatch(
        getHistorySuccess({
          historyData: {
            historyVideoData: transformVideos,
            totalPages: paginate.totalPages
          },
          isUpdating
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setHistoryLoader(false));
    }
  };
};

export const deleteVideoHistoryData = (id: number) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setHistoryLoader(true));
    try {
      const response = await deleteVideoHistory(id);
      await dispatch(declineUser(response));
      dispatch(deleteVideoHistorySuccess(id));
      dispatch(
        setMessage({
          message: 'videoDeletedSuccessfully',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setHistoryLoader(false));
    }
  };
};

export const deleteHistoryData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setHistoryLoader(true));
    try {
      const response = await deleteHistory();
      await dispatch(declineUser(response));
      dispatch(deleteHistorySuccess());
      dispatch(
        setMessage({
          message: 'historyDeletedSuccessfully',
          status: Status.SUCCESS
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setHistoryLoader(false));
    }
  };
};
