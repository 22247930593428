import { AxiosResponse } from 'axios';
import { AxiosDataResponse } from '../domains/models/response';
import {
  ISettingsResponse,
  IWithdrawalPayload
} from '../domains/models/withdrawal';
import { instanceAxios } from './instanceAxios';

export const postWithdrawal = (
  withdrawalData: IWithdrawalPayload
): Promise<AxiosResponse> => {
  return instanceAxios.post(`withdrawal`, withdrawalData);
};

export const getSettings = (): Promise<
  AxiosDataResponse<ISettingsResponse>
> => {
  return instanceAxios.get(`settings`);
};

export const confirmWithdrawal = (
  id: string | null,
  token: string | null
): Promise<AxiosResponse> => {
  return instanceAxios.get(`/withdrawal/confirm?id=${id}&token=${token}`);
};
