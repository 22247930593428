import { IComment, ILike } from '../domains/models/videos';

export const getComments = (
  values: IComment[],
  commentPayload: IComment
): IComment[] =>
  values.map((comment) => {
    if (comment.id === commentPayload.parentId) {
      return {
        ...comment,
        children: [commentPayload, ...comment.children]
      };
    } else if (comment.children.length) {
      return {
        ...comment,
        children: getComments(comment.children, commentPayload)
      };
    } else {
      return comment;
    }
  });
export const updateLikesComments = (
  values: IComment[],
  payload: ILike,
  id: number
): IComment[] =>
  values.map((comment) => {
    if (comment.id === id) {
      return {
        ...comment,
        likeCount: +payload.likeCount,
        dislikeCount: +payload.dislikeCount
      };
    } else if (comment.children.length) {
      return {
        ...comment,
        children: updateLikesComments(comment.children, payload, id)
      };
    } else {
      return comment;
    }
  });
