import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  alpha,
  Avatar,
  Button,
  Grid,
  LinearProgress,
  Link,
  Menu,
  MenuProps,
  Typography
} from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { logoutSuccess } from '../../../application/actions/auth';
import { receiveChannels } from '../../../application/reducers';
import { logout } from '../../../application/thunk/auth';
import { ReactComponent as MyChannel } from '../../../assets/images/myChannel.svg';
import { ReactComponent as Profile } from '../../../assets/images/profile.svg';
import { ReactComponent as SignOut } from '../../../assets/images/signOut.svg';
import { ReactComponent as Upload } from '../../../assets/images/upload.svg';
import { ReactComponent as Wallet } from '../../../assets/images/wallet.svg';
import { theme } from '../../../assets/styles/configs/themeMaterials';
import { IExperience } from '../../../domains/models/profile';
import { CustomButton, Variant } from '../../shared/components/ButtonCustom';
import { CustomDialog } from '../../shared/components/CustomDialog';
import { useStyles } from './styles';

const StyledButton = styled(Button)(({ theme: fullTheme }) => ({
  backgroundColor: 'transparent',
  padding: '10px 0',
  paddingTop: fullTheme.spacing(1),
  minWidth: '20px',
  '& .MuiButton-endIcon': {
    marginLeft: '5px'
  },
  '& svg': {
    [fullTheme.breakpoints.down('md')]: {
      width: '15px'
    }
  }
}));

const StyledMenuItem = styled(MenuItem)(({ theme: fullTheme }) => ({
  marginTop: fullTheme.spacing(1),
  padding: '5px 10px'
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme: fullTheme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: fullTheme.spacing(2),
    minWidth: 200,
    maxWidth: 400,
    color: theme.common.primary,
    boxShadow: theme.boxShadow.userMenu,
    '& .MuiMenu-list': {
      padding: '5px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        marginRight: fullTheme.spacing(3.5)
      },
      '&:active': {
        backgroundColor: alpha(
          fullTheme.palette.primary.main,
          fullTheme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

export type IUserMenu = {
  isAuthenticated: boolean;
  picture: string | null;
  rno: string | null;
  name: string | null;
  experience: IExperience | null;
};

export const UserMenu = ({
  picture,
  rno,
  isAuthenticated,
  name,
  experience
}: IUserMenu) => {
  const {
    arrowIcon,
    userMenuBtn,
    profile,
    dropdownIcon,
    link,
    uploadBtn,
    userInfoWrapper,
    userInfo,
    userName,
    level,
    levelDescription,
    progressBar
  } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { myChannelsNumber } = useSelector(receiveChannels);
  const [popupOpened, setPopupOpened] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getProgressValue = useCallback(() => {
    if (experience?.levelUp) {
      const experienceLevel = 2000;
      const progressValue =
        ((experienceLevel - experience.levelUp) * 100) / 2000;
      return experience.levelUp <= 20
        ? Math.floor(progressValue)
        : Math.ceil(progressValue);
    }
  }, [experience?.levelUp]);

  if (!isAuthenticated && experience) {
    dispatch(logoutSuccess());
  }

  return isAuthenticated ? (
    <Stack spacing={1.5} direction="row" sx={{ alignItems: 'center' }}>
      <Link className={link} component={RouterLink} to="/account/wallet">
        <CustomButton buttonVariant={Variant.SECONDARY} width={125}>
          {`${rno} RNO`}
        </CustomButton>
      </Link>
      <Link
        className={`${link} ${uploadBtn}`}
        component={RouterLink}
        to={myChannelsNumber ? '/video/upload/info' : '/channels/my'}
      >
        <StyledButton>
          <Grid className={userMenuBtn}>
            <Upload />
          </Grid>
        </StyledButton>
      </Link>
      <div>
        <StyledButton
          id="demo-customized-button"
          aria-controls="demo-customized-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : 'false'}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon className={arrowIcon} />}
        >
          <Grid className={`${userMenuBtn} ${profile}`}>
            {picture ? (
              <Avatar
                src={picture}
                sx={{
                  width: { xs: '40px', md: '50px' },
                  height: { xs: '40px', md: '50px' }
                }}
              />
            ) : (
              <Profile />
            )}
          </Grid>
        </StyledButton>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button'
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {experience && (
            <MenuItem
              onClick={handleClose}
              disableRipple
              className={userInfoWrapper}
            >
              <Box className={userInfo}>
                <Box className={userName}>{name}</Box>
                <Box className={level}>{`LVL ${experience.level}`}</Box>
              </Box>
              <Box className={progressBar}>
                <LinearProgress
                  variant="determinate"
                  value={getProgressValue()}
                />
              </Box>
              <Box
                className={levelDescription}
              >{`${experience.levelUp} XP to Next LVL`}</Box>
            </MenuItem>
          )}
          <Divider sx={{ my: 0.5 }} />
          <StyledMenuItem
            onClick={() => {
              history.push('/account/profile');
              handleClose();
            }}
            disableRipple
          >
            <Box className={dropdownIcon}>
              <Profile />
            </Box>
            <Typography>{t('myAccount')}</Typography>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              history.push('/channels/my');
              handleClose();
            }}
            disableRipple
          >
            <Box className={dropdownIcon}>
              <MyChannel />
            </Box>
            <Typography>{t('myChannel')}</Typography>
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              history.push('/account/wallet');
              handleClose();
            }}
            disableRipple
          >
            <Box className={dropdownIcon}>
              <Wallet />
            </Box>
            <Typography>{t('wallet')}</Typography>
          </StyledMenuItem>
          <Divider sx={{ my: 0.5 }} />
          <StyledMenuItem onClick={() => setPopupOpened(true)} disableRipple>
            <Box className={dropdownIcon}>
              <SignOut />
            </Box>
            <Typography>{t('signOut')}</Typography>
          </StyledMenuItem>
        </StyledMenu>
        <Grid>
          <CustomDialog
            popupOpened={popupOpened}
            setPopupOpened={setPopupOpened}
            title={t('areYouSureToLogout')}
            primaryBtn={t('logout')}
            secondaryBtn={t('cancel')}
            actionHandler={() => {
              dispatch(logout());
            }}
          />
        </Grid>
      </div>
    </Stack>
  ) : (
    <Stack spacing={2} direction="row">
      <Link component={RouterLink} to="/login" underline="none">
        <CustomButton buttonVariant={Variant.PRIMARY}>
          {t('login')}
        </CustomButton>
      </Link>
      <Link component={RouterLink} to="/register" underline="none">
        <CustomButton buttonVariant={Variant.SECONDARY}>
          {t('signUp')}
        </CustomButton>
      </Link>
    </Stack>
  );
};
