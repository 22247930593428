import {
  ICategoriesList,
  ICategory,
  IGetCategories,
  IPreferredCategoriesPayload,
  IPreferredCategoriesResponse
} from '../domains/models/categories';
import { AxiosDataResponse } from '../domains/models/response';
import { IGetVideos } from '../domains/models/videos';
import { instanceAxios } from './instanceAxios';

export const getCategories = (): Promise<
  AxiosDataResponse<IGetCategories<ICategory[]>>
> => {
  return instanceAxios.get(`/categories`);
};

export const getCategory = (
  id: string,
  page: number,
  sort: string
): Promise<AxiosDataResponse<IGetVideos>> => {
  return instanceAxios.get(`/categories/${id}?page=${page}&sortby=${sort}`);
};

export const getCategoriesList = (): Promise<
  AxiosDataResponse<IGetCategories<ICategoriesList[]>>
> => {
  return instanceAxios.get(`/categories/list`);
};

export const getPreferredCategories = (): Promise<
  AxiosDataResponse<IPreferredCategoriesResponse>
> => {
  return instanceAxios.get(`/categories/preferred`);
};

export const postPreferredCategories = (
  payload: IPreferredCategoriesPayload
): Promise<AxiosDataResponse> => {
  return instanceAxios.post(`/categories/preferred`, payload);
};
