import {
  IPlaylist,
  IPlaylistVideoResponse
} from '../../domains/models/playlist';
import { PlaylistAction, PlaylistActionTypes } from '../actions/playlist';

export interface IPlaylistState {
  isLoading: boolean;
  playlists: IPlaylist[] | null;
  playlist: IPlaylistVideoResponse | null;
  initialPlaylistValues: IPlaylist[] | null;
  isToggledVideo: boolean;
  isAutoplay: boolean;
  isTheatreMode: boolean;
}

const localAutoplay = JSON.parse(localStorage.getItem('isAutoplay') || 'false');
const localTheatreMode = JSON.parse(
  localStorage.getItem('isTheatreMode') || 'true'
);

export const initialPlaylistState: IPlaylistState = {
  isLoading: false,
  playlists: null,
  playlist: null,
  initialPlaylistValues: null,
  isToggledVideo: false,
  isAutoplay: localAutoplay,
  isTheatreMode: localTheatreMode
};

export default function playlistReducer(
  state: IPlaylistState = initialPlaylistState,
  action: PlaylistAction
) {
  switch (action.type) {
    case PlaylistActionTypes.SET_PLAYLIST_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case PlaylistActionTypes.GET_PLAYLIST_SUCCESS: {
      return {
        ...state,
        playlists: action.payload,
        initialPlaylistValues: action.payload.filter(
          (playlist: IPlaylist) => playlist.isInPlaylist
        )
      };
    }
    case PlaylistActionTypes.GET_PLAYLIST_VIDEO_SUCCESS: {
      return {
        ...state,
        playlist: action.payload
      };
    }
    case PlaylistActionTypes.DELETE_USER_PLAYLIST_VIDEO_SUCCESS: {
      return {
        ...state,
        playlist: state.playlist
          ? {
              ...state.playlist,
              videos: state.playlist.videos.filter(
                (video) => video.id !== action.payload
              )
            }
          : null
      };
    }
    case PlaylistActionTypes.DELETE_USER_PLAYLIST_SUCCESS: {
      return {
        ...state,
        playlists: state.playlists
          ? state.playlists.filter((playlist) => playlist.id !== action.payload)
          : null
      };
    }
    case PlaylistActionTypes.TOGGLE_VIDEO_PLAYLIST: {
      return {
        ...state,
        isToggledVideo: !state.isToggledVideo
      };
    }
    case PlaylistActionTypes.EDIT_NAME_SUCCESS: {
      return {
        ...state,
        playlist: { ...state.playlist, title: action.payload.title }
      };
    }
    case PlaylistActionTypes.DELETE_All_PLAYLISTS_SUCCESS: {
      return {
        ...state,
        playlists: []
      };
    }
    case PlaylistActionTypes.SET_AUTOPLAY: {
      return {
        ...state,
        isAutoplay: action.payload
      };
    }

    case PlaylistActionTypes.SET_THEATREMODE: {
      return {
        ...state,
        isTheatreMode: action.payload
      };
    }
    default:
      return state;
  }
}
