import {ThunkDispatch} from 'redux-thunk';
import {IAd} from '../../domains/models';
import {IState} from '../reducers';
import {getAdsData} from '../thunk/ads';

export enum AdsActionTypes {
  SET_ADS_LOADER = 'ads/set-loader',
  GET_ADS_SUCCESS = 'ads/get-success',
  GET_ADS_FAILED = 'ads/get-failed',
}

export interface ISetAdsLoader {
  type: AdsActionTypes.SET_ADS_LOADER;
  payload: boolean;
}

export interface IGetAdsSuccess {
  type: AdsActionTypes.GET_ADS_SUCCESS;
  payload: IAd[];
}

export interface IGetAdsFailed {
  type: AdsActionTypes.GET_ADS_FAILED;
}

export const setAdsLoader = (isLoading: boolean): ISetAdsLoader => ({
  type: AdsActionTypes.SET_ADS_LOADER,
  payload: isLoading
});

export const getAds = () => {
  return async (dispatch: ThunkDispatch<void, IState, AdsAction>) => {
    await dispatch(getAdsData());
  };
};

export const getAdsSuccess = (
    payload: IAd[]
): IGetAdsSuccess => ({
  type: AdsActionTypes.GET_ADS_SUCCESS,
  payload
});

export const getAdsFailed = () => ({
  type: AdsActionTypes.GET_ADS_FAILED
});

export type AdsAction =
    | ISetAdsLoader
    | IGetAdsSuccess
    | IGetAdsFailed;
