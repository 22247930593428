import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { receiveProfile } from '../../../application/reducers';
import { getToken } from '../../../utils/token';

export type ProtectedRouteProps = {
  authenticationPath: string;
} & RouteProps;

export function ProtectedRoute({
  authenticationPath,
  ...routeProps
}: ProtectedRouteProps) {
  const { user } = useSelector(receiveProfile);
  const token = getToken();

  if (token && user) {
    return <Route {...routeProps} />;
  } else {
    return <Redirect to={authenticationPath} />;
  }
}
