import { ThunkDispatch } from 'redux-thunk';
import { ISearchResponse } from '../../domains/models/search';
import { togglePrerender } from '../../utils/prerender';
import { IState } from '../reducers';
import { getSearchData } from '../thunk/search';

export enum SearchActionTypes {
  SET_SEARCH_LOADER = 'search/set-search-loader',
  GET_SEARCH_DATA_SUCCESS = 'search/get-search-data-success'
}

export interface ISetSearchLoader {
  type: SearchActionTypes.SET_SEARCH_LOADER;
  payload: boolean;
}

export const setSearchLoader = (isLoading: boolean): ISetSearchLoader => {
  togglePrerender(isLoading);
  return ({
    type: SearchActionTypes.SET_SEARCH_LOADER,
    payload: isLoading
  });
};

export const searchRequested = (payload: { query: string; page: number }) => {
  return async (dispatch: ThunkDispatch<void, IState, SearchAction>) => {
    await dispatch(getSearchData(payload));
  };
};

export interface IGetSearchDataSuccess {
  type: SearchActionTypes.GET_SEARCH_DATA_SUCCESS;
  payload: ISearchResponse;
  isUpdating: boolean;
}

export const getSearchDataSuccess = (
  payload: ISearchResponse,
  isUpdating: boolean
): IGetSearchDataSuccess => ({
  type: SearchActionTypes.GET_SEARCH_DATA_SUCCESS,
  payload,
  isUpdating
});

export type SearchAction = ISetSearchLoader | IGetSearchDataSuccess;
