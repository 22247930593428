import { FC, SyntheticEvent } from "react";
import { useHistory } from "react-router";
import { Form, Formik } from 'formik';
import { Grid, Box, Autocomplete, TextField } from "@mui/material";
import { t } from "i18next";
import { ReactComponent as SearchIcon } from "../../../assets/images/search.svg";
import { useStyles } from './styles';

interface IAutocompleteSearch {
  query: string;
  onChangeInput: (value: SyntheticEvent) => void;
  optionList: string[];
}

export const AutocompleteSearch: FC<IAutocompleteSearch> = ({ query = '', onChangeInput , optionList}) => {
  const { search, searchIcon, autocomplete } = useStyles();
  const history = useHistory();
  const handleSubmit = (): void => history.push(`/user/search/${query}`);
  const handleChange = (e: SyntheticEvent): void => {
    const target = e.target as HTMLInputElement;
    if (!target?.outerText && !target?.value)  {
      history.push(`/`);
      return;
    }
    if (target?.outerText)  {
      history.push(`/user/search/${target.outerText}`)
      return;
    }
    history.push(`/user/search/${target.value}`)
  };

  const cloneList = optionList.map((el: string, i: number) => {
    return { index: i, name: el}
  })

  return (
    <Formik initialValues={{ query }} onSubmit={handleSubmit}>
      <Form className={search}>
        <Grid className={searchIcon}>
          <SearchIcon />
        </Grid>
        <Autocomplete
          freeSolo
          inputValue={query}
          className={autocomplete}
          onChange={handleChange}
          onInputChange={(e) => onChangeInput(e)}
          getOptionLabel={(option) => `${option}`}
          filterOptions={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          options={cloneList}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.index}>
              {option.name}
            </Box>
          )}
          renderInput={(params) => <TextField placeholder={t('search')} {...params} label='' />}
        />
      </Form>
    </Formik>
  );
}