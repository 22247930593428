import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../assets/styles/configs/themeMaterials';

export const useStyles = makeStyles((fullTheme: Theme) => ({
  userMenuBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.button.round,
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    [fullTheme.breakpoints.down('md')]: {
      width: '40px',
      height: '40px'
    }
  },
  profile: {
    '& path, & circle': {
      fill: theme.common.primaryLight
    }
  },
  arrowIcon: {
    color: theme.common.primary
  },
  dropdownIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '24px',
    height: '24px',
    marginRight: '15px'
  },
  link: {
    textDecoration: 'none',
    color: theme.common.primary
  },
  uploadBtn: {
    [fullTheme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  userInfoWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  userInfo: {
    width: '100%',
    maxWidth: '168px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500
  },
  userName: {
    maxWidth: '70%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  level: {
    color: theme.common.detail
  },
  levelDescription: {
    fontWeight: 500,
    fontSize: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  progressBar: {
    margin: '10px 0 5px 0',
    '& .MuiLinearProgress-root': {
      backgroundColor: theme.common.primaryLight,
      width: '168px',
      height: '20px',
      border: '1px solid transparent',
      borderRadius: '4px',
      backgroundImage: `linear-gradient(${theme.common.detailLight}, ${theme.common.detail})`,
      backgroundOrigin: 'border-box',
      boxShadow: `1px 1000px 1px ${theme.common.primaryLight} inset`
    },
    '& .MuiLinearProgress-bar': {
      background: theme.button.round,
      height: '20px',
      overflow: 'hidden'
    }
  }
}));
