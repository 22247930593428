import { AxiosResponse } from 'axios';
import { AxiosDataResponse } from '../domains/models/response';
import {
  IGetVideos,
  ILike,
  IPostUploadVideoResponse,
  ISubmitCommentsValues,
  IUploadVideoPreviewResponse,
  IUploadVideoResponse,
  TypeCaptcha
} from '../domains/models/videos';
import { instanceAxios } from './instanceAxios';

const {
  REACT_APP_URL,
  REACT_APP_SERVER_URL,
  REACT_APP_API_URL,
  REACT_APP_UPLOAD_URL
} = process.env;

const serverUrl = REACT_APP_UPLOAD_URL
  ? REACT_APP_UPLOAD_URL
  : REACT_APP_SERVER_URL;

export const getRecentVideos = (page: number): Promise<AxiosResponse> => {
  return instanceAxios.get(`/videos/recent?page=${page}`);
};

export const getTrendingVideos = (
  page: number,
  sort: string
): Promise<AxiosResponse> => {
  return instanceAxios.get(`/videos/trending?page=${page}&sort=${sort}`);
};

export const getRecommendedVideos = (
  page: number,
  sort: string
): Promise<AxiosResponse> => {
  return instanceAxios.get(`/videos/recommended?page=${page}&sort=${sort}`);
};

export const getSuggestVideos = (id: string): Promise<AxiosResponse> => {
  return instanceAxios.get(`/videos/suggest?videoTapeId=${id}`);
};

export const getVideo = (id: string, page: number): Promise<AxiosResponse> => {
  return instanceAxios.get(`/videos/${id}?page=${page}`);
};

export const likeVideo = (id: number): Promise<AxiosDataResponse<ILike>> => {
  return instanceAxios.post(`/videos/${id}/like`);
};

export const dislikeVideo = (id: number): Promise<AxiosDataResponse<ILike>> => {
  return instanceAxios.post(`/videos/${id}/dislike`);
};

export const uploadVideo = (): Promise<
  AxiosDataResponse<IUploadVideoResponse>
> => {
  return instanceAxios.get(`/videos`);
};

export const editVideo = (
  id: number
): Promise<AxiosDataResponse<IUploadVideoResponse>> => {
  return instanceAxios.get(`/videos/${id}/edit`);
};

export const uploadVideoPreview = (
  payload: FormData,
  updateProgress?: (percentComplete: number) => void
): Promise<AxiosDataResponse<IUploadVideoPreviewResponse>> => {
  return instanceAxios.post('/videos/thumbnails', payload, {
    headers: { 'Content-type': 'multipart/form-data' },

    baseURL: `${REACT_APP_URL}${serverUrl}${REACT_APP_API_URL}`,

    onUploadProgress: (event) => {
      const percentComplete = Math.round((event.loaded / event.total) * 100);

      updateProgress && updateProgress(percentComplete);
    }
  });
};

export const postUploadVideo = (
  payload: FormData
): Promise<AxiosDataResponse<IPostUploadVideoResponse>> => {
  return instanceAxios.post(`/videos`, payload, {
    headers: { 'Content-type': 'multipart/form-data' }
  });
};

export const createComment = (
  payload: ISubmitCommentsValues
): Promise<AxiosDataResponse> => {
  return instanceAxios.post(`/comment`, payload);
};

export const likeComment = (id: number): Promise<AxiosDataResponse<ILike>> => {
  return instanceAxios.post(`/comment/${id}/like`);
};

export const dislikeComment = (
  id: number
): Promise<AxiosDataResponse<ILike>> => {
  return instanceAxios.post(`/comment/${id}/dislike`);
};

export const pinComment = (
  videoId: number,
  id: number
): Promise<AxiosDataResponse<ILike>> => {
  return instanceAxios.post(`/videos/${videoId}/comment/${id}/pin`);
};

export const unpinComment = (
  videoId: number,
  id: number
): Promise<AxiosDataResponse<ILike>> => {
  return instanceAxios.post(`/videos/${videoId}/comment/${id}/unpin`);
};

export const deleteVideo = (videoId: number): Promise<AxiosDataResponse> => {
  return instanceAxios.delete(`/videos/${videoId}`);
};

export const getTagVideos = (
  id: string,
  page: number
): Promise<AxiosDataResponse<IGetVideos>> => {
  return instanceAxios.get(`/tags/${id}/videos?page=${page}`);
};

export const resetCaptcha = (type: TypeCaptcha): Promise<AxiosDataResponse> => {
  return instanceAxios.post(`/captcha`, { type });
};

export const sharedVideo = (id: number): Promise<AxiosDataResponse> => {
  return instanceAxios.post(`/videos/${id}/shared`);
};
