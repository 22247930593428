import { AlertColor, Collapse, IconButton } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearMessage } from '../../../../application/actions/message';
import { ReactComponent as Cross } from '../../../../assets/images/cross.svg';
import { ReactComponent as ExclamationMark } from '../../../../assets/images/exclamationMark.svg';
import { ReactComponent as Success } from '../../../../assets/images/success.svg';
import { useStyles } from './styles';

export enum Status {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning'
}

interface ICustomAlert {
  disabled?: boolean;
  status: AlertColor | null;
  children?: React.ReactNode;
  onCloseAlert?: () => void;
}

type ITest = AlertProps & ICustomAlert;

export const CustomAlert = React.forwardRef<HTMLDivElement, ITest>(
  ({ status, children, onCloseAlert }: ITest, ref) => {
    const { alert, collapse } = useStyles();
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(true);
    const statusClass = Object.values(Status)?.find((el) => el === status);

    const handleCloseAlert = () => {
      setOpen(false);
      setTimeout(() => {
        onCloseAlert && onCloseAlert();
      }, 500);
    };

    useEffect(() => {
      return () => {
        dispatch(clearMessage());
      };
    }, [dispatch]);

    return (
      <Collapse in={open} className={collapse}>
        {status && (
          <MuiAlert
            className={`${alert} ${statusClass}`}
            severity={status}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleCloseAlert}
              >
                <Cross />
              </IconButton>
            }
            icon={status === Status.SUCCESS ? <Success /> : <ExclamationMark />}
            sx={{ mb: 2 }}
            ref={ref}
          >
            {children}
          </MuiAlert>
        )}
      </Collapse>
    );
  }
);
