import { Link, ListItem, ListItemIcon } from '@mui/material';
import React from 'react';
import { ReactComponent as Facebook } from '../../../../assets/images/facebook.svg';
import { ReactComponent as Instagram } from '../../../../assets/images/instagram.svg';
import { ReactComponent as Telegram } from '../../../../assets/images/telegram.svg';
import { ReactComponent as Twitter } from '../../../../assets/images/twitter.svg';
import { ReactComponent as Discord } from '../../../../assets/images/discord.svg';
import { ReactComponent as Reddit } from '../../../../assets/images/reddit.svg';

import { useStyles } from './styles';

export interface ISocialLinksSidebar {
  open: boolean;
}

export const SocialLinksSidebar = ({ open }: ISocialLinksSidebar) => {
  const { listItemSocial, linkSocial, icon, imgIconFacebook, imgIcon } =
    useStyles({ open });

  return (
    <ListItem className={listItemSocial}>
      <Link
        className={linkSocial}
        id="twitter"
        /* FIXME wrong url */
        href="https://twitter.com/"
        target="_blank"
      >
        <ListItemIcon className={icon}>
          <Twitter className={imgIcon} />
        </ListItemIcon>
      </Link>

      <Link
        className={linkSocial}
        id="facebook"
        /* FIXME wrong url */
        href="https://www.facebook.com/"
        target="_blank"
      >
        <ListItemIcon className={icon}>
          <Facebook className={imgIconFacebook} />
        </ListItemIcon>
      </Link>

      <Link
        className={linkSocial}
        /* FIXME wrong url */
        href="https://discord.com/"
        target="_blank"
      >
        <ListItemIcon className={icon}>
          <Discord />
        </ListItemIcon>
      </Link>

      <Link
        className={linkSocial}
        /* FIXME wrong url */
        href="https://www.instagram.com/"
        target="_blank"
      >
        <ListItemIcon className={icon}>
          <Instagram className={imgIcon} />
        </ListItemIcon>
      </Link>

      <Link
        className={linkSocial}
        /* FIXME wrong url */
        href="https://t.me/"
        target="_blank"
      >
        <ListItemIcon className={icon}>
          <Telegram className={imgIcon} />
        </ListItemIcon>
      </Link>

      <Link
        className={linkSocial}
        href="https://www.reddit.com/r/Base.Tube/"
        target="_blank"
      >
        <ListItemIcon className={icon}>
          <Reddit />
        </ListItemIcon>
      </Link>
    </ListItem>
  );
};
