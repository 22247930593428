import { makeStyles } from '@mui/styles';
import { theme } from '../../../../assets/styles/configs/themeMaterials';

interface ICustomButtonStyles {
  width?: number;
  height?: number;
  fontSize?: number;
}

export const useStyles = makeStyles(() => ({
  btn: ({ width, height, fontSize }: ICustomButtonStyles) => ({
    width: width ? `${width}px` : '90px',
    height: height ? `${height}px` : '30px',
    display: 'block',
    padding: '0 10px',
    borderRadius: '5px',
    cursor: 'pointer',

    '&.MuiButton-root': {
      textTransform: 'none',
      fontFamily: theme.fontFamily.primary,
      fontSize: fontSize ? `${fontSize}px` : '14px'
    }
  }),
  btnPrimary: {
    color: theme.common.primaryLight,
    boxShadow: theme.boxShadow.button,
    background: theme.button.primary,
    '&.Mui-disabled': {
      boxShadow: theme.boxShadow.button
    }
  },
  btnSecondary: {
    color: theme.common.primary,
    boxShadow: theme.boxShadow.button,
    background: theme.common.primaryLight,
    '&:hover': {
      color: theme.common.primaryLight,
      backgroundColor: theme.common.detail
    }
  }
}));
