import {Theme} from "@mui/material/styles";
import { makeStyles } from '@mui/styles';
import { theme } from '../../../assets/styles/configs/themeMaterials';

export const useStyles = makeStyles((fullTheme:Theme) => ({
  registerBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.button.primary,
    color: theme.common.secondaryLight,
    textAlign: 'center',
    padding: '20px 0',
    position: 'fixed',
    bottom: '0',
    zIndex: '2',
    [fullTheme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  bannerContent: {
    zIndex: '2'
  },
  title: {
    '&.MuiTypography-root': {
      textAlign: 'center',
      letterSpacing: '0.05em',
      color: theme.common.primaryLight
    }
  },
  btnContent: {
    paddingLeft: '80px',
    paddingRight: '20px',
    display: 'flex',
    [fullTheme.breakpoints.down('sm')]: {
      display: 'block',
      paddingLeft: '15px'
    }
  },
  bold: {
    '&.MuiTypography-root': {
      fontWeight: '700'
    }
  },
  btnClose: {
    position:  'absolute',
    top: '0',
    right: '0'
  },
  cancelIcon: {
    cursor: 'pointer'
  }
}));
