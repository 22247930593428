import { IVideo } from './videos';

export type IPlaylist = {
  chanelId: null | number;
  description: string;
  id: number;
  picture: string;
  playlistType: string;
  status: number;
  title: string;
  totalVideos: number;
  updatedAt: string;
  isInPlaylist: boolean;
};

export type IGetPlaylists = {
  playlists: IPlaylist[];
};

export interface IPlaylistVideoResponse extends IPlaylist {
  videos: IVideo[];
}

export interface ICreatePlaylist {
  title: string;
  videoTapeId: number;
  playlistType: string;
  playlistDisplayType: string;
}

export enum PlaylistSettings {
  CHANGE_PLAYLIST = 'changePlaylist',
  DELETE = 'delete'
}

export enum PlaylistType {
  CHANNEL = 'CHANNEL',
  USER = 'USER'
}

export enum PlaylistDisplayType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}
