import {
  IChannelResponse,
  IChannelsResponse,
  ICreateChannelPayload,
  IGetChannel,
  IGetChannels,
  IGetChannelsList,
  IGetPreferredChannelsResponse,
  IGetSubscribersResponse
} from '../domains/models/channels';
import { AxiosDataResponse } from '../domains/models/response';
import { instanceAxios } from './instanceAxios';

export const getChannels = (
  page: number,
  sort: string
): Promise<AxiosDataResponse<IGetChannels>> => {
  return instanceAxios.get(`/channels?page=${page}&sortby=${sort}`);
};

export const getSubscribedChannels = (
  page: number,
  sort: string
): Promise<AxiosDataResponse<IGetChannels>> => {
  return instanceAxios.get(`/channels/subscribed?page=${page}&sortby=${sort}`);
};

export const getChannel = (
  id: string
): Promise<AxiosDataResponse<IChannelResponse>> => {
  return instanceAxios.get(`/channels/${id}`);
};

export const createChannel = (
  channelData: ICreateChannelPayload
): Promise<AxiosDataResponse<IGetChannel>> => {
  return instanceAxios.post(`/channels`, channelData, {
    headers: { 'Content-type': 'multipart/form-data' }
  });
};

export const getMyChannels = (
  page: number
): Promise<AxiosDataResponse<IGetChannels>> => {
  return instanceAxios.get(`/channels/my?page=${page}`);
};

export const getIsSubscribed = (
  id: number
): Promise<AxiosDataResponse<IChannelResponse>> => {
  return instanceAxios.post(`/channels/${id}/subscribe`);
};

export const editChannel = (
  channelData: ICreateChannelPayload,
  id: number
): Promise<AxiosDataResponse<IGetChannel>> => {
  return instanceAxios.post(`/channels/${id}`, channelData, {
    headers: { 'Content-type': 'multipart/form-data' }
  });
};

export const deleteChannel = (id: number): Promise<AxiosDataResponse> => {
  return instanceAxios.delete(`/channels/${id}`);
};

export const getSubscribers = (
  page: number,
  channelId: number
): Promise<AxiosDataResponse<IGetSubscribersResponse>> => {
  return instanceAxios.get(`/channels/${channelId}/subscribers?page=${page}`);
};

export const getPreferredChannels = (
  page: number
): Promise<AxiosDataResponse<IGetPreferredChannelsResponse>> => {
  return instanceAxios.get(`/channels/preferred?page=${page}`);
};

export const getChannelsList = (): Promise<
  AxiosDataResponse<IGetChannelsList>
> => {
  return instanceAxios.get(`/channels/list`);
};

export const changeChannel = (
  videoId: number,
  channelId: number
): Promise<AxiosDataResponse> => {
  return instanceAxios.patch(
    `/videos/${videoId}?field=channel_id&value=${channelId}`
  );
};

export const getAssociatedChannels = (
  channelId: number
): Promise<AxiosDataResponse<IChannelsResponse>> => {
  return instanceAxios.get(`/channels/associated/${channelId}`);
};
