import dayjs from 'dayjs';
import { IHistoryVideo } from '../domains/models/history';
import { IPlaylist } from '../domains/models/playlist';
import { ISpamVideo } from '../domains/models/spam';
import { IVideo } from '../domains/models/videos';

export const handleDateHorizontalBlocks = <
  T extends IHistoryVideo | ISpamVideo
>(
  videoData: T[]
) => {
  return videoData.map((video) => {
    const { videoTape } = video;
    return {
      ...video,
      updatedAt: dayjs(video.updatedAt).format('D.MM.YYYY'),
      videoTape: {
        ...videoTape,
        createdAt: dayjs(videoTape.createdAt).format('D MMM YYYY')
      }
    };
  });
};

export const handleVideosDate = (videos: IVideo[]): IVideo[] => {
  return videos.map((video: IVideo) => ({
    ...video,
    createdAt: dayjs(video.createdAt).format('D MMM YYYY')
  }));
};

export const handlePlaylistDate = (videos: IPlaylist[]): IPlaylist[] => {
  return videos.map((playlist: IPlaylist) => ({
    ...playlist,
    updatedAt: dayjs(playlist.updatedAt).format('D MMM YYYY')
  }));
};
