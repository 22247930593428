import { Box } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../assets/styles/configs/themeMaterials';

export const useStyles = makeStyles((fullTheme: Theme) => ({
  searchIcon: {
    color: theme.text.search,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '24px',
    left: '35px',
    zIndex: '1',
    [fullTheme.breakpoints.down('md')]: {
      top: '16px',
      left: '26px'
    }
  },

  search: {
    position: 'relative',
    maxWidth: '440px',
    width: '100%',
    padding: '8px 15px'
  },

  autocomplete: {
    '& .MuiInputBase-root': {
      borderRadius: '50px',
      backgroundColor: theme.bg.searchField,
      color: theme.common.primaryDark,
      [fullTheme.breakpoints.down('md')]: {
        padding: '5px 15px 5px 30px',
        fontSize: '12px'
      }
    },
    '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: '0px 0px 0px 40px',
      [fullTheme.breakpoints.down('md')]: {
        padding: '0px'
      }
    },
    '&.MuiInputBase-input': {
      '&::placeholder': {
        color: theme.common.placeholder
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.bg.searchField
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
      {
        borderColor: theme.bg.searchField
      },
    '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: theme.bg.searchField
      }
  }
}));

export const Search = styled(Box)(() => ({
  borderRadius: '215px',
  backgroundColor: theme.bg.searchField,
  margin: '10px'
}));

export const StyledInputBase = styled(InputBase)(({ theme: fullTheme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: '10px 20px',
    paddingLeft: fullTheme.spacing(6),
    transition: fullTheme.transitions.create('width'),
    width: '100%',
    [fullTheme.breakpoints.up('lg')]: {
      width: '440px'
    },
    [fullTheme.breakpoints.down('md')]: {
      padding: '5px 15px 5px 30px',
      fontSize: '12px'
    }
  }
}));
