import { AxiosDataResponse } from '../domains/models/response';
import {ISearchAutocompleteResponse, ISearchResponse} from '../domains/models/search';
import { instanceAxios } from './instanceAxios';

export const getSearchResults = (
  payload: string,
  page: number
): Promise<AxiosDataResponse<ISearchResponse>> => {
  return instanceAxios.get(`/search?search=${payload}&page=${page}`);
};

export const getSearchAutocomplete = (
  payload: string
): Promise<AxiosDataResponse<ISearchAutocompleteResponse>> => {
  return instanceAxios.get(`/search/suggest/?search=${payload}`);
};
