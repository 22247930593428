import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { getVpnMessage } from '../../../utils/vpnMessage';
import { CustomAlert } from '../../shared/components/CustomAlert';
import { Status } from '../../shared/components/CustomAlert/component';
import { useStyles } from './styles';

export const VpnMessage = () => {
  const { vpnAlert } = useStyles();
  const { t } = useTranslation();

  const handleCloseAlert = () => {
    const vpnMessage = getVpnMessage();
    const vpnData = {
      isMessage: false,
      expiry: vpnMessage.expiry
    };
    localStorage.setItem('vpnMessage', JSON.stringify(vpnData));
  };

  return (
    <Grid className={vpnAlert}>
      <CustomAlert status={Status.ERROR} onCloseAlert={handleCloseAlert}>
        {t('heyItSeemsYouAreUsingVpn')}
      </CustomAlert>
    </Grid>
  );
};
