import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IWithdrawalPayload } from '../../domains/models/withdrawal';
import {
  confirmWithdrawal,
  getSettings,
  postWithdrawal
} from '../../services/withdrawal';
import { handleError422, handleErrors } from '../../utils/handleErrors';
import { Status } from '../../views/shared/components/CustomAlert/component';
import { setMessage } from '../actions/message';
import { getActiveWithdrawal, getBalanceRequested } from '../actions/profile';
import {
  getSettingsSuccess,
  sendWithdrawalEmail,
  setWithdrawalApiErrors,
  setWithdrawalLoader
} from '../actions/withdrawal';
import { IState } from '../reducers';
import { declineUser } from './auth';

export const setWithdrawal = (payload: IWithdrawalPayload) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setWithdrawalLoader(true));
    try {
      const response = await postWithdrawal(payload);
      await dispatch(declineUser(response));
      await dispatch(getBalanceRequested());
      dispatch(sendWithdrawalEmail('pleaseConfirm'));
      dispatch(
        setMessage({ message: 'pleaseConfirm', status: Status.SUCCESS })
      );
      dispatch(getActiveWithdrawal(true));
    } catch (error: any) {
      if (error.response.status === 422) {
        const error422Data = handleError422(error.response.data.errors);
        dispatch(setWithdrawalApiErrors(error422Data));
      } else if (error.response.status === 423) {
        await dispatch(getBalanceRequested());
        dispatch(sendWithdrawalEmail('pleaseConfirm'));
        dispatch(getActiveWithdrawal(true));
        const errorData = handleErrors(error);
        dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      } else {
        const errorData = handleErrors(error);
        dispatch(setMessage({ message: errorData, status: Status.ERROR }));
      }
    } finally {
      dispatch(setWithdrawalLoader(false));
    }
  };
};

export const getSettingsData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setWithdrawalLoader(true));
    try {
      const response = await getSettings();
      await dispatch(declineUser(response));
      dispatch(getSettingsSuccess(response.data.data));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setWithdrawalLoader(false));
    }
  };
};

export const confirmWithdrawalData = (
  id: string | null,
  token: string | null
) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setWithdrawalLoader(true));
    try {
      const response = await confirmWithdrawal(id, token);
      await dispatch(declineUser(response));
      dispatch(
        setMessage({
          message: 'withdrawalCompletedSuccessfully',
          status: Status.SUCCESS
        })
      );
      dispatch(getActiveWithdrawal(false));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setWithdrawalLoader(false));
    }
  };
};
