import {
  IChangePasswordPayload,
  IProfileResponse,
  IUserAnalytic
} from '../../domains/models';
import { IChannelsList } from '../../domains/models/channels';
import {
  IAnalyticsDetail,
  IBalance,
  IExperience,
  IReferrals
} from '../../domains/models/profile';
import { ProfileAction, ProfileActionTypes } from '../actions/profile';

export type IProfileState = {
  user: IProfileResponse | null;
  analytics: IUserAnalytic | null;
  analyticsDetail: IAnalyticsDetail | null;
  isLoading: boolean;
  errors: Partial<IProfileResponse> | null;
  passwordErrors: Partial<IChangePasswordPayload> | null;
  balance: IBalance | null;
  referrals: IReferrals[] | null;
  experience: IExperience | null;
  isSidebarOpened: boolean;
  isCloseModalFree: boolean;
  availableChannels: IChannelsList[] | null;
  hasPendingRequests: boolean;
};

const localIsSidebarOpened = JSON.parse(
  localStorage.getItem('isSidebarOpened') || 'false'
);

export const initialProfileState: IProfileState = {
  user: null,
  analytics: null,
  analyticsDetail: null,
  isLoading: false,
  errors: null,
  passwordErrors: null,
  balance: null,
  isSidebarOpened: localIsSidebarOpened,
  isCloseModalFree: true, // temporary closed by default
  availableChannels: null,
  hasPendingRequests: false,
  referrals: null,
  experience: null
};

export default function profileReducer(
  state: IProfileState = initialProfileState,
  action: ProfileAction
) {
  switch (action.type) {
    case ProfileActionTypes.SET_PROFILE_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case ProfileActionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    case ProfileActionTypes.GET_PROFILE_FAILED:
      return {
        ...state,
        user: null
      };
    case ProfileActionTypes.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload
      };
    case ProfileActionTypes.GET_BALANCE_SUCCESS:
      return {
        ...state,
        balance: action.payload.balance,
        referrals: action.payload.referrals,
        experience: action.payload.experience
      };
    case ProfileActionTypes.SET_PROFILE_API_ERRORS: {
      return {
        ...state,
        errors: action.payload
      };
    }
    case ProfileActionTypes.SET_PROFILE_API_PASSWORD_ERRORS: {
      return {
        ...state,
        passwordErrors: action.payload
      };
    }
    case ProfileActionTypes.GET_ACTIVE_WITHDRAWAL: {
      return {
        ...state,
        user: {
          ...state.user,
          hasActiveWithdrawal: action.payload
        }
      };
    }
    case ProfileActionTypes.SET_IS_SIDEBAR_OPENED: {
      return {
        ...state,
        isSidebarOpened: action.payload
      };
    }
    case ProfileActionTypes.GET_AVAILABLE_CHANNELS_FOR_MONETIZATION: {
      return {
        ...state,
        availableChannels: action.payload.channels,
        hasPendingRequests: action.payload.hasPendingRequests
      };
    }
    case ProfileActionTypes.SET_PENDING_REQUEST: {
      return {
        ...state,
        hasPendingRequests: action.payload
      };
    }

    case ProfileActionTypes.GET_USER_ANALYTIC: {
      return {
        ...state,
        analytics: { ...state.analytics, loaded: false, loading: true }
      };
    }

    case ProfileActionTypes.GET_USER_ANALYTIC_SUCCESS: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          loaded: true,
          loading: false,
          ...action.payload
        }
      };
    }

    case ProfileActionTypes.GET_USER_ANALYTIC_DETAIL: {
      return {
        ...state,
        analyticsDetail: {
          ...state.analyticsDetail,
          loaded: false,
          loading: true
        }
      };
    }

    case ProfileActionTypes.GET_USER_ANALYTIC_DETAIL_SUCCESS: {
      return {
        ...state,
        analyticsDetail: {
          ...state.analyticsDetail,
          ...action.payload,
          loaded: true,
          loading: false
        }
      };
    }

    case ProfileActionTypes.SET_CLOSE_MODAL_FREE: {
      return {
        ...state,
        isCloseModalFree: action.payload
      };
    }
    default:
      return state;
  }
}
