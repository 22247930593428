import dayjs from 'dayjs';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IPreferredCategoriesPayload } from '../../domains/models/categories';
import { IVideo } from '../../domains/models/videos';
import {
  getCategories,
  getCategoriesList,
  getCategory,
  getPreferredCategories,
  postPreferredCategories
} from '../../services/categories';
import { handleErrors } from '../../utils/handleErrors';
import { Status } from '../../views/shared/components/CustomAlert/component';
import {
  getCategoriesListSuccess,
  getCategoriesSuccess,
  getCategoryVideosSuccess,
  getPreferredCategoriesSuccess,
  setCategoriesLoader
} from '../actions/categories';
import { setMessage } from '../actions/message';
import { redirect } from '../actions/redirect';
import { setSnackbar } from '../actions/snackbar';
import { IState } from '../reducers';
import { declineUser } from './auth';

export const getCategoriesData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setCategoriesLoader(true));
    try {
      const response = await getCategories();
      await dispatch(declineUser(response));
      const { categories } = response.data.data;
      dispatch(getCategoriesSuccess(categories));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setCategoriesLoader(false));
    }
  };
};

export const getCategoryData = (
  id: string,
  page: number,
  sort: string,
  isUpdating: boolean
) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setCategoriesLoader(true));
    try {
      const response = await getCategory(id, page, sort);
      await dispatch(declineUser(response));
      const { paginate, videos } = response.data.data;
      const transformVideos = videos.map((video: IVideo) => ({
        ...video,
        createdAt: dayjs(video.createdAt).format('D MMM YYYY')
      }));
      dispatch(
        getCategoryVideosSuccess({
          videos: {
            videoData: transformVideos,
            total: paginate.total,
            totalPages: paginate.totalPages
          },
          isUpdating
        })
      );
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setCategoriesLoader(false));
    }
  };
};

export const getCategoriesListData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setCategoriesLoader(true));
    try {
      const response = await getCategoriesList();
      await dispatch(declineUser(response));
      const { categories } = response.data.data;
      dispatch(getCategoriesListSuccess(categories));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setCategoriesLoader(false));
    }
  };
};

export const getPreferredCategoriesData = () => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setCategoriesLoader(true));
    try {
      const response = await getPreferredCategories();
      await dispatch(declineUser(response));
      dispatch(getPreferredCategoriesSuccess(response.data.data));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setMessage({ message: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setCategoriesLoader(false));
    }
  };
};

export const postPreferredCategoriesData = (
  payload: IPreferredCategoriesPayload
) => {
  return async (
    dispatch: ThunkDispatch<void, IState, AnyAction>
  ): Promise<void> => {
    dispatch(setCategoriesLoader(true));
    try {
      const response = await postPreferredCategories(payload);
      await dispatch(declineUser(response));
      dispatch(redirect('/channels/preferred'));
    } catch (error: any) {
      const errorData = handleErrors(error);
      dispatch(setSnackbar({ snackbar: errorData, status: Status.ERROR }));
    } finally {
      dispatch(setCategoriesLoader(false));
    }
  };
};
