import {IHandleError422} from "../domains/models/handleError";

export const handleError422 = (error: [string, string[]]): IHandleError422 => {
  return Object.entries(error).reduce((acc, element) => {
    return {
      ...acc,
      [element[0]]: element[1].toString()
    };
  }, {});
};

export const handleErrors = (error: any) => {
  if (error?.response?.status.toString().startsWith('5')) {
    return 'somethingWentWrong';
  }
  if (error?.response?.data?.errors || error?.response?.data?.data) {
    const errors = error?.response?.data?.errors || error?.response?.data?.data;
    const messageErrors = Object.values(errors);
    return messageErrors.reduce((acc: string, element: any) => {
      return `${acc}${element} `;
    }, '');
  }

  if (!error?.response?.data?.errors && error?.response?.statusText) {
    return '' + error.response.statusText;
  }
  return 'somethingWentWrong';
};
