import { IChannel } from '../../domains/models';
import { IPaginate } from '../../domains/models/response';
import { IVideo } from '../../domains/models/videos';
import { SearchAction, SearchActionTypes } from '../actions/search';

export interface ISearchState {
  isLoading: boolean;
  searchingData: {
    searching: (IChannel | IVideo)[];
    paginate: IPaginate;
  } | null;
}

export const initialSearchState: ISearchState = {
  isLoading: false,
  searchingData: null
};

export default function searchReducer(
  state: ISearchState = initialSearchState,
  action: SearchAction
) {
  switch (action.type) {
    case SearchActionTypes.SET_SEARCH_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case SearchActionTypes.GET_SEARCH_DATA_SUCCESS: {
      const { channels, videos, paginate } = action.payload;
      const oldVideos = state.searchingData
        ? state.searchingData.searching
        : [];
      return {
        ...state,
        searchingData: {
          searching: action.isUpdating
            ? [...channels, ...videos]
            : [...oldVideos, ...channels, ...videos],
          paginate
        }
      };
    }
    default:
      return state;
  }
}
