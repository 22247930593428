// todo const updatingTime = 24 * 60 * 60 * 1000;
const updatingTime = 60 * 1000;

export const setVpnMessage = () => {
  const now = new Date();
  const vpnMessage = {
    isMessage: true,
    expiry: now.getTime() + updatingTime
  };
  localStorage.setItem('vpnMessage', JSON.stringify(vpnMessage));
};

export const getVpnMessage = () => {
  const vpnMessage = localStorage.getItem('vpnMessage');
  return JSON.parse(vpnMessage as string);
};

export const unsetVpnMessage = () => {
  localStorage.setItem('vpnMessage', JSON.stringify(null));
};
