import { AlertColor } from '@mui/material';

export enum SnackbarActionTypes {
  SET_SNACKBAR = 'snackbar/set-snackbar',
  CLEAR_SNACKBAR = 'snackbar/clear-snackbar'
}

export interface ISnackbarData {
  snackbar: string;
  status?: AlertColor;
}

export interface ISetSnackbar {
  type: SnackbarActionTypes.SET_SNACKBAR;
  payload: ISnackbarData;
}

export const setSnackbar = (payload: ISnackbarData) => ({
  type: SnackbarActionTypes.SET_SNACKBAR,
  payload
});

export interface IClearSnackbar {
  type: SnackbarActionTypes.CLEAR_SNACKBAR;
}

export const clearSnackbar = (): IClearSnackbar => ({
  type: SnackbarActionTypes.CLEAR_SNACKBAR
});

export type SnackbarAction = ISetSnackbar | IClearSnackbar;
