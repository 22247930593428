import { IHistoryVideo } from '../../domains/models/history';
import { ISpamReason } from '../../domains/models/spam';
import { SpamAction, SpamActionTypes } from '../actions/spam';

export interface ISpamState {
  isLoading: boolean;
  spamVideoData: IHistoryVideo[] | null;
  spamReasons: ISpamReason[] | null;
  totalPages: number;
}

export const initialSpamState: ISpamState = {
  isLoading: false,
  spamVideoData: null,
  spamReasons: null,
  totalPages: 0
};

export default function spamReducer(
  state: ISpamState = initialSpamState,
  action: SpamAction
) {
  switch (action.type) {
    case SpamActionTypes.SET_SPAM_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case SpamActionTypes.GET_SPAM_SUCCESS: {
      const oldVideos = state.spamVideoData ? state.spamVideoData : [];
      const { isUpdating, spamData } = action.payload;
      return {
        ...state,
        spamVideoData: isUpdating
          ? spamData.spamVideoData
          : [...oldVideos, ...spamData.spamVideoData],
        totalPages: spamData.totalPages
      };
    }
    case SpamActionTypes.GET_SPAM_REASONS_SUCCESS: {
      return {
        ...state,
        spamReasons: action.payload
      };
    }
    default:
      return state;
  }
}
