import { IChannel } from '../../domains/models';
import { IUpdatesFromSubscription } from '../../domains/models/home';
import { IVideo } from '../../domains/models/videos';
import { HomeAction, HomeActionTypes } from '../actions/home';

export interface IHomeState {
  isLoading: boolean;
  popularChannels: IChannel[] | null;
  recentVideos: IVideo[] | null;
  recommendedVideos: IVideo[] | null;
  trendingVideos: IVideo[] | null;
  updatesFromSubscription: IUpdatesFromSubscription[] | null;
}

export const initialHomeState: IHomeState = {
  isLoading: false,
  popularChannels: null,
  recentVideos: null,
  recommendedVideos: null,
  trendingVideos: null,
  updatesFromSubscription: null
};

export default function homeReducer(
  state: IHomeState = initialHomeState,
  action: HomeAction
) {
  switch (action.type) {
    case HomeActionTypes.SET_HOME_LOADER:
      return {
        ...state,
        isLoading: action.payload
      };
    case HomeActionTypes.GET_HOME_DATA_SUCCESS: {
      const {
        popularChannels,
        recentVideos,
        recommendedVideos,
        trendingVideos,
        updatesFromSubscription
      } = action.payload;
      return {
        ...state,
        popularChannels,
        recentVideos,
        recommendedVideos,
        trendingVideos,
        updatesFromSubscription
      };
    }
    case HomeActionTypes.GET_IS_SUBSCRIBE_IN_HOME_SUCCESS: {
      const popularChannels =
        state.popularChannels &&
        state.popularChannels.map((channel) => {
          if (channel.id === action.payload.channel.id) {
            return action.payload.channel;
          }
          return channel;
        });

      return {
        ...state,
        popularChannels
      };
    }
    default:
      return state;
  }
}
